import styled, { keyframes } from "styled-components";
import { Olympe } from "dv-olympe";

const animateChat = keyframes`
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
`;

export const ConvStyled = styled.div`
  background-color: ${({ clr = Olympe.Color }) => clr.black}66;
  border: 1px solid ${({ clr = Olympe.Color }) => clr.white}22;
  border-radius: 10px;
  height: 500px;
  padding: 20px 30px;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  position: relative;

  @media screen and (max-width: 1460px) {
    width: 100%;
    height: 500px;
    margin-bottom: 30px;
  }

  @media screen and (max-width: 700px) {
    width: calc(100% - 25px);
    padding: 0;
    height: 500px;
    margin-bottom: 30px;
  }

  .empty {
    font-size: 1.1rem;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 30px;

    svg {
      margin-right: 10px;
    }
  }

  .headerWrap {
    padding: 10px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .header {
      padding: 10px 0px;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-decoration: none;

      .usrLogo {
        display: flex;
        align-self: center;

        border: 2px solid ${({ clr = Olympe.Color }) => clr.blue};
        transition: border-color 0.4s ease;

        width: 40px !important;
        height: 40px !important;
        border-radius: 50%;
        margin-right: 10px;

        @media screen and (max-width: 700px) {
          margin: 0;
          padding: 0;
          border-color: transparent;
        }
      }

      h2 {
        font-size: 1.2rem;
        color: ${({ clr = Olympe.Color }) => clr.grey};
      }
    }

    @media screen and (max-width: 1300px) {
      flex-direction: column;

      .header h2 {
        font-size: 16px;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;

    @media screen and (max-width: 700px) {
      flex-direction: column;
      align-items: center;
      padding-bottom: 15px;
    }

    textarea {
      width: 100%;
      resize: vertical;
      font-size: 0.9rem;
      padding: 10px;
      border-radius: 10px;
      min-height: 2.4rem !important;
      max-height: 20rem !important;
      height: 100%;
      font-family: "Inter", sans-serif;
      overflow-y: auto;

      @media screen and (max-width: 700px) {
        width: 90%;
        margin-bottom: 15px;
      }

      &:focus {
        outline: none;
      }
    }
  }
`;

export const ChatStyled = styled.div`
  background-color: ${({ clr = Olympe.Color }) => clr.dark};
  border: 1px solid ${({ clr = Olympe.Color }) => clr.white}66;

  width: calc(100% - 10px);
  height: 100%;

  padding: 10px 20px;
  margin: 15px 5px;

  overflow-y: auto;
  overflow-x: hidden;

  border-radius: 10px;

  display: flex;
  flex-direction: column;

  .newDay {
    text-align: center;
    font-size: 0.9rem;
    padding: 10px 0;
  }

  p {
    border-radius: 1.15rem;
    line-height: 1.25;
    font-size: 0.9rem;
    max-width: 75%;
    padding: 0.5rem 0.875rem;
    position: relative;
    word-wrap: break-word;
    margin: 0.5rem 0;
    width: fit-content;

    @media screen and (max-width: 1305px) {
      max-width: 85%;
    }

    .date {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 0.8rem;
      color: ${({ clr = Olympe.Color }) => clr.light};

      svg {
        width: 0.95rem;
        margin-left: 5px;
      }

      button {
        background-color: inherit;
        border: none;
        outline: none;
        color: ${({ clr = Olympe.Color }) => clr.white};
        padding: 0;
        margin: 0 4px;
        cursor: pointer;
        transition: transform 0.4s ease-out;

        svg {
          margin: 0;
          width: 0.9rem;
        }

        &:hover {
          transform: scale(0.85);
        }
      }
    }

    &::before,
    &::after {
      bottom: -0.1rem;
      content: "";
      height: 1rem;
      position: absolute;
    }

    &.from-me {
      align-self: flex-end;
      background-color: ${({ clr = Olympe.Color }) => clr.blueChat};
      color: ${({ clr = Olympe.Color }) => clr.white};

      &::before {
        border-bottom-left-radius: 0.8rem 0.7rem;
        border-right: 1rem solid ${({ clr = Olympe.Color }) => clr.blueChat};
        right: -0.35rem;
        transform: translate(0, -0.1rem);
      }

      &::after {
        background-color: ${({ clr = Olympe.Color }) => clr.dark};
        border-bottom-left-radius: 0.5rem;
        right: -40px;
        transform: translate(-30px, -2px);
        width: 10px;
      }
    }

    &.from-them {
      align-items: flex-start;
      background-color: ${({ clr = Olympe.Color }) => clr.light};
      color: ${({ clr = Olympe.Color }) => clr.dark};

      &::before {
        border-bottom-right-radius: 0.8rem 0.7rem;
        border-left: 1rem solid ${({ clr = Olympe.Color }) => clr.light};
        left: -0.35rem;
        transform: translate(0, -0.1rem);
      }
      &::after {
        background-color: ${({ clr = Olympe.Color }) => clr.dark};
        border-bottom-right-radius: 0.5rem;
        left: 20px;
        transform: translate(-30px, -2px);
        width: 10px;
      }

      .date {
        color: #8d99ae;
      }
    }

    &.from-system {
      align-self: center;
      background-color: ${({ clr = Olympe.Color }) => clr.light};
      border-radius: 10px;
      color: ${({ clr = Olympe.Color }) => clr.dark};
      text-align: center;

      .date {
        color: ${({ clr = Olympe.Color }) => clr.dark};
      }
    }
  }

  p[class^="from-"].emoji {
    background: none;
    font-size: 2.5rem;

    &::before {
      display: none;
    }
  }

  .from-me ~ .from-me,
  .from-them ~ .from-them {
    margin: 0.2rem 0 0;

    &:last-child {
      margin-bottom: 0.5rem;
    }
  }

  .margin-b_one {
    margin-bottom: 1rem !important;
  }

  .no-tail {
    margin-bottom: 0 !important;

    &::before {
      display: none;
    }
  }
`;

export const SquareAnim = styled.ul`
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;

  overflow: hidden;

  li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: ${({ clr = Olympe.Color }) => clr.white}44;
    animation: ${animateChat} 25s linear infinite;
    bottom: -150px;

    &:nth-child(1) {
      left: 25%;
      width: 80px;
      height: 80px;
      animation-delay: 0s;
    }
    &:nth-child(2) {
      left: 10%;
      width: 20px;
      height: 20px;
      animation-delay: 2s;
      animation-duration: 12s;
    }
    &:nth-child(3) {
      left: 70%;
      width: 20px;
      height: 20px;
      animation-delay: 4s;
    }
    &:nth-child(4) {
      left: 40%;
      width: 60px;
      height: 60px;
      animation-delay: 0s;
      animation-duration: 18s;
    }
    &:nth-child(5) {
      left: 65%;
      width: 20px;
      height: 20px;
      animation-delay: 0s;
    }
    &:nth-child(6) {
      left: 75%;
      width: 110px;
      height: 110px;
      animation-delay: 3s;
    }
    &:nth-child(7) {
      left: 35%;
      width: 60px;
      height: 60px;
      animation-delay: 7s;
    }
    &:nth-child(8) {
      left: 50%;
      width: 25px;
      height: 25px;
      animation-delay: 15s;
      animation-duration: 45s;
    }
    &:nth-child(9) {
      left: 20%;
      width: 15px;
      height: 15px;
      animation-delay: 2s;
      animation-duration: 35s;
    }
    &:nth-child(10) {
      left: 85%;
      width: 110px;
      height: 110px;
      animation-delay: 0s;
      animation-duration: 11s;
    }
  }
`;
