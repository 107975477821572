import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Calendar as FCalendar,
  Check,
  Clock,
  Star,
  User,
  XCircle
} from "react-feather";
import { useForm } from "react-hook-form";
import moment from "moment";
import { toast } from "react-toastify";
import { Olympe } from "dv-olympe";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import frLocale from "@fullcalendar/core/locales/fr";

import listPlugin from "@fullcalendar/list";
import Select from "react-select";

import { axiosGet, axiosPut } from "../datas/fetch";
import { MainContext } from "../datas/context";
import { getInstTime } from "../datas/UtilsFunctions";
import {
  AskedCard,
  HorizontalWrap,
  MeetAgenda,
  MeetList,
  MeetModal
} from "../components/StyledElements";

moment.locale("fr");

export default function MeetsView () {
  const context = React.useContext(MainContext);
  const params = useParams();
  const navigate = useNavigate();

  const [loading, setLoad] = useState(!!params.usrId);
  const [users, setUsers] = useState([]);
  const [list, setList] = useState([]);
  const [client, setClient] = useState([]);
  const [asked, setAsked] = useState([]);
  const [search, setSearch] = useState("");

  const [askLoad, setAskLoad] = useState(false);
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);

  const { setValue, getValues, watch } = useForm();

  const calendarRef = React.createRef();
  const ListRef = React.createRef();

  useEffect(() => {
    const refreshInfos = async () =>
      axiosGet({
        apiKey: context.api.key,
        uri: "meets/"
      }).then((data) => {
        setUsers(data.meets || []);
        setAsked(data.asked || []);

        if (params.usrId && !data.meets[0].id) {
          toast.error("Utilisateur non trouvé.", { icon: "🔎" });
          navigate("/meetings/");
          return;
        }

        if (loading) setLoad(false);
        return data.meets || [];
      });

    refreshInfos().then((meets) => {
      if (params.usrId) {
        const name = meets.find((usr) => usr.id === params.usrId);
        if (name && client.length === 0) {
          setClient([
            {
              label: name.name || "nc",
              value: params.usrId
            }
          ]);
        }
      }
    });
    watch([
      "rdvMotif",
      "rdvContent",
      "rdvDate",
      "rdvLocation",
      "rdvUser",
      "rdvId"
    ]);
    const datasInterval = setInterval(() => refreshInfos(), 2000);

    return () => {
      clearInterval(datasInterval);
    };
  }, [context.api.key, loading, navigate, params.usrId]);

  useEffect(() => {
    const ret = [];

    for (let i = 0; i < users?.length; i++) {
      if (users[i].related > 0) {
        ret.push({
          label: users[i]?.name || "nc",
          value: users[i]?.id || "nc"
        });
      }
    }

    if (JSON.stringify(ret) !== JSON.stringify(list)) setList(ret);
  }, [users, client]);

  useEffect(() => {
    if (!params.usrId) {
      setClient([]);
    } else {
      const name = users.find((usr) => usr.id === params.usrId);
      if (name) {
        setClient([
          {
            label: name.name || "nc",
            value: params.usrId
          }
        ]);
      }
    }
  }, [params]);

  const handleSearch = (event) => setSearch(event.target.value);

  const filteredDatas = () => {
    const ret = [];
    const tmp = users
      .filter(
        (obj) =>
          obj.id.toLowerCase().includes(search.toLowerCase()) ||
          obj.name.toLowerCase().includes(search.toLowerCase())
      )
      .sort((a, b) => b.size - a.size)
      .sort((a, b) => (a.related < b.related ? 1 : -1));

    for (let i = 0; i < tmp.length; i++) {
      ret.push({
        id: tmp[i].id,
        name: tmp[i].name,
        desc:
          tmp[i].count > 0
            ? (
              <span style={{ color: Olympe.Color.blueLight }}>
                {tmp[i].count} action {tmp[i].count > 1 && "s"} à réaliser.
              </span>
            )
            : tmp[i].related === 0
              ? (
                "Aucun fichier."
              )
              : (
                <span className="text">
                  {tmp[i].related} <FCalendar /> ● {getInstTime(tmp[i].size)}
                </span>
              ),
        badge: tmp[i].count
      });
    }
    return ret;
  };

  const filteredEvents = () => {
    const tmp = [];

    if (client.toString() !== "") {
      for (let i = 0; i < client.length; i++) {
        const meets =
          users.find((obj) => obj.id === client[i].value)?.meets || [];

        for (let k = 0; k < meets.length; k++) {
          tmp.push({
            id: meets[k].rdvId || k,
            title: client[i].label + " - " + meets[k].rdvName,
            start: moment(meets[k].rdvDate).format("YYYY-MM-DD[T]HH:mm:ss"),
            end: moment(meets[k].rdvDate)
              .add(meets[k].rdvDuration, "minutes")
              .format("YYYY-MM-DD[T]HH:mm:ss")
          });
        }
      }
    } else {
      for (let i = 0; i < users.length; i++) {
        if (users[i].related > 0) {
          for (let k = 0; k < users[i].meets.length; k++) {
            tmp.push({
              id: users[i].meets[k].rdvId || k,
              title: users[i].name + " - " + users[i].meets[k].rdvName,
              start: moment(users[i].meets[k].rdvDate).format(
                "YYYY-MM-DD[T]HH:mm:ss"
              ),
              end: moment(users[i].meets[k].rdvDate)
                .add(users[i].meets[k].rdvDuration, "minutes")
                .format("YYYY-MM-DD[T]HH:mm:ss")
            });
          }
        }
      }
    }
    return tmp;
  };

  const renderAskedCard = (elt, idx) => (
    <AskedCard
      key={`${elt.rdvId}-${idx}`}
      onClick={() => {
        setValue("rdvId", elt.rdvId);
        setValue("rdvModif", elt.rdvName);
        setValue("rdvContent", elt.rdvContent);
        setValue("rdvDate", elt.rdvDate);
        setValue("rdvUser", elt.rdvUsrName);
        setValue("rdvLocation", elt.rdvLocation);
        setOpen(true);
      }}
    >
      <Olympe.Tooltips title={`Voir le profil de ${elt.rdvUsrName}`} notSpan>
        <Link to={`/accounts/${elt.rdvUsrId}`}>
          <User />
          {elt.rdvUsrName}
        </Link>
      </Olympe.Tooltips>

      <h3>
        {Olympe.Utils.truncStr({ str: elt.rdvName, len: 50 }) ||
          "Raison inconnue"}
      </h3>

      <p className="date">
        <Clock />
        {moment(elt.rdvDate).format("DD MMM Y")}
      </p>
    </AskedCard>
  );

  const modifReject = () => {
    if (!getValues("rdvId")) {
      toast.error("L'identifiant n'est pas récupéré, réessayez plus tard.");
      return;
    }

    setAskLoad(true);
    axiosPut({
      apiKey: context.api.key,
      uri: "meetAsk/?item=reject",
      opt: { rdvId: getValues("rdvId") }
    })
      .then((data) => {
        if (data !== "true") {
          throw new Error(data || "réponse serveur erronée.");
        }
        toast.success("La demande de rendez-vous a bien été refusée.", {
          icon: <XCircle />
        });
      })
      .catch((e) =>
        toast.error(
          "Une erreur est survenue, la demande de rendez-vous n'a pas été rejetée : " +
            e.message
        )
      )
      .finally(() => {
        setAskLoad(false);
        setOpen(false);
      });
  };

  const handleDateClick = (dateClickInfo) => {
    calendarRef.current
      .getApi()
      .changeView("timeGridDay", dateClickInfo.dateStr);
  };

  if (!users[0]) return <Olympe.Loader />;

  return (
    <Olympe.Wrapper horizontal multiple>
      <Olympe.Modal
        open={open}
        loading={askLoad}
        closeOnDocumentClick
        onClose={closeModal}
        title={"Demande de rendez-vous"}
        content={
          <MeetModal>
            <h1>{getValues("rdvModif") || "NC"}</h1>

            <p className="location">{getValues("rdvLocation") || "NC"}</p>
            <p className="content">{getValues("rdvContent") || "NC"}</p>

            <div className="footer">
              <p>
                <User />
                {getValues("rdvUser") || "NC"}
              </p>

              <p>
                <Clock />
                {Olympe.Utils.stringUpper(
                  moment(getValues("rdvDate")).format("DD MMMM Y")
                )}
              </p>
            </div>
          </MeetModal>
        }
        buttons={[
          {
            isDanger: true,
            label: "Refuser",
            icon: <XCircle />,
            exec: modifReject
          },
          {
            label: "Accepter",
            icon: <Check />,
            exec: () => navigate(`/meets/edit/${getValues("rdvId")}`)
          }
        ]}
      />

      {asked.length > 0 && (
        <Olympe.SubItem>
          <Olympe.ItemFullWidth>
            <Olympe.ItemTitle>
              <span>
                <Star />
                {asked.length > 1 ? "Nouvelles demandes" : "Nouvelle demande"}
              </span>
            </Olympe.ItemTitle>

            <HorizontalWrap>
              {asked.map((elt, idx) => renderAskedCard(elt, idx))}
            </HorizontalWrap>
          </Olympe.ItemFullWidth>
        </Olympe.SubItem>
      )}

      <MeetAgenda>
        <Select
          isMulti
          className="select"
          options={list}
          placeholder="Utilisateur(s)"
          value={client}
          onChange={(u) => {
            if (u.length === 0) navigate("/meets/");
            setClient(u);
          }}
          noOptionsMessage={() => "Aucun compte trouvé 😬"}
          styles={{
            menu: (baseStyles) => ({
              ...baseStyles,
              zIndex: 2,
              backgroundColor: Olympe.Color.white,
              color: Olympe.Color.black
            }),
            multiValueRemove: (styles) => ({
              ...styles,
              color: Olympe.Color.black,
              ":hover": {
                color: Olympe.Color.blueDark
              }
            })
          }}
        />

        <FullCalendar
          ref={calendarRef}
          nowIndicator={true}
          allDaySlot={false}
          height={600}
          navLinks={true}
          weekends={false}
          selectConstraint="businessHours"
          events={filteredEvents()}
          slotMaxTime="20:00:00"
          slotMinTime="08:00:00"
          eventClick={(e) => navigate("/meets/details/" + e.event.id)}
          dateClick={handleDateClick}
          eventBackgroundColor={Olympe.Color.blue}
          eventColor={Olympe.Color.blueDark}
          eventTextColor={Olympe.Color.white}
          eventBorderColor={Olympe.Color.blueDark}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          locales={frLocale}
          locale="fr"
          timeZone="Europe/Paris"
          businessHours={{
            daysOfWeek: [1, 2, 3, 4, 5],

            startTime: "8:00",
            endTime: "20:00"
          }}
          views={{
            timeGridWeek: {
              titleFormat: {
                month: "short",
                year: "numeric",
                day: "numeric"
              }
            },
            dayGridFourWeek: {
              type: "dayGrid",
              duration: { weeks: 4 }
            }
          }}
          headerToolbar={{
            left: "dayGridMonth,timeGridWeek,timeGridDay",
            center: "title",
            end: "today prev,next"
          }}
        />
      </MeetAgenda>

      <Olympe.SubItem>
        <Olympe.List.Menu
          uri="meets"
          label="Rendez-vous"
          placeholder="Utilisateur, identifiant"
          handleSearch={handleSearch}
          search={search}
          content={filteredDatas()}
        />

        <MeetList>
          <FullCalendar
            ref={ListRef}
            events={filteredEvents()}
            eventClick={(e) => navigate("/meets/details/" + e.event.id)}
            eventBackgroundColor={Olympe.Color.white}
            eventColor={Olympe.Color.blueDark}
            eventTextColor={Olympe.Color.white}
            eventBorderColor={Olympe.Color.blueDark}
            plugins={[listPlugin]}
            initialView="listMonth"
            locales={frLocale}
            locale="fr"
            timeZone="Europe/Paris"
            customButtons={{
              new: {
                text: "Ajouter un rendez-vous",
                click: () => navigate(`/meets/add/${params.usrId}`)
              },
              toggle: {
                text: "Changer de vue",
                click: () => {
                  if (ListRef.current.getApi().view.type === "listWeek") {
                    ListRef.current.getApi().changeView("listMonth");
                  } else if (
                    ListRef.current.getApi().view.type === "listMonth"
                  ) {
                    ListRef.current.getApi().changeView("listYear");
                  } else {
                    ListRef.current.getApi().changeView("listWeek");
                  }
                }
              }
            }}
            headerToolbar={{
              left: "title",
              center: "",
              end: params.usrId ? "new" : ""
            }}
            footerToolbar={{
              left: "prev,next",
              center: "toggle",
              right: "today"
            }}
          />
        </MeetList>
      </Olympe.SubItem>
    </Olympe.Wrapper>
  );
}
