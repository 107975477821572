import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { axiosGet, axiosPut } from "../datas/fetch";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import $ from "jquery";
import {
  ArrowLeftCircle,
  AtSign,
  Briefcase,
  Check,
  CheckCircle,
  Home,
  Info,
  MapPin,
  Phone,
  Send,
  User
} from "react-feather";

import { MainContext } from "../datas/context";
import { ItemAddr } from "../components/StyledItem";
import { Olympe } from "dv-olympe";

export default function AccountEdit () {
  const context = React.useContext(MainContext);
  const [datas, setDatas] = useState({});
  const [fields, setFields] = useState([]);
  const [suggest, setSuggest] = useState([]);
  const [sent, setSent] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    setError,
    setFocus,
    setValue,
    unregister,
    getValues,
    watch,
    formState: { errors }
  } = useForm();

  useEffect(() => {
    const refreshInfos = async () => {
      axiosGet({
        apiKey: context.api.key,
        uri: `users/?item=user&usrId=${params.usrId}`
      }).then((data) => {
        setDatas(data);
        setFields(data.addr);

        watch(["clType", "clName", "clNb"]);
      });
    };

    refreshInfos();
    return () => refreshInfos;
  }, []);

  const checkField = ({
    name,
    value,
    required = false,
    minLength = 0,
    maxLength = 50,
    regex = /g/,
    format = null
  }) => {
    if (!name) {
      console.error("Non existing field : " + name);
      return 1;
    }

    if (required && value.length === 0) {
      setError(name, { type: "required", message: "Ce champ est requis." });
      setFocus(name);
      return 1;
    }

    if (minLength > 0 && value.length < minLength) {
      setError(name, {
        type: "minLength",
        message: `Au moins ${minLength} caractères.`
      });
      setFocus(name);
      return 1;
    }

    if (maxLength > 0 && value.length > maxLength) {
      setError(name, {
        type: "maxLength",
        message: `Maximum ${maxLength} caractères.`
      });
      setFocus(name);
      return 1;
    }

    if (regex !== /g/ && !regex.test(value)) {
      setError(name, {
        type: "pattern",
        message: format ? `Format : ${format}` : "Mauvais format"
      });
      setFocus(name);
      return 1;
    }
    return 0;
  };

  const onSubmit = async (data) => {
    let sum = 0;

    sum += checkField({
      name: "clName",
      value: data.clName,
      required: true,
      minLength: 2,
      regex:
        /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
      format: "Jean Dupont, Ab Cd"
    });

    sum += checkField({
      name: "clMail",
      value: data.clMail,
      required: true,
      minLength: 4,
      regex: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
      format: "xxx@yyy.zzz"
    });

    sum += checkField({
      name: "clTel",
      value: data.clTel,
      required: true,
      minLength: 10,
      maxLength: 14,
      regex: /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}/,
      format: "01 23 45 67 89"
    });

    if (data.clAddr0.length < 3) {
      toast.error("Veuillez entrer une adresse d'au moins 3 caractères.");
      setFocus("clAddr0");
      return;
    }

    if (sum === 0) {
      setSent(true);

      const datas = await axiosPut({
        apiKey: context.api.key,
        uri: "user/",
        opt: data
      })
        .then((data) => data)
        .catch((e) => e.message)
        .finally(() => setSent(false));

      if (datas === "true") {
        toast.success(
          "Les informations de l'utilisateur ont bien été modifiées.",
          { icon: "👍" }
        );
        navigate(`/accounts/${params.usrId}`);
      } else {
        toast.error(
          `Une erreur est survenue, les informations restent les mêmes : ${
            !datas ? "requête réseau échouée." : datas
          }`
        );
      }
    }
  };

  const blurAddr = (e, idx) => {
    const value = e.target.value;

    if (!getValues("clType") && value !== "" && idx > 0) {
      setValue("clType", true);
    }
  };

  const addField = (e) => {
    const nb = e.target.value;

    if (nb < 1) setValue("clNb", 1);
    else if (nb > 20) setValue("clNb", 20);

    const max = nb > 20 ? 20 : nb < 1 ? 1 : nb;
    const tmp = [];

    for (let i = 0; i < max; i++) {
      watch(`clAddr${i}`);
      tmp.push(datas.addr[i] || "");
    }
    for (let i = max; i < 20; i++) unregister(`clAddr${i}`);

    setFields([...tmp]);
  };

  const searchAddr = (e) => {
    const value = e.target.value;
    if (value.length < 3) {
      setSuggest([]);
      return;
    }

    setValue($(e.target).attr("name"), e.target.value);

    axios
      .get(`https://api-adresse.data.gouv.fr/search/?q=${value}t&limit=5`)
      .then(({ data }) => {
        const tmp = [];
        for (let i = 0; i < data.features.length; i++) {
          tmp.push(data.features[i].properties.label);
        }
        setSuggest([...tmp]);
      })
      .catch(() => false);
  };

  const clickOnSuggest = (idx, value, e) => {
    setValue(`clAddr${idx}`, value);
    $(e.target).parent().parent().removeClass("focused");
    setSuggest([]);
  };

  const focusAddr = (e) => {
    setSuggest([]);
    $(".inputs.focused").removeClass("focused");
    $(e.target).parent().addClass("focused");
    searchAddr(e);
  };

  const removeSuggestFields = (e) => {
    if (e.target.tagName.toLowerCase() !== "div") return;
    setSuggest([]);
    $(".inputs.focused").removeClass("focused");
  };

  const magicNumTel = (e) => {
    const value = e.target.value
      .replaceAll(/\s/g, "")
      .replace("+330", "0")
      .replace("+33", "0")
      .replace(/\d{2}(?=.)/g, "$& ");

    setValue("clTel", value);
  };

  if (!datas.id) return <Olympe.Loader />;

  return (
    <Olympe.Wrapper onClick={removeSuggestFields}>
      <Olympe.Input.Text
        type="hidden"
        {...register("clId")}
        defaultValue={params.usrId}
        readOnly
      />

      <Olympe.SubItem>
        <Olympe.ItemFullWidth>
          <Olympe.ItemTitle>
            <span>
              <Olympe.Tooltips
                title="Retour aux informations de l'utilisateur."
                classes="icon"
                placement="left"
              >
                <Olympe.Button.Basic
                  toExecute={() => navigate(`/accounts/${datas.id}`)}
                  icon={<ArrowLeftCircle />}
                />
              </Olympe.Tooltips>

              <span style={{ marginLeft: 20 }}>Informations</span>
            </span>
          </Olympe.ItemTitle>

          <Olympe.ItemWithAvatar>
            <div className="infosSep">
              <div className="usrDetails">
                <User />

                <div className="basicInput">
                  <Olympe.Input.Text
                    type="text"
                    {...register("clName")}
                    defaultValue={datas.name}
                    autoComplete="off"
                    placeholder="Prénom Nom"
                    onKeyPress={(e) => {
                      e.key === "Enter" && Olympe.Utils.enterInput(e);
                    }}
                  />
                  {errors.clName && (
                    <span className="errorlog">{errors.clName.message}</span>
                  )}
                </div>
              </div>

              <div className="usrDetails">
                <AtSign />
                <div className="basicInput">
                  <Olympe.Input.Text
                    type="text"
                    {...register("clMail")}
                    defaultValue={datas.mail}
                    autoComplete="off"
                    placeholder="Adresse mail"
                    onKeyPress={(e) => {
                      e.key === "Enter" && Olympe.Utils.enterInput(e);
                    }}
                  />
                  {errors.clMail && (
                    <span className="errorlog">{errors.clMail.message}</span>
                  )}
                </div>
              </div>

              <div className="usrDetails">
                <Phone />
                <div className="basicInput">
                  <Olympe.Input.Text
                    type="text"
                    {...register("clTel")}
                    defaultValue={datas.phon}
                    autoComplete="off"
                    placeholder="Numéro de téléphone"
                    onKeyPress={(e) => {
                      e.key === "Enter" && Olympe.Utils.enterInput(e);
                    }}
                    onBlur={magicNumTel}
                  />
                  {errors.clTel && (
                    <span className="errorlog">{errors.clTel.message}</span>
                  )}
                </div>
              </div>

              <div className="usrDetails">
                {getValues("clType") ? <Briefcase /> : <Home />}

                <div className="basicInput noMsg">
                  Compte Professionel
                  <Olympe.Input.Text
                    type="checkbox"
                    {...register("clType")}
                    defaultChecked={datas.type === "cl_pro"}
                    onKeyPress={(e) => {
                      e.key === "Enter" && Olympe.Utils.enterInput(e);
                    }}
                  />
                  {errors.clType && (
                    <span className="errorlog">{errors.clType.message}</span>
                  )}
                </div>
              </div>
            </div>

            <div className="infosSep avatar">
              <span className="icon">
                <span className="certified">
                  {getValues("clType") && <CheckCircle />}
                </span>
                {Olympe.Utils.AvatarSvgHtml({
                  seed: getValues("clName") || datas.name
                })}
              </span>
            </div>
          </Olympe.ItemWithAvatar>
        </Olympe.ItemFullWidth>
      </Olympe.SubItem>

      <Olympe.SubItem>
        <ItemAddr>
          <Olympe.ItemTitle>
            Adresse{fields.length > 1 ? "s" : ""} de résidence
          </Olympe.ItemTitle>

          <div className="selectNbWrap">
            <p>Nombre d'adresse{fields.length > 1 ? "s" : ""} :</p>
            <Olympe.Input.Text
              type="number"
              {...register("clNb")}
              className="selectNb"
              defaultValue={fields.length}
              autoComplete="off"
              max="20"
              min="1"
              step="1"
              onChange={addField}
            />
          </div>

          <div id="addrListRef">
            {fields.map((addr, idx) => (
              <div
                className="usrDetails"
                key={`${idx}-${addr}`}
                style={{ marginTop: 3, marginBottom: 3 }}
              >
                <MapPin />
                <div className="addrInput">
                  <span className="codeDesc">N° {idx + 1} :</span>
                  <div className="inputs">
                    <Olympe.Input.Text
                      type="text"
                      {...register(`clAddr${idx}`)}
                      defaultValue={addr}
                      autoComplete="off"
                      placeholder="Nouvelle adresse"
                      onBlur={(e) => blurAddr(e, idx)}
                      onChange={searchAddr}
                      onFocus={focusAddr}
                      onKeyPress={(e) => {
                        e.key === "Enter" && Olympe.Utils.enterInput(e);
                      }}
                    />
                    {suggest.length > 0 && (
                      <div className="suggest">
                        {suggest.map((elt) => (
                          <p
                            key={`${elt}`}
                            onClick={(e) => clickOnSuggest(idx, elt, e)}
                          >
                            {elt}
                          </p>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </ItemAddr>
      </Olympe.SubItem>

      <Olympe.SubItem>
        <Olympe.ItemFullWidth>
          <Olympe.ItemTitle>Confirmer les modifications</Olympe.ItemTitle>

          <p className="usrDetails">
            <Info />
            <span className="codeDesc">
              Modifier les informations de l'utilisateur est une action
              immédiate et irréversible. Pour des soucis de communication et
              d'expérience utilisateur, un mail automatique est envoyé à
              l'utilisateur lui prévenant des changements appliqués.
            </span>
          </p>

          <p className="usrDetails">
            <Send />
            <span className="codeDesc">
              Pour ne pas envoyer de mail à l'utilisateur, veuillez désactiver
              l'option ci-dessous ; les changements seront effectifs sans
              prévenir l'utilisateur :<br />
              <br />
              Notifier l'utilisateur
              <Olympe.Input.Text
                type="checkbox"
                defaultChecked
                {...register("letKnow")}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                  $(e.target)[0].blur();
                }}
              />
            </span>
          </p>

          <div className="footerInfos">
            {sent
              ? (
                <Olympe.MiniLoader />
              )
              : (
                <Olympe.Button.Basic
                  label="Valider les informations"
                  icon={<Check />}
                  type="submit"
                  toExecute={handleSubmit(onSubmit)}
                />
              )}
          </div>
        </Olympe.ItemFullWidth>
      </Olympe.SubItem>
    </Olympe.Wrapper>
  );
}
