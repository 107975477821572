import styled from "styled-components";
import { Olympe } from "dv-olympe";

/* NOTIFICATIONS PAGE */
export const ExpandedItem = styled.div`
  p {
    padding: 4px 10px;
    margin: 0;
    display: flex;
    align-items: center;

    svg {
      height: 16px;
      width: 16px;
      margin-right: 10px;
      color: ${({ clr = Olympe.Color }) => clr.blueLight};
    }

    &.error {
      width: 100%;
      justify-content: center;
      padding: 30px;
      text-align: center;
      font-weight: 800;
      color: ${({ clr = Olympe.Color }) => clr.light};

      svg {
        color: ${({ clr = Olympe.Color }) => clr.tomato};
      }
    }
  }

  @media screen and (max-width: 700px) {
    width: 100%;
    max-width: 100%;
    padding: 5px;
    margin: 0;

    * {
      word-break: break-all;
    }

    p,
    li {
      word-break: break-spaces;
      font-size: 12px;
    }
  }

  iframe {
    width: 100%;
    margin: 0;
    margin-top: 20px;
    height: 50vh;
    max-height: 500px;
    overflow-y: auto;
  }

  .notifPreview {
    background-color: ${({ clr = Olympe.Color }) => clr.inputBg};
    color: ${({ clr = Olympe.Color }) => clr.dark};
    min-height: 50px;
    max-width: 400px;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    position: relative;
    margin: 20px auto;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    @media screen and (max-width: 700px) {
      max-width: 100%;
      margin: 0;
      padding: 5px;
    }

    .imgWrap {
      background-color: ${({ clr = Olympe.Color }) => clr.black};
      min-width: 60px;
      min-height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;

      @media screen and (max-width: 700px) {
        display: none;
      }
    }

    .titles {
      padding-left: 10px;

      h5 {
        padding: 5px 0;
        margin: 0;
        font-size: 1rem;
      }

      h6 {
        padding: 0;
        font-weight: 400;
        margin: 0;
        font-size: 13px;
        color: ${({ clr = Olympe.Color }) => clr.dark};
      }
    }

    span {
      position: absolute;
      background-color: ${({ clr = Olympe.Color }) => clr.inputBg};
      border: 2px solid ${({ clr = Olympe.Color }) => clr.grey};
      top: -5px;
      right: -5px;
      height: 25px;
      width: 25px;
      border-radius: 50%;
      text-align: center;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
export const NotifForm = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 40%;
  min-width: 400px;
  max-width: 700px;
  margin: 20px auto;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  background-color: ${({ clr = Olympe.Color }) => clr.light};

  @media screen and (max-width: 700px) {
    max-width: 100%;
    min-width: 100%;
  }

  .imgWrap {
    background-color: ${({ clr = Olympe.Color }) => clr.black};
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }

  .titles {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    padding: 0 20px;

    .notifTitle {
      width: 100%;
      margin-bottom: 5px;
      font-size: 1rem;
    }

    .notifBody {
      width: 100%;
    }
  }

  .notifBadge {
    position: absolute;
    top: -15px;
    right: -15px;
    min-width: 50px;
    height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
  }
`;
export const EditNotif = styled.div`
  background-color: ${({ clr = Olympe.Color }) => clr.white}22;
  border: 1px solid ${({ clr = Olympe.Color }) => clr.white}22;
  color: ${({ clr = Olympe.Color }) => clr.light};
  min-height: 50px;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  margin: 20px auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media screen and (max-width: 700px) {
    max-width: 100%;
    margin: 0;
    padding: 5px;
  }

  h2 {
    width: 100%;
    padding-top: 0;
    font-size: 1rem;
  }

  input,
  textarea {
    width: 100%;
  }

  textarea {
    border: 1px solid transparent;
    outline: none;
    margin-top: 10px;
    padding: 5px 10px;
    resize: vertical;
    border-radius: 5px;
    min-height: 25px;
    max-height: 150px;
    font-size: 0.9rem;
    font-family: "Inter", sans-serif;

    &:focus {
      border: 1px solid ${Olympe.Color.blueInput};
      border-radius: 7px;
      box-shadow: 0 0 0 1px ${Olympe.Color.blueInput};
    }
  }

  .desc {
    color: ${Olympe.Color.grey};
    font-style: italic;
    font-size: 13px;
  }
`;
export const NotifModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  textarea {
    min-height: 50px;
  }
  span {
    margin: 10px auto;
  }
  ul {
    padding-left: 0;
  }
  li {
    text-align: center;
    font-size: 13px;
    line-height: 150%;
    color: ${({ clr = Olympe.Color }) => clr.grey};
    margin: 0;
    list-style-type: none;
  }
`;

/* ACCOUNT VIEW PAGE */
export const ButtonsList = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-top: 10px;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }

  .icon {
    background-color: ${({ clr = Olympe.Color }) => clr.light};
    min-width: 40px;
    min-height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    svg,
    div {
      color: ${({ clr = Olympe.Color }) => clr.dark};
      width: 25px;
      height: 25px;
      transition: color 0.4s ease;
    }
  }

  .userPannel {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    min-width: 240px;
    border-radius: 10px;
    padding: 10px 20px;
    width: auto;
    cursor: inherit;

    background-color: ${({ clr = Olympe.Color }) => clr.black}66;
    border: 1px solid ${({ clr = Olympe.Color }) => clr.white}22;

    .icon {
      background-color: ${({ clr = Olympe.Color }) => clr.blueDark};

      div,
      svg {
        width: 40px;
        height: 40px;
      }
    }

    @media screen and (max-width: 700px) {
      min-width: 90%;
      max-width: 90%;
    }

    h2 {
      color: ${({ clr = Olympe.Color }) => clr.light};
      font-size: 1rem;
      margin-right: 10px;
    }
  }

  .list {
    width: 240px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 1000px) {
      min-width: 70%;
      max-width: 70%;
      margin: 20px 0 10px 0;
    }

    .button {
      position: relative;

      .badgeOn {
        position: absolute;
        top: 0;
        right: 0;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        display: block;
        background-color: ${Olympe.Color.blueLight};
      }

      &:not(.userPannel):hover svg {
        color: ${({ clr = Olympe.Color }) => clr.blue};
      }
    }
  }
`;

/* ACCOUNT PAGE */
export const HeaderDatatable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 700px) {
    flex-direction: column;
    padding-bottom: 30px;

    p {
      font-size: 14px;
    }
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    input {
      margin-right: 10px;

      @media screen and (max-width: 700px) {
        margin: 5px;
      }
    }
  }
`;

/* SETTINGS PAGE */
export const ListPwd = styled(Olympe.ItemFullWidth)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;

  span {
    padding: 10px 15px;
    margin: 5px;
    border-radius: 10px;
    background-color: ${({ clr = Olympe.Color }) => clr.dark}66;
    color: ${({ clr = Olympe.Color }) => clr.light};
    transition: background-color 0.2s ease-out;

    &::before {
      content: "- ";
    }

    &.filled {
      background-color: #44d7a8;

      &::before {
        content: "✓ ";
      }
    }
  }
`;

/* APPLICATION PAGE */
export const MainActu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .actuTitle {
    width: 40%;
  }

  .actuImg {
    width: 50%;
  }

  @media screen and (max-width: 700px) {
    flex-direction: column;
    justify-content: center;

    .actuTitle {
      width: 100%;
    }

    .actuImg {
      > svg {
        display: none;
      }

      > div {
        width: 100%;
      }

      width: 100%;
    }
  }
`;
export const ActuList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px 15px;
  margin: 5px 0;
  border: 1px solid transparent;
  border-radius: 10px;
  transition: border 0.4s ease, background-color 0.4s ease;

  p {
    display: flex;
    flex-direction: column;
    width: 100%;

    .footer {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      color: #767575;
      margin-top: 5px;

      @media screen and (max-width: 700px) {
        justify-content: center;
      }

      span {
        font-size: 14px;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        &:first-child {
          margin-right: 10px;
        }

        &:last-child {
          margin-left: 10px;
        }

        svg {
          width: 14px;
          margin-right: 5px;
          color: ${Olympe.Color.blueLight};
        }
      }
    }
  }

  @media screen and (max-width: 700px) {
    flex-direction: column;
    justify-content: center;

    p {
      text-align: center;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #ddd;
      padding-bottom: 30px;
    }
  }

  &:hover {
    border-color: rgba(255, 255, 255, 0.4);
    background-color: rgba(0, 0, 0, 0.4);
  }
`;
export const WelcomeMessage = styled(Olympe.ItemFullWidth)`
  .welcomeWrapper {
    background-color: #416772;
    padding: 30px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    width: 50%;
    max-width: 50%;
    min-width: 300px;
    margin: auto;

    @media screen and (max-width: 700px) {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
    }
  }

  .appSep {
    width: 150px;
    height: 8px;
    border-radius: 10px;
    display: block;
    margin: 10px auto;
    background-color: #39d699;
  }
`;

/* GLOBAL */
export const HorizontalWrap = styled.div`
  max-width: 100%;
  padding: 10px;

  display: flex;
  /* justify-content: center;
  align-items: center; */
  flex-direction: row;
  /* flex-wrap: wrap; */

  overflow-x: auto;
  gap: 10px;
`;
export const HorizontalCard = styled.div`
  background-color: ${Olympe.Color.white}33;
  border: 1px solid ${Olympe.Color.white}33;
  color: ${Olympe.Color.white};
  cursor: pointer;

  max-width: 230px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 10px;
  margin: 0;
  border-radius: 10px;

  position: relative;
  user-select: none;

  transition: background-color 0.4s ease, border-color 0.4s ease;

  > svg {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 16px;
  }

  img {
    object-fit: cover;
    height: 100px;
    width: 100px;
    border-radius: 50%;

    &.contain {
      object-fit: cover;
      height: 100px;
      width: 100px;
      border-radius: 5px;
    }
  }

  h3 {
    font-size: 13px;
    text-align: center;
  }

  p,
  span {
    text-align: center;
    font-size: 13px;
    color: ${Olympe.Color.grey};
    padding: 0;
    margin: 0;
  }

  span {
    font-size: 12px;
    margin-top: 5px;
    color: ${Olympe.Color.greyLight};

    &:first-of-type {
      margin-top: 10px;
    }
  }

  &:hover {
    background-color: ${Olympe.Color.white}44;
    border-color: ${Olympe.Color.white}88;
  }
`;

/* ADMIN PAGE */
export const EmployeeModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .imgWrap {
    border-radius: 50%;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    width: 100px;
    height: 100px;

    &:hover .overlay {
      opacity: 1;
    }
    &:hover img {
      opacity: 0.3;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.5s ease;
      backface-visibility: hidden;
    }

    .overlay {
      transition: 0.5s ease;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  input[type="date"] {
    margin-bottom: 10px;
  }

  .select {
    width: 100%;
    margin-bottom: 5px;

    color: ${Olympe.Color.dark};
    font-size: 0.9rem;
    margin: 10px 0;

    input {
      border: none;
      box-shadow: none;
    }

    .select__indicator {
      padding: 3px;
    }
  }

  .field {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    svg {
      height: 18px;
      margin-right: 5px;
    }

    input {
      margin-left: 5px;
    }
  }
`;

/* FILES PAGE */
export const ImagesModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    max-width: 200px;
    max-height: 200px;
    object-fit: contain;
    margin-bottom: 20px;
  }

  input[type="date"] {
    margin-bottom: 10px;
  }

  p {
    padding: 5px;
    margin: 0;
    color: ${Olympe.Color.grey};

    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;

    svg {
      margin-right: 10px;
    }

    &:first-of-type {
      margin-top: 10px;
    }
  }
`;

/* MEETINGS PAGE */
export const AskedCard = styled.div`
  background-color: ${Olympe.Color.white}33;
  border: 1px solid ${Olympe.Color.white}33;
  color: ${Olympe.Color.white};
  cursor: pointer;

  max-width: 210px;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  padding: 10px;
  margin: 0;
  border-radius: 10px;

  position: relative;
  user-select: none;

  transition: background-color 0.4s ease, border-color 0.4s ease;

  svg {
    width: 13px;
    margin-right: 5px;
    color: ${Olympe.Color.blueLight};
  }

  a {
    color: ${Olympe.Color.light};
    text-decoration: none;
    font-size: 16px;

    transition: color 0.4s ease;

    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      width: 16px;
    }

    &:hover {
      color: ${Olympe.Color.blueLight};
    }
  }

  h3 {
    color: ${Olympe.Color.white};
    font-size: 14px;
    margin-bottom: 0;
  }

  .date {
    color: ${Olympe.Color.greyLight};
    align-self: flex-end;
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 0;

    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &:hover {
    background-color: ${Olympe.Color.white}44;
    border-color: ${Olympe.Color.white}88;
  }
`;
export const MeetModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    color: ${Olympe.Color.light};
    font-size: 1rem;
    text-align: center;
  }

  svg {
    width: 13px;
    margin-right: 5px;
    color: ${Olympe.Color.blueLight};
  }

  p {
    padding: 5px;
    margin: 0;
    color: ${Olympe.Color.grey};

    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;

    svg {
      margin-right: 10px;
    }

    &:first-of-type {
      margin-top: 10px;
    }
  }

  p.location {
    color: ${Olympe.Color.greyLight};
    align-self: center;
    font-size: 13px;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
  }

  p.content {
    color: ${Olympe.Color.grey};
    align-self: center;
    font-style: italic;
    font-size: 14px;
    text-align: left;
  }

  .footer {
    width: 100%;

    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    p {
      color: ${Olympe.Color.greyLight};
      align-self: flex-end;
      font-size: 13px;
      margin-top: 0;
      margin-bottom: 0;

      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
`;
export const MeetAgenda = styled.div`
  margin: 20px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;

  @media screen and (max-width: 700px) {
    display: none;
  }

  .select {
    margin: 20px;
  }

  .fc {
    padding: 0 20px 20px;

    h2.fc-toolbar-title {
      color: ${Olympe.Color.dark};

      &:first-letter {
        text-transform: uppercase;
      }

      @media screen and (max-width: 700px) {
        padding-bottom: 10px;
      }
    }
  }

  .fc-header-toolbar,
  .fc-footer-toolbar {
    @media screen and (max-width: 700px) {
      flex-direction: column;

      .fc-toolbar-chunk {
        margin: 5px;
      }
    }
  }

  .fc-header-toolbar {
    padding: 10px 20px;
    margin-bottom: 0;

    .fc-toolbar-title {
      color: ${Olympe.Color.dark};
    }
  }

  .fc-timegrid-slot-label-cushion,
  .fc-col-header-cell-cushion {
    color: ${Olympe.Color.dark};
    font-weight: 600;
    font-size: 12px;
  }

  .fc-toolbar-title {
    font-size: 20px;
  }

  .fc-event {
    background-color: ${Olympe.Color.blueLight};
    cursor: pointer;

    &:hover {
      background-color: ${Olympe.Color.blue};
    }
  }

  .fc a[data-navlink] {
    color: ${Olympe.Color.greyLight};

    &:hover {
      color: ${Olympe.Color.blue};
      text-decoration: none;
    }
  }
`;
export const MeetList = styled(MeetAgenda)`
  margin: 0;
  background: rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.133);
  color: ${Olympe.Color.dark};
  padding-top: 20px;

  @media screen {
    display: flex;
  }

  @media screen and (max-width: 700px) {
    min-height: 500px;
  }

  .fc {
    width: 100%;

    @media screen and (max-width: 700px) {
      height: 600px;
    }

    h2.fc-toolbar-title {
      color: ${Olympe.Color.white};
    }

    .fc-view {
      background: ${Olympe.Color.white};
      border-radius: 10px;
      border: none;
      outline: none;
    }
    .fc-view-harness {
    }
  }
`;
