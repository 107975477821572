import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FaAndroid, FaApple } from "react-icons/fa";
import { toast } from "react-toastify";
import axios from "axios";
import { Olympe } from "dv-olympe";

import { MainContext } from "../datas/context";
import { axiosDelete, axiosGet, axiosPost, axiosPut } from "../datas/fetch";
import {
  AtSign,
  Check,
  Edit2,
  Facebook,
  Globe,
  Home,
  Image,
  Instagram,
  Linkedin,
  MapPin,
  MessageCircle,
  Monitor,
  Phone,
  PlusCircle,
  Tool,
  Trash,
  Users,
  UserX,
  XCircle,
  Youtube
} from "react-feather";
import {
  EmployeeModal,
  HorizontalCard,
  HorizontalWrap
} from "../components/StyledElements";
import Select from "react-select";

export default function AdminPage () {
  const context = React.useContext(MainContext);

  const [datas, setDatas] = useState([]);
  const [emps, setEmps] = useState([]);
  const [imgs, setImgs] = useState([]);
  const [editImg, setEditImg] = useState(false);
  const [rscLoad, setRscLoad] = useState(false);
  const [crpLoad, setCrpLoad] = useState(false);
  const [msgLoad, setMsgLoad] = useState(false);

  const [empLoad, setEmpLoad] = useState(false);
  const [empPhoto, setEmpPhoto] = useState({});
  const [open, setOpen] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const closeModal = () => setOpen(false);
  const closeNewModal = () => setOpenNew(false);

  const {
    register,
    reset,
    setValue,
    setFocus,
    getValues,
    watch,
    formState: { dirtyFields }
  } = useForm();

  React.useEffect(() => {
    const refreshInfos = async () => {
      axiosGet({
        apiKey: context.api.key,
        uri: "corp/"
      })
        .then((data) => {
          if (!data.infos || !data.emps || !data.imgs) {
            throw new Error("Données d'entreprises erronées.");
          }
          setDatas(data.infos);
          if (Array.isArray(data.imgs)) {
            const i = data.imgs;
            i.unshift({
              label: "Logo",
              value: ""
            });
            setImgs(i);
          }
          setEmps(data.emps);
        })
        .catch((e) => {
          toast.error(
            `Chargement des données impossible, veuillez réessayer plus tard : ${e.message}`,
            {
              autoClose: false
            }
          );
        });
    };

    watch([
      "clName",
      "clMail",
      "clRole",
      "empId",
      "empName",
      "empTel",
      "empJob",
      "empPhoto",
      "empField"
    ]);

    refreshInfos();
    const datasInterval = setInterval(() => refreshInfos(), 3000);

    return () => {
      clearInterval(datasInterval);
    };
  }, []);

  if (!context.corp.infos || !datas.corpName) return <Olympe.Loader />;

  const submitRessources = () => {
    setRscLoad(true);
    const yt = getValues("corpYoutube");
    const fb = getValues("corpFacebook");
    const ig = getValues("corpInstagram");
    const lk = getValues("corpLinkedin");
    const name = getValues("corpName");
    const linkApple = getValues("linkApple");
    const linkAndroid = getValues("linkAndroid");

    axiosPut({
      uri: "corp",
      apiKey: context.api.key,
      opt: {
        yt,
        fb,
        ig,
        lk,
        linkApple,
        linkAndroid,
        appName: name,
        appVersion: datas.appVers,
        chatGuest: datas.chatGuest,
        chatEmpty: datas.chatEmpty
      }
    })
      .then((data) => {
        if (data !== "true") {
          throw new Error(data || "requête serveur erronée");
        }

        toast.success(
          "Les informations des ressources ont bien été modifiées."
        );

        reset({
          corpYoutube: yt,
          corpFacebook: fb,
          corpInstagram: ig,
          corpLinkedin: lk,
          corpName: name,
          linkApple,
          linkAndroid
        });
      })
      .catch((e) => {
        toast.error(
          "Une erreur est survenue, les ressources n'ont pas été modifiées : " +
            e.message
        );
      })
      .finally(() => setRscLoad(false));
  };

  const submitChats = () => {
    setMsgLoad(true);
    const guest = getValues("chatGuest");
    const empty = getValues("chatEmpty");

    axiosPut({
      uri: "corp",
      apiKey: context.api.key,
      opt: {
        yt: datas.corpYoutube,
        fb: datas.corpFacebook,
        ig: datas.corpInstagram,
        lk: datas.corpLinkedin,
        linkApple: datas.iosLink,
        linkAndroid: datas.andLink,
        appName: datas.corpName,
        appVersion: datas.appVers,
        chatGuest: guest,
        chatEmpty: empty
      }
    })
      .then((data) => {
        if (data !== "true") {
          throw new Error(data || "requête serveur erronée");
        }

        toast.success(
          "Les informations des messagerie ont bien été modifiées."
        );

        reset({
          chatGuest: guest,
          chatEmpty: empty
        });
      })
      .catch((e) => {
        toast.error(
          "Une erreur est survenue, les informations de messagerie n'ont pas été modifiées : " +
            e.message
        );
      })
      .finally(() => setMsgLoad(false));
  };

  const submitCorp = () => {
    setCrpLoad(true);
    const mail = getValues("corpMail");
    const addr = getValues("corpAddr");
    const tel = getValues("corpTel");
    const site = getValues("corpSite");
    const logo = getValues("corpLogo");

    axios
      .put(
        "https://api.damien-vergobbi.fr/v1/corp/",
        {
          mail,
          addr,
          tel,
          site,
          logo,
          key: context.corp.infos.corp.key
        },
        {
          auth: {
            username: context.api.key
          }
        }
      )
      .then(({ data }) => {
        if (data !== "true") {
          throw new Error(data || "requête serveur erronée");
        }

        toast.success(
          "Les informations publiques de l'entreprise ont bien été modifiées."
        );

        reset({
          corpMail: mail,
          corpAddr: addr,
          corpTel: tel,
          corpSite: site,
          corpLogo: logo
        });
      })
      .catch((e) => {
        toast.error(
          "Une erreur est survenue, les informations publiques de l'entreprise n'ont pas été modifiées : " +
            e.message
        );
      })
      .finally(() => setCrpLoad(false));
  };

  const magicNumTel = (e, field) => {
    const value = e.target.value
      .replaceAll(/\s/g, "")
      .replace("+330", "0")
      .replace("+33", "0")
      .slice(0, 10)
      .replace(/\d{2}(?=.)/g, "$& ");

    setValue(field, value);
  };

  const verifEmail = (elt, original) => {
    const rgx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (!rgx.test(elt.value)) {
      toast.error("Email non conforme, l'ancienne adresse a été rétablit.");
      setValue(elt.name, original);
      setFocus(elt.name);
    }
  };

  const verifLink = (elt, original) => {
    const link =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
    if (!link.test(elt.value)) {
      toast.error("URL non conforme, l'ancienne a été rétablit.");
      setValue(elt.name, original);
      setFocus(elt.name);
      return;
    }
    if (
      elt.name === "corpLogo" &&
      !elt.value.startsWith(getValues("corpSite"))
    ) {
      toast.info(
        "L'URL du logo doit commencer avec la même URL du site, elle n'a donc pas été modifiée."
      );
      setValue(elt.name, original);
      setFocus(elt.name);
      return;
    }
    if (
      (elt.name.includes("Linkedin") && !elt.value.includes("linkedin.com")) ||
      (elt.name.includes("Youtube") && !elt.value.includes("youtube.com")) ||
      (elt.name.includes("Instagram") &&
        !elt.value.includes("instagram.com")) ||
      (elt.name.includes("Facebook") && !elt.value.includes("facebook.com")) ||
      (elt.name.includes("Apple") && !elt.value.includes("apple.com")) ||
      (elt.name.includes("Android") && !elt.value.includes("android.com"))
    ) {
      toast.error(
        `L'URL ne semble pas rediriger vers le site ${elt.name
          .replace("corp", "")
          .replace("link", "")}, l'ancienne a été rétablit.`
      );
      setValue(elt.name, original);
      setFocus(elt.name);
    }
  };

  const verifName = (elt, original) => {
    if (elt.length < 1) {
      toast.error("Nom non conforme, l'ancien a été rétablit.");
      setValue(elt.name, original);
      setFocus(elt.name);
    }
  };

  const renderEmpCard = (elt, idx) => (
    <HorizontalCard
      key={elt.name}
      onClick={() => {
        const uri = elt.photo.split("/").pop().split("?")[0].trim();
        ModalEmp(idx);
        setEmpPhoto({
          label: uri === "logo-little.png" ? "Logo" : uri,
          value:
            uri === "logo-little.png"
              ? ""
              : elt.photo
                .split("/")
                .pop()
                .split("?")[0]
                .trim()
                .toLowerCase()
                .replace(/[^a-zA-Z0-9]+/g, "")
        });
        setValue("empId", elt.id);
        setValue("empName", elt.name);
        setValue("empTel", elt.tel);
        setValue("empJob", elt.job);
        setValue("empPhoto", elt.photo);
        setValue("empField", !!elt.field);
        setValue("empDate", elt.date);
        setOpen(true);
      }}
    >
      {elt.field
        ? (
          <Olympe.Tooltips title={`${elt.name} va sur le terrain.`} notSpan>
            <Tool />
          </Olympe.Tooltips>
        )
        : (
          false
        )}
      <img src={elt.photo} alt={elt.name} height={100} width={100} />
      <h3>{elt.name}</h3>
      <p>{elt.job}</p>
    </HorizontalCard>
  );

  const handleEditEmp = () => {
    setEmpLoad(true);

    const id = getValues("empId");
    const name = getValues("empName");
    const tel = getValues("empTel") || "";
    const job = getValues("empJob");
    const photo = getValues("empPhoto").split("/").pop().split("?")[0].trim();
    const field = getValues("empField");
    const date = getValues("empDate");

    // console.log({ id, name, tel, job, photo, field, date });

    axiosPut({
      apiKey: context.api.key,
      uri: "employee",
      opt: {
        id,
        name,
        tel,
        job,
        photo: photo === "logo-little.png" ? "" : photo,
        field,
        date
      }
    })
      .then((data) => {
        if (data !== "true") {
          throw new Error(data || "réponse serveur erronée.");
        }
        toast.success("Les informations de l'employé ont bien été modifiées.", {
          icon: <Edit2 />
        });
        // navigate(`/products/${infos.corp}`);
        setOpen(false);
        setEditImg(false);
      })
      .catch((e) =>
        toast.error(
          "Une erreur est survenue, les informations de l'employé n'ont pas été modifiées : " +
            e.message
        )
      )
      .finally(() => {
        setEmpLoad(false);
      });
  };

  const handleAddEmp = () => {
    setEmpLoad(true);

    const name = getValues("empName");
    const tel = getValues("empTel") || "";
    const job = getValues("empJob");
    const photo = getValues("empPhoto").split("/").pop().split("?")[0].trim();
    const field = getValues("empField");
    const date = getValues("empDate");

    axiosPost({
      apiKey: context.api.key,
      uri: "employee",
      opt: {
        name,
        tel,
        job,
        photo: photo === "logo-little.png" ? "" : photo,
        field,
        date
      }
    })
      .then((data) => {
        if (data !== "true") {
          throw new Error(data || "réponse serveur erronée.");
        }
        toast.success(`L'employé ${name} a bien été ajouté.`, {
          icon: <PlusCircle />
        });
        setOpenNew(false);
        setEditImg(false);
      })
      .catch((e) =>
        toast.error(
          "Une erreur est survenue, l'employé n'a pas été ajouté : " + e.message
        )
      )
      .finally(() => {
        setEmpLoad(false);
      });
  };

  const handleDeleteEmp = () => {
    setEmpLoad(true);

    const id = getValues("empId");

    axiosDelete({
      apiKey: context.api.key,
      uri: "employee",
      opt: { id }
    })
      .then((data) => {
        if (data !== "true") {
          throw new Error(data || "réponse serveur erronée.");
        }
        toast.success("L'employé a bien été supprimé de la base de données.", {
          icon: <Trash />
        });
        setOpen(false);
      })
      .catch((e) =>
        toast.error(
          "Une erreur est survenue, l'employé n'a pas été supprimé de la base de données: " +
            e.message
        )
      )
      .finally(() => {
        setEmpLoad(false);
      });
  };

  const ModalEmp = () => (
    <Olympe.Modal
      open={open}
      loading={empLoad}
      onClose={closeModal}
      title="Modifier un employé"
      content={
        <EmployeeModal>
          <div className="imgWrap" onClick={() => setEditImg((prev) => !prev)}>
            <img
              src={getValues("empPhoto")}
              alt={getValues("empName")}
              height={100}
              width={100}
            />

            <div className="overlay">
              <div>{editImg ? "Valider" : "Modifier"}</div>
            </div>
          </div>

          <Olympe.Input.Text
            type="date"
            {...register("empDate")}
            defaultValue={""}
            autoComplete="off"
            placeholder="Recrutement"
            onKeyPress={(e) => {
              e.key === "Enter" && Olympe.Utils.enterInput(e);
            }}
          />

          {editImg && (
            <Select
              className="select"
              options={imgs || []}
              placeholder="Photo de l'employé"
              value={empPhoto}
              onChange={(t) => {
                setEmpPhoto(t);
                setValue(
                  "empPhoto",
                  t.label === "Logo"
                    ? datas.littleLogo
                    : getValues("empPhoto")
                      .replace("/utils/", "/images/")
                      .replace(
                        getValues("empPhoto").split("/").pop(),
                        t.label
                      )
                      .split("?")[0]
                      .trim()
                );
              }}
              noOptionsMessage={() => "Aucune image trouvée 😬"}
            />
          )}

          <Olympe.Input.Text
            type="text"
            {...register("empName")}
            defaultValue={""}
            autoComplete="off"
            placeholder="Prénom Nom"
            onKeyPress={(e) => {
              e.key === "Enter" && Olympe.Utils.enterInput(e);
            }}
          />

          <Olympe.Input.Text
            type="text"
            {...register("empJob")}
            defaultValue={""}
            autoComplete="off"
            placeholder="Métier"
            onKeyPress={(e) => {
              e.key === "Enter" && Olympe.Utils.enterInput(e);
            }}
          />

          <Olympe.Input.Text
            type="text"
            {...register("empTel")}
            defaultValue={""}
            autoComplete="off"
            placeholder="Téléphone"
            onBlur={(e) => magicNumTel(e, "empTel")}
            onKeyPress={(e) => {
              e.key === "Enter" && Olympe.Utils.enterInput(e);
            }}
          />

          <div className="field">
            {getValues("empField") ? <Tool /> : <Monitor />} L'employé{" "}
            {getValues("empField") ? "va" : "ne va pas"} sur le terrain
            <Olympe.Input.Text
              type="checkbox"
              {...register("empField")}
              defaultValue={false}
              autoComplete="off"
              onKeyPress={(e) => {
                e.key === "Enter" && Olympe.Utils.enterInput(e);
              }}
            />
          </div>
        </EmployeeModal>
      }
      buttons={[
        {
          isCancel: true,
          label: "Annuler",
          icon: <XCircle />,
          exec: closeModal
        },
        {
          isDanger: !getValues("empName"),
          label: !getValues("empName") ? "Supprimer" : "Modifier",
          icon: !getValues("empName") ? <Trash /> : <Edit2 />,
          exec:
            !getValues("empName") || getValues("empName").length === 0
              ? handleDeleteEmp
              : handleEditEmp
        }
      ]}
    />
  );

  return (
    <Olympe.Wrapper>
      {ModalEmp()}

      <Olympe.Modal
        open={openNew}
        loading={empLoad}
        onClose={closeNewModal}
        title="Ajouter un employé"
        content={
          <EmployeeModal>
            <div
              className="imgWrap"
              onClick={() => setEditImg((prev) => !prev)}
            >
              <img
                src={getValues("empPhoto")}
                alt={getValues("empName")}
                height={100}
                width={100}
              />

              <div className="overlay">
                <div>{editImg ? "Valider" : "Modifier"}</div>
              </div>
            </div>

            <Olympe.Input.Text
              type="date"
              {...register("empDate")}
              defaultValue={""}
              autoComplete="off"
              placeholder="Recrutement"
              onKeyPress={(e) => {
                e.key === "Enter" && Olympe.Utils.enterInput(e);
              }}
            />

            {editImg && (
              <Select
                className="select"
                options={imgs || []}
                placeholder="Photo de l'employé"
                defaultValue={[]}
                onChange={(t) => {
                  setEmpPhoto(t);
                  setValue(
                    "empPhoto",
                    t.label === "Logo"
                      ? datas.littleLogo
                      : getValues("empPhoto")
                        .replace("/utils/", "/images/")
                        .replace(
                          getValues("empPhoto").split("/").pop(),
                          t.label
                        )
                        .split("?")[0]
                        .trim()
                  );
                }}
                noOptionsMessage={() => "Aucune image trouvée 😬"}
              />
            )}

            <Olympe.Input.Text
              type="text"
              {...register("empName")}
              defaultValue={""}
              autoComplete="off"
              placeholder="Prénom Nom"
              onKeyPress={(e) => {
                e.key === "Enter" && Olympe.Utils.enterInput(e);
              }}
            />

            <Olympe.Input.Text
              type="text"
              {...register("empJob")}
              defaultValue={""}
              autoComplete="off"
              placeholder="Métier"
              onKeyPress={(e) => {
                e.key === "Enter" && Olympe.Utils.enterInput(e);
              }}
            />

            <Olympe.Input.Text
              type="text"
              {...register("empTel")}
              defaultValue={""}
              autoComplete="off"
              placeholder="Téléphone"
              onBlur={(e) => magicNumTel(e, "empTel")}
              onKeyPress={(e) => {
                e.key === "Enter" && Olympe.Utils.enterInput(e);
              }}
            />

            <div className="field">
              {getValues("empField") ? <Tool /> : <Monitor />} L'employé{" "}
              {getValues("empField") ? "va" : "ne va pas"} sur le terrain
              <Olympe.Input.Text
                type="checkbox"
                {...register("empField")}
                defaultValue={false}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.key === "Enter" && Olympe.Utils.enterInput(e);
                }}
              />
            </div>
          </EmployeeModal>
        }
        buttons={[
          {
            isCancel: true,
            label: "Annuler",
            icon: <XCircle />,
            exec: closeNewModal
          },
          {
            label: "Valider",
            icon: <Check />,
            exec:
              !getValues("empName") || getValues("empName").length === 0
                ? false
                : handleAddEmp
          }
        ]}
      />

      <Olympe.SubItem>
        <Olympe.ItemFullWidth>
          <Olympe.ItemTitle>
            <span>
              <Users />
              Employés
            </span>

            <Olympe.Tooltips
              title={"Ajouter un employé"}
              classes="icon"
              placement="left"
            >
              <Olympe.Button.Basic
                toExecute={() => {
                  setEditImg(false);
                  setEmpPhoto({});
                  reset({
                    empName: "",
                    empDate: "",
                    empTel: "",
                    empJob: "",
                    empPhoto: "",
                    empField: true
                  });
                  setValue("empPhoto", datas.littleLogo);
                  setOpenNew(true);
                }}
                icon={<PlusCircle />}
              />
            </Olympe.Tooltips>
          </Olympe.ItemTitle>

          <HorizontalWrap>
            {emps.map((elt, idx) => renderEmpCard(elt, idx))}
          </HorizontalWrap>
        </Olympe.ItemFullWidth>
      </Olympe.SubItem>

      <Olympe.SubItem>
        <Olympe.ItemFullWidth>
          <Olympe.ItemTitle>Informations de l'entreprise</Olympe.ItemTitle>

          <Olympe.ItemWithAvatar className="center">
            <div className="infosSep">
              <div className="usrDetails">
                <AtSign />
                <div className="basicInput">
                  <Olympe.Input.Text
                    type="text"
                    {...register("corpMail")}
                    defaultValue={context.corp.infos.corp.mail}
                    autoComplete="off"
                    placeholder="Adresse mail de contact"
                    onKeyPress={(e) => {
                      e.key === "Enter" && Olympe.Utils.enterInput(e);
                    }}
                    onBlur={(e) =>
                      verifEmail(e.target, context.corp.infos.corp.mail)
                    }
                  />
                </div>
              </div>

              <div className="usrDetails">
                <MapPin />
                <div className="basicInput">
                  <Olympe.Input.Text
                    type="text"
                    {...register("corpAddr")}
                    defaultValue={context.corp.infos.corp.addr}
                    autoComplete="off"
                    placeholder="Adresse des bureaux"
                    onKeyPress={(e) => {
                      e.key === "Enter" && Olympe.Utils.enterInput(e);
                    }}
                  />
                </div>
              </div>

              <div className="usrDetails">
                <Phone />
                <div className="basicInput">
                  <Olympe.Input.Text
                    type="text"
                    {...register("corpTel")}
                    defaultValue={context.corp.infos.corp.tel}
                    autoComplete="off"
                    placeholder="Téléphone de contact"
                    onKeyPress={(e) => {
                      e.key === "Enter" && Olympe.Utils.enterInput(e);
                    }}
                    onBlur={(e) => magicNumTel(e, "corpTel")}
                  />
                </div>
              </div>

              <div className="usrDetails">
                <Globe />
                <div className="basicInput">
                  <Olympe.Input.Text
                    type="text"
                    {...register("corpSite")}
                    defaultValue={context.corp.infos.corp.link}
                    autoComplete="off"
                    placeholder="URL de base du serveur"
                    onKeyPress={(e) => {
                      e.key === "Enter" && Olympe.Utils.enterInput(e);
                    }}
                    onBlur={(e) =>
                      verifLink(e.target, context.corp.infos.corp.link)
                    }
                  />
                </div>
              </div>

              <div className="usrDetails">
                <Image />
                <div className="basicInput">
                  <Olympe.Tooltips title="URL du logo" notSpan>
                    <Olympe.Input.Text
                      type="text"
                      {...register("corpLogo")}
                      defaultValue={context.corp.infos.corp.logo}
                      autoComplete="off"
                      placeholder="Logo par défaut"
                      onKeyPress={(e) => {
                        e.key === "Enter" && Olympe.Utils.enterInput(e);
                      }}
                      onBlur={(e) =>
                        verifLink(e.target, context.corp.infos.corp.logo)
                      }
                    />
                  </Olympe.Tooltips>
                </div>
              </div>
            </div>

            <div className="infosSep avatar">
              <span className="icon">
                <img
                  src={getValues("corpLogo") || context.corp.infos.corp.logo}
                  alt={context.corp.infos.corp.name}
                  height={120}
                />
              </span>
            </div>
          </Olympe.ItemWithAvatar>

          <div className="footerInfos">
            {crpLoad
              ? (
                <Olympe.MiniLoader />
              )
              : (
                <Olympe.Button.Basic
                  label="Enregister les informations"
                  icon={<Check />}
                  toExecute={
                    dirtyFields.corpMail ||
                  dirtyFields.corpAddr ||
                  dirtyFields.corpTel ||
                  dirtyFields.corpSite ||
                  dirtyFields.corpLogo
                      ? submitCorp
                      : false
                  }
                />
              )}
          </div>
        </Olympe.ItemFullWidth>
      </Olympe.SubItem>

      <Olympe.SubItem>
        <Olympe.ItemFullWidth>
          <Olympe.ItemTitle>Nom et liens externes</Olympe.ItemTitle>

          <div className="usrDetails">
            <Home color={context.clr.light} />

            <div className="basicInput">
              <Olympe.Input.Text
                type="text"
                {...register("corpName")}
                defaultValue={datas.corpName}
                autoComplete="off"
                placeholder="Nom de l'entreprise"
                onKeyPress={(e) => {
                  e.key === "Enter" && Olympe.Utils.enterInput(e);
                }}
                onBlur={(e) => verifName(e.target, datas.corpName)}
              />
            </div>
          </div>

          <div className="usrDetails">
            <Linkedin color={context.clr.blue} />

            <div className="basicInput">
              <Olympe.Tooltips title="URL du compte Linkedin" notSpan>
                <Olympe.Input.Text
                  type="text"
                  {...register("corpLinkedin")}
                  defaultValue={datas.corpLinkedin}
                  autoComplete="off"
                  placeholder="Lien du compte Linkedin"
                  onKeyPress={(e) => {
                    e.key === "Enter" && Olympe.Utils.enterInput(e);
                  }}
                  onBlur={(e) => verifLink(e.target, datas.corpLinkedin)}
                />
              </Olympe.Tooltips>
            </div>
          </div>

          <div className="usrDetails">
            <Youtube color="red" />

            <div className="basicInput">
              <Olympe.Tooltips title="URL de la chaine YouTube" notSpan>
                <Olympe.Input.Text
                  type="text"
                  {...register("corpYoutube")}
                  defaultValue={datas.corpYoutube}
                  autoComplete="off"
                  placeholder="Lien de la chaine YouTube"
                  onKeyPress={(e) => {
                    e.key === "Enter" && Olympe.Utils.enterInput(e);
                  }}
                  onBlur={(e) => verifLink(e.target, datas.corpYoutube)}
                />
              </Olympe.Tooltips>
            </div>
          </div>

          <div className="usrDetails">
            <Instagram color={context.clr.light} />

            <div className="basicInput">
              <Olympe.Tooltips title="URL du compte instagram" notSpan>
                <Olympe.Input.Text
                  type="text"
                  {...register("corpInstagram")}
                  defaultValue={datas.corpInstagram}
                  autoComplete="off"
                  placeholder="Lien du compte Instagram"
                  onKeyPress={(e) => {
                    e.key === "Enter" && Olympe.Utils.enterInput(e);
                  }}
                  onBlur={(e) => verifLink(e.target, datas.corpInstagram)}
                />
              </Olympe.Tooltips>
            </div>
          </div>

          <div className="usrDetails">
            <Facebook color={context.clr.blue} />

            <div className="basicInput">
              <Olympe.Tooltips title="URL du compte Facebook" notSpan>
                <Olympe.Input.Text
                  type="text"
                  {...register("corpFacebook")}
                  defaultValue={datas.corpFacebook}
                  autoComplete="off"
                  placeholder="Lien du compte Facebook"
                  onKeyPress={(e) => {
                    e.key === "Enter" && Olympe.Utils.enterInput(e);
                  }}
                  onBlur={(e) => verifLink(e.target, datas.corpFacebook)}
                />
              </Olympe.Tooltips>
            </div>
          </div>

          <div className="usrDetails">
            <FaApple color={context.clr.light} />

            <div className="basicInput">
              <Olympe.Tooltips title="URL de l'App Store" notSpan>
                <Olympe.Input.Text
                  type="text"
                  {...register("linkApple")}
                  defaultValue={datas.iosLink}
                  autoComplete="off"
                  placeholder="Lien de l'app"
                  onKeyPress={(e) => {
                    e.key === "Enter" && Olympe.Utils.enterInput(e);
                  }}
                  onBlur={(e) => verifLink(e.target, datas.iosLink)}
                />
              </Olympe.Tooltips>
            </div>
          </div>

          <div className="usrDetails">
            <FaAndroid color="#7CB342" />

            <div className="basicInput">
              <Olympe.Tooltips title="URL de Google Play Store" notSpan>
                <Olympe.Input.Text
                  type="text"
                  {...register("linkAndroid")}
                  defaultValue={datas.andLink}
                  autoComplete="off"
                  placeholder="Lien de l'app"
                  onKeyPress={(e) => {
                    e.key === "Enter" && Olympe.Utils.enterInput(e);
                  }}
                  onBlur={(e) => verifLink(e.target, datas.andLink)}
                />
              </Olympe.Tooltips>
            </div>
          </div>

          <div className="footerInfos">
            {rscLoad
              ? (
                <Olympe.MiniLoader />
              )
              : (
                <Olympe.Button.Basic
                  label="Enregister"
                  icon={<Check />}
                  toExecute={
                    dirtyFields.corpName ||
                  dirtyFields.corpFacebook ||
                  dirtyFields.corpInstagram ||
                  dirtyFields.corpYoutube ||
                  dirtyFields.corpLinkedin ||
                  dirtyFields.linkApple ||
                  dirtyFields.linkAndroid
                      ? submitRessources
                      : false
                  }
                />
              )}
          </div>
        </Olympe.ItemFullWidth>
      </Olympe.SubItem>

      <Olympe.SubItem>
        <Olympe.ItemFullWidth>
          <Olympe.ItemTitle>
            <span>
              <MessageCircle />
              Messagerie vide
            </span>
          </Olympe.ItemTitle>

          <div className="usrDetails">
            <textarea
              {...register("chatEmpty")}
              defaultValue={datas.chatEmpty}
              placeholder="Ce message sera affiché sur le fil des conversations vides."
            />
          </div>

          <Olympe.ItemTitle>
            <span>
              <UserX />
              Messagerie du compte invité
            </span>
          </Olympe.ItemTitle>

          <div className="usrDetails">
            <textarea
              {...register("chatGuest")}
              defaultValue={datas.chatGuest}
              placeholder="Ce message sera affiché sur la messagerie du compte invité."
            />
          </div>

          <div className="footerInfos">
            {msgLoad
              ? (
                <Olympe.MiniLoader />
              )
              : (
                <Olympe.Button.Basic
                  label="Enregister"
                  icon={<Check />}
                  toExecute={
                    dirtyFields.chatEmpty || dirtyFields.chatGuest
                      ? submitChats
                      : false
                  }
                />
              )}
          </div>
        </Olympe.ItemFullWidth>
      </Olympe.SubItem>
    </Olympe.Wrapper>
  );
}
