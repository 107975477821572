import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  AlertTriangle,
  Clipboard,
  Edit2,
  File,
  FilePlus,
  FolderPlus,
  Image,
  Trash,
  Upload,
  X,
  XCircle
} from "react-feather";
import moment from "moment";
import { toast } from "react-toastify";
import axios from "axios";
import $ from "jquery";
import { LinearProgress } from "@mui/material";
import { Olympe } from "dv-olympe";

import { axiosDelete, axiosGet, axiosPut } from "../datas/fetch";
import { MainContext } from "../datas/context";
import { ItemFile } from "../components/StyledItem";
import {
  HorizontalCard,
  HorizontalWrap,
  ImagesModal
} from "../components/StyledElements";

const columns = [
  {
    name: "Id",
    // selector: (row, idx) => idx + 1,
    selector: (row, idx) => idx,
    format: (row, idx) =>
      row.creation === false
        ? (
          <span className="errorRow">
            <AlertTriangle /> {idx + 1}
          </span>
        )
        : (
          idx + 1
        ),
    width: "80px",
    hide: "sm"
  },
  {
    name: "Nom du fichier",
    selector: (row) =>
      Olympe.Utils.truncStr({ str: row.fileName || "nc", len: 40 }),
    grow: 7,
    sortable: true
  },
  {
    name: "Taille",
    selector: (row) => row.size,
    format: (row) => Olympe.Utils.getFileSize(row.size),
    grow: 3,
    sortable: true,
    hide: "md"
  },
  {
    name: "Date",
    selector: (row) =>
      row.creation === false ? moment().format("YYYY-MM-DD") : row.fileDate,
    format: (row) =>
      row.creation === false
        ? moment().format("ll")
        : moment(row.fileDate).format("ll"),
    sortable: true,
    grow: 2,
    hide: "md"
  }
];

export default function FilesView () {
  const context = React.useContext(MainContext);
  const params = useParams();
  const navigate = useNavigate();

  const [imgs, setImgs] = useState([]);
  const [loading, setLoad] = useState(!!params.usrId);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [searchFile, setSearchFile] = useState("");

  const [imgLoad, setImgLoad] = useState(false);
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);

  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(0);
  const [fileLoad, setFileLoad] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(null);

  const { register, setValue, getValues, watch } = useForm();

  useEffect(() => {
    const refreshInfos = async () =>
      axiosGet({
        apiKey: context.api.key,
        uri: "files/"
      }).then((data) => {
        setUsers(data.files || []);
        setImgs(data.imgs || []);

        if (params.usrId && !data.files[0].id) {
          toast.error("Utilisateur non trouvé.", { icon: "🔎" });
          navigate("/accounts/");
          return;
        }

        if (loading) setLoad(false);
      });

    if (params.usrId === "pdf") navigate("/files/");

    refreshInfos();
    watch([
      "fileName",
      "imgName",
      "imgNameVerif",
      "imgUrl",
      "imgExt",
      "imgSize",
      "imgDate",
      "imgCount"
    ]);
    const datasInterval = setInterval(() => refreshInfos(), 2000);

    return () => {
      clearInterval(datasInterval);
    };
  }, [context.api.key, loading, navigate, params.usrId, watch]);

  const handleSearch = (event) => setSearch(event.target.value);
  const handleSearchFile = (event) => setSearchFile(event.target.value);

  const filteredDatas = () => {
    const ret = [];
    const tmp = users
      .filter(
        (obj) =>
          obj.id.toLowerCase().includes(search.toLowerCase()) ||
          obj.name.toLowerCase().includes(search.toLowerCase()) ||
          obj.count.toString().includes(search.toLowerCase()) ||
          Olympe.Utils.getFileSize(obj.size)
            .toLowerCase()
            .includes(search.toLowerCase())
      )
      .sort((a, b) => b.size - a.size)
      .sort((a, b) => (a.related < b.related ? 1 : -1));

    for (let i = 0; i < tmp.length; i++) {
      ret.push({
        id: tmp[i].id,
        name: tmp[i].name,
        desc:
          tmp[i].count !== tmp[i].related
            ? (
              <span style={{ color: Olympe.Color.tomato }}>
                {Math.abs(tmp[i].related - tmp[i].count)} Erroné
                {Math.abs(tmp[i].related - tmp[i].count) > 1 && "s"}
              </span>
            )
            : tmp[i].count === 0
              ? (
                "Aucun fichier."
              )
              : (
                <span className="text">
                  {tmp[i].count} <File /> ● {Olympe.Utils.getFileSize(tmp[i].size)}
                </span>
              ),
        badge: Math.abs(tmp[i].related - tmp[i].count)
      });
    }
    return ret;
  };

  const filteredFilesDatas = () => {
    const tmp = [];

    if (params.usrId) {
      for (let i = 0; i < users.length; i++) {
        if (users[i] && users[i].id === params.usrId) {
          for (let k = 0; k < users[i].files.length; k++) {
            tmp.push(users[i].files[k]);
          }
          break;
        }
      }
    } else {
      for (let i = 0; i < users.length; i++) {
        if (users[i].count > 0) {
          for (let k = 0; k < users[i].files.length; k++) {
            tmp.push(users[i].files[k]);
          }
        }
      }
    }
    return tmp.filter(
      (obj) =>
        obj.fileName.toLowerCase().includes(searchFile.toLowerCase()) ||
        obj.fileUrl.toLowerCase().includes(searchFile.toLowerCase())
    );
  };

  const filteredFilesUser = () => {
    const tmp = {
      id: null,
      name: null
    };

    if (params.usrId) {
      for (let i = 0; i < users.length; i++) {
        if (users[i] && users[i].id === params.usrId) {
          tmp.id = users[i].id;
          tmp.name = users[i].name;
          break;
        }
      }
    }

    return tmp;
  };

  const selectFile = (event) => {
    setSelectedFiles(event.target.files);

    if (event.target.files[0]) {
      setValue("fileName", magicFileName(event.target.files[0].name));
    } else {
      setSelectedFiles(null);
    }
  };

  const magicFileName = (str = "") => {
    const tmp = str
      .replace(/\.[^/.]+$/, "")
      .replace(/[&/\\#,+$~%.'"*?<>{}]/g, "");

    if (tmp.length === 0 || tmp === "Logo") {
      return "Nouvelle image " + moment().format("YYYY-MM-DD HH-mm-ss");
    }
    return tmp;
  };

  const UploadFile = () => {
    if (!selectedFiles) {
      return console.error("Empty file");
    }

    let count = 0;
    const formData = new FormData();
    const currentFile = selectedFiles[0];

    const fileName =
      getValues("fileName").length === 0
        ? "Nouveau Fichier"
        : magicFileName(getValues("fileName"));

    formData.append("file", currentFile);
    setProgress(0);
    setBuffer(0);
    setFileLoad(true);

    const toastId = toast.info("Préparation au téléchargement", {
      isLoading: true,
      autoClose: false
    });

    return axios
      .post(
        `https://api.mayansenergies.com/v1/file/?fileName=${fileName}&item=image`,
        formData,
        {
          auth: { username: context.api.key },
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            count++;
            const prog = progressEvent.loaded / progressEvent.total;

            if (buffer === 0) setBuffer(prog * 100 * 2);

            setBuffer(Math.round(100 * prog) + Math.round(100 * prog) / count);
            setProgress(Math.round(100 * prog));

            if (prog < 1) {
              toast.update(toastId, {
                render: `Téléchargé à ${Math.round(prog * 100)} %`,
                progress,
                isLoading: true
              });
            }
          }
        }
      )
      .then(({ data }) => {
        if (data !== "true" && data !== "true_compress") {
          throw new Error(data || "réponse serveur erronée.");
        }

        toast.update(toastId, {
          render: `L'image "${fileName}" a bien été téléchargée ${
            data === "true_compress" ? "et compressée" : ""
          }.`,
          type: "success",
          isLoading: false,
          autoClose: true,
          icon: <Upload />
        });
      })
      .catch((e) => {
        toast.update(toastId, {
          render: `L'image "${fileName}" n'a pas été téléchargée : ${e.message}`,
          type: "error",
          isLoading: false,
          autoClose: true
        });
      })
      .finally(() => {
        setProgress(0);
        setFileLoad(false);
        setSelectedFiles(null);
      });
  };

  const renderImgCard = (elt) => (
    <HorizontalCard
      key={elt.name}
      onClick={() => {
        setValue("imgUrl", elt.url);
        setValue("imgExt", elt.ext);
        setValue("imgDate", elt.date);
        setValue("imgSize", elt.size);
        setValue("imgName", elt.name.replace(`.${elt.ext}`, ""));
        setValue("imgCount", elt.count);
        setValue("imgNameVerif", elt.name.replace(`.${elt.ext}`, ""));
        setOpen(true);
      }}
    >
      {elt.count === 0 && (
        <AlertTriangle color={Olympe.Color.tomato} size={16} />
      )}
      <img
        src={elt.url}
        alt={elt.name}
        height={100}
        width={100}
        className="contain"
      />
      <h3>
        {Olympe.Utils.truncStr({
          str: elt.name.replace(`.${elt.ext}`, "") || "nc",
          len: 20
        })}
      </h3>
      <p>{elt.ext.toUpperCase()}</p>
      <span>{Olympe.Utils.getFileSize(elt.size)}</span>
    </HorizontalCard>
  );

  const handleDeleteImg = () => {
    setImgLoad(true);

    const name = `${getValues("imgNameVerif")}.${getValues("imgExt")}`;

    axiosDelete({
      apiKey: context.api.key,
      uri: "image",
      opt: { name }
    })
      .then((data) => {
        if (data !== "true" && data !== "true_database") {
          throw new Error(data || "réponse serveur erronée.");
        }
        if (data === "true_database") {
          toast.success(
            "L'image a bien été supprimée du serveur et de la base de données.",
            {
              icon: <Trash />
            }
          );
        } else {
          toast.info(
            "L'image a été supprimée du serveur mais pas de la base de données."
          );
        }
        setOpen(false);
      })
      .catch((e) =>
        toast.error(
          "Une erreur est survenue, l'image n'a pas été supprimée : " +
            e.message
        )
      )
      .finally(() => {
        setImgLoad(false);
      });
  };

  const handleEditImg = () => {
    const newName = `${getValues("imgName")}.${getValues("imgExt")}`;
    const oldName = `${getValues("imgNameVerif")}.${getValues("imgExt")}`;

    setImgLoad(true);

    axiosPut({
      apiKey: context.api.key,
      uri: "ask/?item=imageName",
      opt: { newName, oldName }
    })
      .then((data) => {
        if (data !== "true" && data !== "true_database") {
          throw new Error(data || "réponse serveur erronée.");
        }
        if (data === "true_database") {
          toast.success(
            `L'image "${getValues(
              "imgNameVerif"
            )}" a bien été renommée en "${getValues(
              "imgName"
            )}" et les bases de données modifiées.`,
            {
              icon: "👍"
            }
          );
        } else {
          toast.info(
            `L'image "${getValues(
              "imgNameVerif"
            )}" a bien été renommée en "${getValues(
              "imgName"
            )}" mais les bases de données n'ont pas été mises à jour.`
          );
        }
        setOpen(false);
      })
      .catch((e) => {
        toast.error(
          `L'image "${getValues("imgNameVerif")}" n'a pas été renommée : ${
            e.message
          }`
        );
      })
      .finally(() => setImgLoad(false));
  };

  const CopyUrl = () => {
    const val = getValues("imgUrl");
    if (val.length === 0 || !navigator.clipboard) {
      toast.error("URL de l'image non copiée.");
      return;
    }

    navigator.clipboard
      .writeText(val)
      .then(function () {
        toast.success("URL de l'image copiée avec succès.");
        setOpen(false);
      })
      .catch(function () {
        toast.error("URL de l'image non copiée.");
      });
  };

  if (!users[0]) return <Olympe.Loader />;

  return (
    <Olympe.Wrapper horizontal multiple>
      <Olympe.Modal
        open={open}
        loading={imgLoad}
        onClose={closeModal}
        title="Modifier une image"
        content={
          <ImagesModal>
            <img
              src={getValues("imgUrl")}
              alt={getValues("imgName")}
              height={200}
              width={200}
            />

            <Olympe.Input.Text
              type="text"
              {...register("imgName")}
              defaultValue={""}
              autoComplete="off"
              placeholder="Nom de l'image"
              onKeyPress={(e) => {
                e.key === "Enter" && Olympe.Utils.enterInput(e);
              }}
            />

            {getValues("imgCount") === 0 && (
              <p>
                <AlertTriangle color={Olympe.Color.tomato} size={16} /> Cette
                image n{"'"} est pas utilisée.
              </p>
            )}

            <p>
              {Olympe.Utils.getFileSize(getValues("imgSize"))} -{" "}
              {(getValues("imgExt") || "NC").toUpperCase()}
              {getValues("imgCount") > 0 && ` - ${getValues("imgCount")} fois`}
            </p>

            {getValues("imgDate") && (
              <Olympe.Tooltips
                title={`Téléchargée le ${moment(getValues("imgDate")).format(
                  "LL"
                )}`}
                placement="bottom"
                notSpan
              >
                <p>{moment(getValues("imgDate")).format("LL")}</p>
              </Olympe.Tooltips>
            )}
          </ImagesModal>
        }
        buttons={[
          {
            isCancel: true,
            label: "Annuler",
            icon: <XCircle />,
            exec: closeModal
          },
          {
            isDanger: !getValues("imgName"),
            label: !getValues("imgName")
              ? "Supprimer"
              : getValues("imgName") === getValues("imgNameVerif")
                ? "Copier"
                : "Modifier",
            icon: !getValues("imgName")
              ? (
                <Trash />
              )
              : getValues("imgName") === getValues("imgNameVerif")
                ? (
                  <Clipboard />
                )
                : (
                  <Edit2 />
                ),
            exec:
              !getValues("imgName") || getValues("imgName").length === 0
                ? handleDeleteImg
                : getValues("imgName") === getValues("imgNameVerif")
                  ? CopyUrl
                  : handleEditImg
          }
        ]}
      />

      <Olympe.SubItem>
        <Olympe.ItemFullWidth>
          <Olympe.ItemTitle>
            <span>
              <Image />
              Images
            </span>
          </Olympe.ItemTitle>

          <HorizontalWrap>
            {imgs.map((elt) => renderImgCard(elt))}
          </HorizontalWrap>
        </Olympe.ItemFullWidth>
      </Olympe.SubItem>

      <Olympe.SubItem>
        <ItemFile>
          <input
            type="file"
            onChange={selectFile}
            accept=".png,.jpeg,.jpg,.webp,.gif"
            id="pickImage"
          />

          <Olympe.ItemTitle>
            <span>
              <FolderPlus />
              Ajouter une image
            </span>
          </Olympe.ItemTitle>

          <div className="loadFile">
            <p>
              Ajouter une image depuis votre appareil, puis donnez lui un nom
              pour l{"'"}identifier plus facilement par la suite.
            </p>

            {!selectedFiles && (
              <Olympe.Button.FilePick
                className="btnGetFiles"
                onClick={() => $("#pickImage").trigger("click")}
              >
                Choisir une image
              </Olympe.Button.FilePick>
            )}

            {selectedFiles && (
              <Olympe.Input.FormFile>
                <span>
                  <Image />

                  <Olympe.Input.File
                    type="input"
                    placeholder="Nom de l'image"
                    {...register("fileName")}
                  />

                  <Olympe.Button.FileCancel
                    onClick={() => setSelectedFiles(null)}
                  >
                    <X />
                  </Olympe.Button.FileCancel>
                </span>

                <Olympe.Button.FileUpload
                  className="btnUpload"
                  onClick={UploadFile}
                >
                  Télécharger
                </Olympe.Button.FileUpload>
              </Olympe.Input.FormFile>
            )}
          </div>

          {fileLoad && (
            <LinearProgress
              value={progress}
              valueBuffer={buffer}
              variant="buffer"
            />
          )}
        </ItemFile>
      </Olympe.SubItem>

      <Olympe.SubItem className="listItem">
        <Olympe.List.Menu
          uri="files"
          label="Fichiers du serveur"
          placeholder="Utilisateur, identifiant"
          handleSearch={handleSearch}
          search={search}
          content={filteredDatas()}
        />

        <Olympe.List.Table
          id={filteredFilesUser().id}
          sortId={4}
          sortAsc={false}
          name={filteredFilesUser().name}
          loading={loading}
          columns={columns}
          search={searchFile}
          buttonIcon={<FilePlus />}
          tableTitle="Tous les fichiers"
          buttonLabel="Ajouter un fichier"
          placeholder="Nom du fichier"
          datas={filteredFilesDatas()}
          handleSearch={handleSearchFile}
          onClickBtn={() => navigate(`/accounts/${filteredFilesUser().id}`)}
          onClickRow={(data) => navigate(`/files/pdf/${data.fileId}`)}
        />
      </Olympe.SubItem>
    </Olympe.Wrapper>
  );
}
