import axios from "axios";
import { toast } from "react-toastify";

const toastError = (data) => {
  const txt = data || "erreur serveur";

  if (txt.startsWith("Wrong auth level")) {
    return "Vous n'avez pas les droits pour effectuer cette requête ; demandez à un administrateur de le faire.";
  } else if (txt.startsWith("API request is not valid.")) {
    return "Votre requete n'est pas valide";
  }
  return txt;
};

export async function axiosGet ({ apiKey = "", uri = "" }) {
  if (!apiKey || apiKey.length === 0) {
    toast.error("Aucune clé d'API trouvée.");
    return false;
  }
  return axios
    .get(`https://api.mayansenergies.com/v1/${uri}`, {
      auth: {
        username: apiKey
      }
    })
    .then(({ data }) => {
      if (typeof data === "string" && data.startsWith("Wrong")) {
        throw new Error(data || "réponse serveur erronée.");
      }
      return data;
    })
    .catch((error) => {
      toast.error(
        "La requête réseau (GET) a échoué : " + toastError(error.message)
      );
      // console.error(error.message);
      return false;
    });
}

export async function axiosPut ({ apiKey = "", uri = "", opt = {} }) {
  return axios
    .put(`https://api.mayansenergies.com/v1/${uri}`, opt, {
      auth: {
        username: apiKey
      }
    })
    .then(({ data }) => data)
    .catch((error) => {
      toast.error(
        "La requête réseau (PUT) a échoué : " + toastError(error.message)
      );
      // console.error(error.message);
      return false;
    });
}

export async function axiosPost ({ apiKey = "", uri = "", opt = {} }) {
  return axios
    .post(`https://api.mayansenergies.com/v1/${uri}`, opt, {
      auth: {
        username: apiKey
      }
    })
    .then(({ data }) => data)
    .catch((error) => {
      toast.error(
        "La requête réseau (POST) a échoué : " + toastError(error.message)
      );
      // console.error(error.message);
      return false;
    });
}

export async function axiosDelete ({ apiKey = "", uri = "", opt = {} }) {
  return axios
    .delete(`https://api.mayansenergies.com/v1/${uri}`, {
      data: opt,
      headers: { Authorization: apiKey }
    })
    .then(({ data }) => data)
    .catch((error) => {
      toast.error(
        "La requête réseau (DELETE) a échoué : " + toastError(error.message)
      );
      // console.error(error.message);
      return false;
    });
}
