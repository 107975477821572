import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import {
  Bell,
  Calendar,
  Database,
  Folder,
  MessageCircle,
  Package,
  PieChart,
  Settings,
  User,
  Users
} from "react-feather";

import { MainContext } from "../datas/context";
import { axiosGet } from "../datas/fetch";
import { Olympe } from "dv-olympe";

export default function App () {
  const navigate = useNavigate();
  const location = useLocation();

  const [datas, setDatas] = useState([]);
  const [key, setKey] = useState(null);
  const [txt, setTxt] = useState("");

  useEffect(() => {
    const lastSeen = moment();
    const datasInterval = setInterval(() => {
      const nowDate = moment();
      const diff = nowDate.diff(lastSeen, "seconds");

      if (diff < 5) setTxt("Vérification de votre clé d'API.");
      else if (diff < 14) {
        setTxt("L'opération prend plus de temps que prévu...");
      } else if (diff > 15) {
        setTxt(
          "La vérification prend trop de temps, veuillez vous reconnecter ou réessayer plus tard."
        );
      }
    }, 1000);

    const tmpKey = localStorage.getItem("key");

    if (!tmpKey || tmpKey.length === 0) {
      console.log("nokey");
      navigate("/login/");
      return;
    } else {
      setKey(tmpKey);
    }

    if (location.pathname === "/accounts/add") {
      document
        .getElementById("outletid")
        ?.scrollIntoView({ behavior: "instant" });
    }

    return () => {
      clearInterval(datasInterval);
    };
  }, [navigate]);

  useEffect(() => {
    const refreshInfos = async () => {
      if (!key) return;

      axiosGet({
        apiKey: key,
        uri: "infos/"
      })
        .then((data) => {
          if (data === false) {
            localStorage.removeItem("key");
            navigate("/login/");
          }
          setDatas(data);
        })
        .catch((e) => {
          toast.error(
            `Chargement des données impossible, veuillez réessayer plus tard : ${e.message}`,
            {
              autoClose: false
            }
          );
        });
    };

    refreshInfos();
    const datasInterval = setInterval(() => refreshInfos(), 3000);

    return () => {
      clearInterval(datasInterval);
    };
  }, [navigate, key]);

  if (!datas.infos) return <Olympe.Loader text={txt} />;

  const ListItem = [
    {
      name: "Application",
      path: "/application",
      icon: <PieChart />,
      link: <Link to="/application" />,
      anim: "rotate"
    },
    {
      name: "Utilisateurs",
      path: "/accounts",
      icon: <Users />,
      link: <Link to="/accounts" />
    },
    {
      name: "Messageries",
      path: "/chats",
      icon: (
        <>
          <MessageCircle />
          {datas.badge ? <span className="badgeOn" /> : null}
        </>
      ),
      link: <Link to="/chats" />
    },
    {
      name: "Fichiers",
      path: "/files",
      icon: (
        <>
          <Folder />
          {datas.badgeFile ? <span className="badgeOn" /> : null}
        </>
      ),
      link: <Link to="/files" />
    },
    {
      name: "Rendez-vous",
      path: "/meets",
      icon: (
        <>
          <Calendar />
          {datas.badgeMeetings ? <span className="badgeOn" /> : null}
        </>
      ),
      link: <Link to="/meets" />
    },
    {
      name: "Installations",
      path: "/setups",
      icon: (
        <>
          <Package />
          {datas.badgeSetup ? <span className="badgeOn" /> : null}
        </>
      ),
      link: <Link to="/setups" />
    },
    {
      name: "Produits",
      path: "/products",
      icon: (
        <>
          <Database />
          {datas.badgeProduct ? <span className="badgeOn" /> : null}
        </>
      ),
      link: <Link to="/products" />
    },
    {
      name: "Notifications",
      path: "/notifications",
      icon: <Bell />,
      link: <Link to="/notifications" />,
      anim: "bounce"
    },
    {
      name: "Administration",
      path: "/administration",
      icon: <Settings />,
      link: <Link to="/administration" />,
      anim: "rotate"
    },
    {
      name: "Mon compte",
      path: "/settings",
      icon: <User />,
      link: <Link to="/settings" />
    }
  ];

  return (
    <MainContext.Provider
      value={{ corp: datas, clr: Olympe.Color, api: { key } }}
    >
      <Olympe.Interface MainContext={MainContext} ListItem={ListItem} />
    </MainContext.Provider>
  );
}
