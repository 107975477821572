import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
} from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";
import { axiosGet, axiosPost, axiosPut } from "../datas/fetch";
import moment from "moment";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  Archive,
  Bell,
  BellOff,
  Clock,
  Edit2,
  Info,
  Send,
  Sliders,
  User,
  Users,
  XCircle,
  XOctagon
} from "react-feather";

import { ItemHistory } from "../components/StyledItem";
import { MainContext } from "../datas/context";
import { useForm } from "react-hook-form";
import {
  ExpandedItem,
  HorizontalCard,
  HorizontalWrap,
  NotifForm,
  NotifModal
} from "../components/StyledElements";
import { Olympe } from "dv-olympe";

const columns = [
  {
    name: "Type",
    selector: (row) => (row.type ? "Push" : "Mail"),
    width: "80px",
    hide: "sm"
  },
  {
    name: "Sujet",
    selector: (row) =>
      Olympe.Utils.truncStr({
        str: row.content?.title || "Notification envoyée",
        len: 70
      }),
    hide: "md",
    grow: 3
  },
  {
    name: "Date",
    selector: (row) => row.date,
    format: (row) => Olympe.Utils.SentLogTime(row.date),
    sortable: true,
    grow: 1
  }
];

export default function Notifs () {
  const context = React.useContext(MainContext);
  const [stats, setStats] = useState([]);
  const [history, setHistory] = useState([]);
  const [historyLoad, setHistoryLoad] = useState(true);
  const [search, setSearch] = useState("");
  const [searchNotif, setSearchNotif] = useState("");
  const [sent, setSent] = useState(false);
  const [users, setUsers] = useState([]);
  const [content, setContent] = useState(<Olympe.MiniLoader />);
  const [title, setTitle] = useState("");

  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);

  const [openView, setOpenView] = useState(false);
  const closeModaView = () => setOpenView(false);

  useEffect(() => {
    refreshInfos();
    const datasInterval = setInterval(() => refreshInfos(), 3000);

    watch([
      "nTitle",
      "nBody",
      "nBadge",
      "tName",
      "tTitle",
      "tContent",
      "tId",
      "tUID",
      "tExec"
    ]);

    return () => {
      clearInterval(datasInterval);
    };
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    setFocus,
    getValues,
    watch,
    reset,
    formState: { dirtyFields }
  } = useForm();

  const refreshInfos = async () => {
    axiosGet({
      apiKey: context.api.key,
      uri: "stats/?item=notifications&stats"
    }).then((data) => {
      setStats(data);

      axiosGet({
        apiKey: context.api.key,
        uri: "stats/?item=notifications"
      }).then((data) => {
        setHistory(data);
        if (historyLoad) setHistoryLoad(false);
      });
    });
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleSearchNotif = (event) => {
    setSearchNotif(event.target.value);
  };

  const getSubHeaderComponent = () => (
    <Olympe.Input.Text
      className="search"
      type="text"
      onChange={handleSearch}
      value={search}
      placeholder="Rechercher..."
      autoComplete="off"
    />
  );

  const filteredDatas = () =>
    history.filter(
      (obj) =>
        obj.content?.title.includes(search) ||
        obj.content?.body.includes(search) ||
        obj.target.some((elt) =>
          elt.toLowerCase().includes(search.toLowerCase())
        )
    );

  const onSubmit = async (data) => {
    const title = data.nTitle;
    const body = data.nBody;
    const badge = data.nBadge.toString();
    const usrs = [];

    for (let i = 0; i < users.length; i++) {
      usrs.push(users[i].value);
    }

    if (usrs.length === 0) {
      setFocus("nUsers");
      toast.info("Veuillez sélectionner au moins un utilisateur.");
      return;
    }

    if (title.length === 0) {
      setFocus("nTitle");
      toast.info("Veuillez ajouter un titre à cette notification.");
      return;
    }

    if (body.length === 0) {
      setFocus("nBody");
      toast.info("Veuillez ajouter une description à cette notification.");
      return;
    }

    setSent(true);

    axiosPost({
      apiKey: context.api.key,
      uri: "notifications",
      opt: {
        title,
        body,
        usrs,
        badge
      }
    })
      .then((data) => {
        if (data.length === 0) {
          throw new Error("réponse serveur erronée.");
        }
        toast.info(data, {
          icon: <Bell />
        });
        setValue("nTitle", stats.name || "Mayans Énergies");
        setValue("nBody", "");
        setValue("nBadge", 1);
        setUsers([]);
      })
      .catch((e) =>
        toast.error(
          "Une erreur est survenue, la notification n'a pas été envoyée : " +
            e.message
        )
      )
      .finally(() => setSent(false));
  };

  const renderNotifsCard = (elt) => (
    <HorizontalCard
      key={elt.pushUID}
      onClick={() => {
        reset({
          tId: elt.id,
          tUID: elt.pushUID,
          tExec: elt.pushExec === "1",
          tContent: elt.pushContent,
          tTitle: elt.pushTitle,
          tName: elt.pushName,
          tDesc: elt.pushDesc
        });
        setOpen(true);
      }}
      style={{ minWidth: 128, maxWidth: 128 }}
    >
      {elt.pushExec === "0" && (
        <Olympe.Tooltips title={"Notification désactivée."} notSpan>
          <BellOff />
        </Olympe.Tooltips>
      )}
      <h3>{elt.pushTitle}</h3>
      <p>{elt.pushName}</p>
    </HorizontalCard>
  );

  const handleEdit = () => {
    setEdit(true);
    const id = getValues("tId");
    const title = getValues("tTitle");
    const content = getValues("tContent");
    const exec = (getValues("tExec") === true ? 1 : 0)?.toString();

    axiosPut({
      apiKey: context.api.key,
      uri: "notification",
      opt: {
        id,
        title,
        content,
        exec
      }
    })
      .then((data) => {
        if (data !== "true") {
          throw new Error(data || "réponse serveur erronée.");
        }
        toast.success("La notification a bien été modifiée.", {
          icon: <Bell />
        });
        setOpen(false);
      })
      .catch((e) =>
        toast.error(
          "Une erreur est survenue, la notification n'a pas été modifiée : " +
            e.message
        )
      )
      .finally(() => {
        setEdit(false);
        refreshInfos();
      });
  };

  const onRowClicked = (data) => {
    setTitle(data.type ? "Notification push" : "Mail envoyé");
    setContent(
      <ExpandedItem clr={context.clr || Olympe.Color}>
        <p>
          <User /> Destinataire : {data.target?.join(", ") || <i>NC</i>}
        </p>

        <p>
          <Clock />
          {moment(data?.date).format("DD MMM YYYY à HH:mm").replace(":", "h")}
        </p>

        {data?.type
          ? (
            <div className="notifPreview">
              <div className="imgWrap">
                <img
                  src={context.corp.infos.corp.logo}
                  alt={context.corp.infos.corp.name}
                  height={50}
                  width={50}
                />
              </div>

              <div className="titles">
                <h5>{data?.content.title || <i>NC</i>}</h5>
                <h6>{data?.content.body || <i>NC</i>}</h6>
              </div>

              <span>
                {Number.isInteger(data?.content.badge)
                  ? data?.content.badge
                  : "?"}
              </span>
            </div>
          )
          : (
            false
          )}

        {!data?.type && data?.content.body && (
          <iframe srcDoc={data?.content.body} height={400}>
            <p className="error">
              <XOctagon /> Navigateur incompatible avec l'intégration dynamique.
            </p>
          </iframe>
        )}

        {!data?.type && !data?.content.body && (
          <p className="error">
            <XOctagon /> Visuel indisponible.
          </p>
        )}
      </ExpandedItem>
    );
    setOpenView(true);
  };

  const getNotifsList = () =>
    stats.notifs?.filter(
      (obj) =>
        obj.pushContent?.toLowerCase().includes(searchNotif?.toLowerCase()) ||
        obj.pushTitle?.toLowerCase().includes(searchNotif?.toLowerCase()) ||
        obj.pushTitle?.toLowerCase().includes(searchNotif?.toLowerCase())
    );

  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement
  );

  if (!stats.name) return <Olympe.Loader />;

  const doughnutData = {
    labels: [" Push ", " Emails "],
    datasets: [
      {
        label: "",
        data: [stats.nPush, stats.nMail],
        backgroundColor: [context.clr.blue, context.clr.light],
        borderWidth: 0
      }
    ]
  };

  const barData = {
    labels: ["24h", "1 semaine", "1 mois"],
    datasets: [
      {
        label: " Période en cours ",
        data: [stats.last_24, stats.last_week, stats.last_month],
        backgroundColor: context.clr.blue,
        minBarLength: 5
      },
      {
        label: " Période précédente ",
        data: [stats.prev_24, stats.prev_week, stats.prev_month],
        backgroundColor: context.clr.light,
        minBarLength: 5
      }
    ]
  };

  return (
    <Olympe.Wrapper>
      <Olympe.Modal
        open={open}
        loading={edit}
        onClose={closeModal}
        title={getValues("tName")}
        content={
          <NotifModal>
            <Olympe.Input.Text
              type="text"
              {...register("tTitle")}
              autoComplete="off"
              placeholder="Titre de la notification"
              onKeyPress={(e) => {
                e.key === "Enter" && Olympe.Utils.enterInput(e);
              }}
            />

            <textarea
              {...register("tContent")}
              placeholder="Contenu de la notification."
            />

            <span>
              {getValues("tExec") === true ? "Activée" : "Désactivée"}

              <Olympe.Input.Text
                type="checkbox"
                {...register("tExec")}
                onKeyPress={(e) => {
                  e.key === "Enter" && Olympe.Utils.enterInput(e);
                }}
              />
            </span>

            <ul className="desc">
              {getValues("tDesc")
                ?.split(" ")
                .map((elt, idx) => (
                  <li key={idx}>{elt}</li>
                ))}
              {getValues("tUID") !== "createNews" && (
                <>
                  <li>[CHAT_BADGE]</li>
                  <li>[NOM_DU_CLIENT]</li>
                  <li>[MAIL_DU_CLIENT]</li>
                </>
              )}
            </ul>
          </NotifModal>
        }
        buttons={[
          {
            isCancel: true,
            label: "Annuler",
            icon: <XCircle />,
            exec: closeModal
          },
          {
            label: "Modifier",
            icon: <Edit2 />,
            exec:
              (dirtyFields.tTitle ||
                dirtyFields.tContent ||
                dirtyFields.tExec) &&
              getValues("tTitle") &&
              getValues("tContent")
                ? handleEdit
                : false
          }
        ]}
      />

      <Olympe.Modal
        large
        open={openView}
        loading={edit}
        onClose={closeModaView}
        title={title}
        content={content}
        buttons={[
          {
            isCancel: true,
            label: "Fermer",
            icon: <XCircle />,
            exec: closeModaView
          }
        ]}
      />

      <Olympe.SubItem>
        <Olympe.Chart
          title="Envoyées"
          subtitle="Notifications envoyées selon leur type."
          chartTitle={Olympe.Utils.numberShorter(
            Math.floor(Number(stats.nPush) + Number(stats.nMail))
          )}
          chartLegend={
            <>
              <div className="legend">
                <span
                  className="square"
                  style={{ backgroundColor: context.clr.blue }}
                />
                Notifications push
              </div>

              <div className="legend">
                <span
                  className="square"
                  style={{ backgroundColor: context.clr.light }}
                />
                Notifications mail
              </div>
            </>
          }
          chart={
            <Doughnut
              data={doughnutData}
              options={{
                responsive: true,
                plugins: { legend: { display: false } },
                cutout: 62
              }}
            />
          }
        />

        <Olympe.Chart
          large
          title="Derniers envois"
          subtitle="Taux d'envois de mails / notifications sur différentes périodes."
          chartLegend={
            <>
              <div className="legend">
                <span
                  className="square"
                  style={{ backgroundColor: context.clr.blue }}
                />
                Denières 24h, semaine et mois en cours
              </div>

              <div className="legend">
                <span
                  className="square"
                  style={{ backgroundColor: context.clr.light }}
                />
                Période précédente
              </div>
            </>
          }
          chart={
            <Bar
              data={barData}
              options={{
                indexAxis: "y",
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false
                  }
                },
                scales: {
                  x: {
                    ticks: {
                      color: context.clr.grey
                    },
                    grid: {
                      color: "rgba(255, 255, 255, 0.2)"
                    }
                  },
                  y: {
                    ticks: {
                      color: context.clr.grey
                    },
                    grid: {
                      color: "rgba(255, 255, 255, 0.2)"
                    }
                  }
                }
              }}
            />
          }
        />
      </Olympe.SubItem>

      <Olympe.SubItem>
        <Olympe.ItemFullWidth>
          <Olympe.ItemTitle>
            <span>
              <Bell /> Envoyer une notification
            </span>
          </Olympe.ItemTitle>

          <form onSubmit={handleSubmit(onSubmit)}>
            <NotifForm clr={context.clr || Olympe.Color}>
              <div className="imgWrap">
                <img
                  src={context.corp.infos.corp.logo}
                  alt={context.corp.infos.corp.name}
                  height={50}
                  width={50}
                />
              </div>

              <div className="titles">
                <Olympe.Input.Text
                  type="text"
                  className="notifTitle"
                  {...register("nTitle")}
                  defaultValue={stats.name || "Mayans Energies"}
                  autoComplete="off"
                  placeholder="Titre de la notification"
                  onKeyPress={(e) => {
                    e.key === "Enter" && Olympe.Utils.enterInput(e);
                  }}
                />

                <Olympe.Input.Text
                  type="text"
                  className="notifBody"
                  {...register("nBody")}
                  defaultValue={""}
                  autoComplete="off"
                  placeholder="Texte de la notification"
                  onKeyPress={(e) => {
                    e.key === "Enter" && Olympe.Utils.enterInput(e);
                  }}
                />
              </div>

              <Olympe.Input.Text
                type="number"
                step={1}
                min={-1}
                max={100}
                className="notifBadge"
                {...register("nBadge")}
                defaultValue={1}
                autoComplete="off"
                placeholder="Badge"
                onKeyPress={(e) => {
                  e.key === "Enter" && Olympe.Utils.enterInput(e);
                }}
              />
            </NotifForm>

            <div className="usrDetails">
              <Users />
              <Select
                isMulti
                className="select"
                options={stats.users || []}
                placeholder="Utilisateur(s)"
                value={users}
                {...register("nUsers")}
                onChange={(u) => setUsers(u)}
                noOptionsMessage={() => "Aucun compte trouvé 😬"}
              />
            </div>

            <p className="usrDetails">
              <Info />
              <span className="codeDesc">
                La liste des utilisateurs correspond aux utilisateurs ayant fait
                au moins une connexion et activé les notifications.
                <br />
                <br />
                Les badges correspondent au nombre affiché sur l'icone de
                l'application. Le laisser à 0 n'augmente pas son nombre initial
                (nombre de messages non lus) ; le mettre à -1 mettra le compteur
                à 0, sinon ce sera sa somme avec les messages non lus.
              </span>
            </p>

            <div className="footerInfos">
              {sent
                ? (
                  <Olympe.MiniLoader />
                )
                : (
                  <Olympe.Button.Basic
                    label="Envoyer la notification"
                    icon={<Send />}
                    type="submit"
                    toExecute={handleSubmit}
                  />
                )}
            </div>
          </form>
        </Olympe.ItemFullWidth>
      </Olympe.SubItem>

      <Olympe.SubItem>
        <Olympe.ItemFullWidth>
          <Olympe.ItemTitle>
            <span>
              <Sliders />
              Éditer les notifications
            </span>

            <Olympe.Input.Text
              className="search"
              type="text"
              onChange={handleSearchNotif}
              value={searchNotif}
              placeholder="Rechercher..."
              autoComplete="off"
            />
          </Olympe.ItemTitle>

          <HorizontalWrap>
            {getNotifsList()?.map((elt, idx) => renderNotifsCard(elt, idx))}
            {getNotifsList()?.length === 0 && (
              <p style={{ textAlign: "center", width: "100%" }}>
                Aucune correspondance
              </p>
            )}
          </HorizontalWrap>
        </Olympe.ItemFullWidth>
      </Olympe.SubItem>

      <Olympe.SubItem>
        <ItemHistory>
          <Olympe.ItemTitle>
            <span>
              <Archive /> Historique
            </span>
          </Olympe.ItemTitle>

          <div className="logsTable">
            <Olympe.Table.Wrap
              clr={context.clr}
              responsive
              columns={columns}
              onRowClicked={(data) => onRowClicked(data)}
              data={filteredDatas()}
              noDataComponent={Olympe.Table.Empty()}
              pagination
              paginationComponentOptions={Olympe.Table.Pagination}
              subHeader
              subHeaderComponent={getSubHeaderComponent()}
              progressPending={historyLoad}
              progressComponent={<Olympe.MiniLoader />}
              theme="customTheme"
              customStyles={Olympe.Table.Style}
            />
          </div>
        </ItemHistory>
      </Olympe.SubItem>
    </Olympe.Wrapper>
  );
}
