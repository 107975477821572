import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import $ from "jquery";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import moment from "moment";
import { useForm } from "react-hook-form";
import {
  ArrowLeftCircle,
  Book,
  BookOpen,
  Calendar,
  Check,
  DollarSign,
  File,
  Image,
  Info,
  MapPin,
  Package,
  PlusCircle,
  Send,
  Trash,
  Users,
  Video,
  Watch
} from "react-feather";

import { axiosGet, axiosPost } from "../datas/fetch";
import { ItemSeeFile, ItemSetup } from "../components/StyledItem";
import { MainContext } from "../datas/context";
import { Olympe } from "dv-olympe";

export default function SetupsAdd () {
  const context = React.useContext(MainContext);
  const params = useParams();
  const navigate = useNavigate();

  const [infos, setInfos] = useState({});
  const [sent, setSent] = useState(false);
  const [techs, setTechs] = useState([]);
  const [addrs, setAddrs] = useState({});
  const [products, setProducts] = useState({});
  const [docu, setDocu] = useState([]);
  const [docuType, setDocuType] = useState("file");

  const { handleSubmit, register, setValue, getValues, setFocus, watch } =
    useForm();

  useEffect(() => {
    const refreshInfos = async () => {
      axiosGet({
        apiKey: context.api.key,
        uri: `listInfos/?clid=${params.usrId}`
      }).then((data) => {
        if (!data.clid) {
          toast.error("Utilisateur non trouvé.", { icon: "🔎" });
          navigate(`/setups/${params.usrId}`);
          return;
        }

        setInfos(data);

        if (data.list && data.list.addr[0]) {
          setAddrs({
            label: data.list.addr[0].label,
            value: data.list.addr[0].value
          });
        }
      });
    };

    if (!params.usrId) {
      toast.error("Utilisateur non précisé.", { icon: <Package /> });
      navigate("/setups/");
    }

    watch([
      "fileDevis",
      "fileContrat",
      "fileFacture",
      "instDate",
      "instPrice",
      "instDuraH",
      "instDuraM",
      "docuName",
      "docuLink"
    ]);

    refreshInfos();
  }, [navigate]);

  const deleteDocu = (link, idx) => {
    const tmp = [];

    for (let i = 0; i < docu.length; i++) {
      if (docu[i].link !== link && i !== idx) {
        tmp.push(docu[i]);
      }
    }

    setDocu(tmp);
    toast.success("Documentation supprimée.", { icon: <Trash /> });
  };

  const addDocu = () => {
    const name = getValues("docuName");
    const link = getValues("docuLink");
    const type = docuType;

    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );

    if (name.length === 0) {
      toast.info("Veuillez entrer une description valide.");
      setFocus("docuName");
      return;
    }

    if (
      (!link.startsWith("https://www.youtube.com/embed/") &&
        !pattern.test(link)) ||
      type === "file"
    ) {
      toast.info(
        "Impossible d'ajouter ce lien, vérifier qu'il pointe vers une image / vidéo ou que ce soit un lien youtube \"embed\""
      );
      setFocus("docuLink");
      return;
    }

    if (name.length === 0) {
      toast.error("Veuillez ajouter un nom à la nouvelle documentation.");
      return;
    } else if (link.length === 0) {
      toast.error("Veuillez ajouter un lien à la nouvelle documentation.");
      return;
    }

    setDocu([...docu, { name, type, link }]);
    setValue("docuName", "");
    setValue("docuLink", "");
    setDocuType("file");
    toast.success("Documentation ajoutée.", { icon: <BookOpen /> });
  };

  const getDocuType = (link = "") => {
    if (/\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(link)) setDocuType("image");
    else if (
      /\.(mp4|webm|mov|AVI)$/.test(link) ||
      link.includes("youtube.com")
    ) {
      setDocuType("video");
    } else setDocuType("file");
  };

  const onSubmit = async (data) => {
    setSent(true);
    const duration = (
      parseInt(data.instDuraM) +
      parseInt(data.instDuraH) * 60
    ).toString();

    const tech = techs
      .map((elt, idx) => `${idx > 0 ? " " : ""}${elt.label.trim()}`)
      .toString();

    const price = data.instPrice?.replace(",", ".").toString();
    const addr = addrs.label;
    const product = products.value;
    const date = data.instDate;
    const documentation = JSON.stringify(docu);
    const notif = data.letKnow.toString();

    if (!product || product.length === 0) {
      toast.info("Veuillez choisir un produit pour continuer.");
      setFocus("instPro");
      setSent(false);
      return;
    }

    axiosPost({
      apiKey: context.api.key,
      uri: "setup",
      opt: {
        clid: params.usrId,
        duration,
        tech,
        documentation,
        addr,
        date,
        price,
        notif,
        product
      }
    })
      .then((data) => {
        if (data !== "true") {
          throw new Error(data || "réponse serveur erronée.");
        }
        toast.success("L'installation a bien été ajoutée.", {
          icon: <Package />
        });
        navigate(`/setups/${infos.clid}`);
      })
      .catch((e) => {
        toast.error(
          "Une erreur est survenue, l'installation n'a pas été ajoutée : " +
            e.message
        );
      })
      .finally(() => setSent(false));
  };

  if (!infos.clid) return <Olympe.Loader />;

  return (
    <Olympe.Wrapper>
      <Olympe.SubItem>
        <ItemSetup className="large">
          <Olympe.ItemTitle>
            <span>
              <Olympe.Tooltips
                title="Retour à la page des installations."
                classes="icon"
                placement="left"
              >
                <Olympe.Button.Basic
                  toExecute={() => navigate(-1)}
                  icon={<ArrowLeftCircle />}
                />
              </Olympe.Tooltips>

              <span style={{ marginLeft: 20 }}>Installation</span>
            </span>
          </Olympe.ItemTitle>

          <div className="usrDetails">
            <Users />
            <CreatableSelect
              isMulti
              className="select"
              options={infos.list.emps}
              placeholder="Techniciens"
              defaultValue={[]}
              onChange={(t) => setTechs(t)}
              formatCreateLabel={(newVal) => `Ajouter : ${newVal}`}
              noOptionsMessage={() => "Aucun technicien trouvé 😬"}
            />
          </div>

          <div className="usrDetails">
            <MapPin />
            <CreatableSelect
              className="select"
              options={infos.list.addr}
              placeholder="Adresse de résidence"
              defaultValue={addrs}
              onChange={(t) => setAddrs(t)}
              noOptionsMessage={() => "Aucune adresse trouvée 😬"}
              formatCreateLabel={(newVal) => `Ajouter : ${newVal}`}
            />
          </div>

          <div className="usrDetails">
            <Package />
            <Select
              className="select"
              {...register("instPro")}
              options={infos.list.products}
              placeholder="Choisir un produit"
              defaultValue={[]}
              onChange={(t) => setProducts(t)}
              noOptionsMessage={() => "Aucune adresse trouvée 😬"}
              formatCreateLabel={(newVal) => `Ajouter : ${newVal}`}
            />
          </div>

          <p className="usrDetails">
            <Watch />

            <Olympe.Tooltips title="Durée des travaux en heures">
              <Olympe.Input.Text
                type="number"
                min={0}
                step={1}
                max={1000}
                {...register("instDuraH")}
                defaultValue={1}
                placeholder="h"
                autoComplete="off"
                onKeyPress={(e) => {
                  e.key === "Enter" && Olympe.Utils.enterInput(e);
                }}
              />
            </Olympe.Tooltips>

            <span className="timeSep">h</span>

            <Olympe.Tooltips title="Durée des travaux en minutes">
              <Olympe.Input.Text
                type="number"
                min={0}
                step={5}
                max={60}
                {...register("instDuraM")}
                defaultValue={0}
                placeholder="min"
                autoComplete="off"
                onKeyPress={(e) => {
                  e.key === "Enter" && Olympe.Utils.enterInput(e);
                }}
              />
            </Olympe.Tooltips>
            <span className="timeSep">min.</span>
          </p>

          <p className="usrDetails">
            <DollarSign />

            <Olympe.Tooltips title="Prix total de l'installation en euros">
              <Olympe.Input.Text
                type="number"
                min={0}
                step={0.01}
                max={1000000}
                {...register("instPrice")}
                defaultValue={0}
                placeholder="Prix total"
                autoComplete="off"
                onKeyPress={(e) => {
                  e.key === "Enter" && Olympe.Utils.enterInput(e);
                }}
              />
            </Olympe.Tooltips>

            <span className="timeSep">€</span>
          </p>

          <p className="usrDetails">
            <Calendar />
            <Olympe.Tooltips title="Jour de la fin des travaux">
              <Olympe.Input.Text
                type="date"
                {...register("instDate")}
                defaultValue={moment().format("YYYY-MM-DD")}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.key === "Enter" && Olympe.Utils.enterInput(e);
                }}
              />
            </Olympe.Tooltips>
            <span className="fullDate">
              {getValues("instDate") &&
                getValues("instDate").length > 0 &&
                !moment().isSame(getValues("instDate"), "day") &&
                moment(getValues("instDate")).format("ddd D MMMM Y")}

              {(!getValues("instDate") ||
                moment().isSame(getValues("instDate"), "day")) &&
                "Aujourd'hui."}
            </span>
          </p>
        </ItemSetup>
      </Olympe.SubItem>

      <Olympe.SubItem>
        <ItemSeeFile>
          <Olympe.ItemTitle>
            <span>
              <BookOpen /> Documentation
            </span>
          </Olympe.ItemTitle>

          <p>
            Voici la liste des images et vidéos reliées à cette installation.
            Elle peut être modifiée à tout moment, dans la section{" "}
            <i>Modifier l'installation</i>. Elle est uniquement visible sur les
            comptes particuliers.
          </p>

          {docu.length > 0
            ? (
              <div className="links">
                {docu.map((elt, idx) => (
                  <div className="link" key={`${idx}-${elt.link}`}>
                    <p className="title">
                      {elt.type === "image"
                        ? (
                          <Image />
                        )
                        : elt.type === "video"
                          ? (
                            <Video />
                          )
                          : (
                            <File />
                          )}{" "}
                      <span>{elt.name}</span>
                    </p>
                    <Olympe.Button.Basic
                      icon={<Trash />}
                      anim="danger"
                      toExecute={() => deleteDocu(elt.link, idx)}
                    />
                  </div>
                ))}
              </div>
            )
            : (
              <h3>
                <Book /> Aucune documentation
              </h3>
            )}

          <div className="addDocu">
            <div className="fields">
              <Olympe.Input.Text
                type="text"
                {...register("docuName")}
                placeholder="Description de la documentation"
                defaultValue={""}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.key === "Enter" && Olympe.Utils.enterInput(e);
                }}
              />
              <Olympe.Input.Text
                type="text"
                {...register("docuLink")}
                placeholder="Lien vers l'image ou la vidéo"
                defaultValue={""}
                autoComplete="off"
                onChange={(e) => getDocuType(e.target.value)}
                onKeyPress={(e) => {
                  e.key === "Enter" && Olympe.Utils.enterInput(e);
                }}
              />
            </div>

            <span>
              {docuType === "image"
                ? (
                  <Image />
                )
                : docuType === "video"
                  ? (
                    <Video />
                  )
                  : (
                    <File />
                  )}
            </span>

            <Olympe.Button.Basic
              label="Ajouter"
              icon={<PlusCircle />}
              toExecute={addDocu}
            />
          </div>
        </ItemSeeFile>
      </Olympe.SubItem>

      <Olympe.SubItem>
        <Olympe.ItemFullWidth>
          <Olympe.ItemTitle>Confirmer l'ajout</Olympe.ItemTitle>

          <p className="usrDetails">
            <Info />
            <span className="codeDesc">
              La création d'une installation est immédiate et modifiable ; les
              documents reliés (devis, contrat et facture) se configurent après
              sa création.
            </span>
          </p>

          <p className="usrDetails">
            <Send />
            <span className="codeDesc">
              Cet ajout prévient l'utilisateur par une notification push, pour
              ne pas en envoyer, veuillez désactiver l'option :
              <Olympe.Input.Text
                type="checkbox"
                defaultChecked
                {...register("letKnow")}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                  $(e.target)[0].blur();
                }}
              />
            </span>
          </p>

          <div className="footerInfos">
            {sent
              ? (
                <Olympe.MiniLoader />
              )
              : (
                <Olympe.Button.Basic
                  label="Ajouter l'installation"
                  icon={<Check />}
                  toExecute={handleSubmit(onSubmit)}
                />
              )}
          </div>
        </Olympe.ItemFullWidth>
      </Olympe.SubItem>
    </Olympe.Wrapper>
  );
}
