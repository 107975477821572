import React /*, { Suspense, lazy } */ from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ProSidebarProvider } from "react-pro-sidebar";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Olympe } from "dv-olympe";

import App from "./pages/App";

import Account from "./pages/Accounts";
import AccountAdd from "./pages/AccountAdd";
import AccountView from "./pages/AccountView";
import AccountEdit from "./pages/AccountEdit";

import ChatView from "./pages/Chat";

import FilesView from "./pages/Files";
import FilesDetails from "./pages/FilesDetails";

import SetupsView from "./pages/Setups";
import SetupsDetails from "./pages/SetupsDetails";
import SetupsEdit from "./pages/SetupEdit";
import SetupsAdd from "./pages/SetupAdd";
import ProductsView from "./pages/Products";
import ProductsDetails from "./pages/ProductsDetails";
import ProductsAdd from "./pages/ProductsAdd";

import Notifs from "./pages/Notifs";
import AdminPage from "./pages/Admin";
import MeetsView from "./pages/Meetings";
import SettingsPage from "./pages/Settings";
import ApplicationPage from "./pages/ApplicationPage";
import MeetingsDetails from "./pages/MeetingsDetails";
import MeetingsEdit from "./pages/MeetingsEdit";
import MeetingsAdd from "./pages/MeetingsAdd";

const root = ReactDOM.createRoot(document.getElementById("root"));

if (window.location.pathname === "/") {
  window.location.href = "/application/";
}

// const App = lazy(() => import("./pages/App"));
// const AdminPage = lazy(() => import("./pages/Admin"));

// const Account = lazy(() => import("./pages/Accounts"));
// const AccountView = lazy(() => import("./pages/AccountView"));
// const AccountAdd = lazy(() => import("./pages/AccountAdd"));
// const AccountEdit = lazy(() => import("./pages/AccountEdit"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Olympe.ErrorPage />,
    children: [
      // Fallback Default
      {
        path: "/",
        element: <AdminPage />,
        errorElement: <Olympe.ErrorPage />
      },

      // ACCOUNTS PART
      {
        path: "/accounts",
        children: [
          {
            path: "",
            element: <Account />,
            errorElement: <Olympe.ErrorPage />
          },
          {
            path: ":usrId",
            element: <AccountView />,
            errorElement: <Olympe.ErrorPage />
          },
          {
            path: ":usrId/edit/",
            element: <AccountEdit />,
            errorElement: <Olympe.ErrorPage />
          },
          {
            path: "add/",
            element: <AccountAdd />,
            errorElement: <Olympe.ErrorPage />
          }
        ]
      },

      // CHAT PART
      {
        path: "/chats",
        children: [
          {
            path: "",
            element: <ChatView />,
            errorElement: <Olympe.ErrorPage />
          },
          {
            path: ":usrId",
            element: <ChatView />,
            errorElement: <Olympe.ErrorPage />
          }
        ]
      },

      // FILES PART
      {
        path: "/files",
        children: [
          {
            path: "",
            element: <FilesView />,
            errorElement: <Olympe.ErrorPage />
          },
          {
            path: ":usrId",
            element: <FilesView />,
            errorElement: <Olympe.ErrorPage />
          },
          {
            path: "pdf/:fileId",
            element: <FilesDetails />,
            errorElement: <Olympe.ErrorPage />
          }
        ]
      },

      // SETUPS PART
      {
        path: "/setups",
        children: [
          {
            path: "",
            element: <SetupsView />,
            errorElement: <Olympe.ErrorPage />
          },
          {
            path: ":usrId",
            element: <SetupsView />,
            errorElement: <Olympe.ErrorPage />
          },
          {
            path: "details/:fileId",
            element: <SetupsDetails />,
            errorElement: <Olympe.ErrorPage />
          },
          {
            path: "edit/:fileId",
            element: <SetupsEdit />,
            errorElement: <Olympe.ErrorPage />
          },
          {
            path: "add/:usrId",
            element: <SetupsAdd />,
            errorElement: <Olympe.ErrorPage />
          }
        ]
      },

      // PRODUCT PART
      {
        path: "/products",
        children: [
          {
            path: "",
            element: <ProductsView />,
            errorElement: <Olympe.ErrorPage />
          },
          {
            path: ":usrId",
            element: <ProductsView />,
            errorElement: <Olympe.ErrorPage />
          },
          {
            path: "details/:productId",
            element: <ProductsDetails />,
            errorElement: <Olympe.ErrorPage />
          },
          {
            path: "add/",
            element: <ProductsAdd />,
            errorElement: <Olympe.ErrorPage />
          }
        ]
      },

      // MEETING PART
      {
        path: "/meets",
        children: [
          {
            path: "",
            element: <MeetsView />,
            errorElement: <Olympe.ErrorPage />
          },
          {
            path: ":usrId",
            element: <MeetsView />,
            errorElement: <Olympe.ErrorPage />
          },
          {
            path: "details/:meetId",
            element: <MeetingsDetails />,
            errorElement: <Olympe.ErrorPage />
          },
          {
            path: "edit/:meetId",
            element: <MeetingsEdit />,
            errorElement: <Olympe.ErrorPage />
          },
          {
            path: "add/:usrId",
            element: <MeetingsAdd />,
            errorElement: <Olympe.ErrorPage />
          }
        ]
      },

      // OTHER
      {
        path: "notifications/",
        element: <Notifs />,
        errorElement: <Olympe.ErrorPage />
      },
      {
        path: "administration/",
        element: <AdminPage />,
        errorElement: <Olympe.ErrorPage />
      },
      {
        path: "settings/",
        element: <SettingsPage />,
        errorElement: <Olympe.ErrorPage />
      },
      {
        path: "application/",
        element: <ApplicationPage />,
        errorElement: <Olympe.ErrorPage />
      }
    ]
  },
  {
    path: "login/",
    element: <Olympe.LoginPage />,
    errorElement: <Olympe.ErrorPage />
  }
]);

root.render(
  <React.StrictMode>
    <ToastContainer />
    <ProSidebarProvider>
      {/* <Suspense fallback={<Olympe.Loader />}> */}
      <Olympe.GlobalStyles />
      <RouterProvider router={router} fallbackElement={Olympe.Loader} />
      {/* </Suspense> */}
    </ProSidebarProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
