import React, { useEffect, useState } from "react";
import {
  Archive,
  ArrowLeftCircle,
  CheckCircle,
  Image,
  Package
} from "react-feather";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

import { axiosGet, axiosPost } from "../datas/fetch";
import { ItemSetup } from "../components/StyledItem";
import { MainContext } from "../datas/context";
import { Olympe } from "dv-olympe";

export default function ProductsAdd () {
  const context = React.useContext(MainContext);
  const navigate = useNavigate();

  const [infos, setInfos] = useState({});
  const [edit, setEdit] = useState(false);
  const [modified, setModified] = useState(false);
  const [fileName, setFileName] = useState("");

  const [corp, setCorp] = useState([]);
  const [logo, setLogo] = useState([]);

  useEffect(() => {
    const refreshInfos = async () => {
      axiosGet({
        apiKey: context.api.key,
        uri: "products/?id=0&edit"
      }).then((data) => {
        setInfos(data);
      });
    };

    refreshInfos();

    return () => refreshInfos;
  }, [navigate]);

  useEffect(() => {
    if (
      fileName.length > 0 &&
      corp.label?.length > 0 &&
      logo.label?.length > 0
    ) {
      setModified(true);
    } else {
      setModified(false);
    }
  }, [corp, logo, fileName]);

  const handleSearchFile = (event) => setFileName(event.target.value);

  const editProduct = () => {
    const proName = fileName;
    const proCorp = corp.label || null;
    const proLogo = logo.label || null;

    if (proName.length === 0) {
      toast.info(
        "Le nom d'un produit est obligatoire, veuillez remplir ce champ."
      );
      return;
    }

    if (!proCorp) {
      toast.info("Veuillez sélectionner ou ajouter un fabricant.");
      return;
    }

    if (!proLogo) {
      toast.info("Veuillez sélectionner une image pour le produit.");
      return;
    }

    setEdit(true);
    axiosPost({
      apiKey: context.api.key,
      uri: "products",
      opt: { proName, proCorp, proLogo }
    })
      .then((data) => {
        if (data !== "true") {
          throw new Error(data || "réponse serveur erronée.");
        }
        toast.success("Le produit a bien été ajouté.", {
          icon: <Archive />
        });
        navigate(`/products/${proCorp}`);
      })
      .catch((e) =>
        toast.error(
          "Une erreur est survenue, le produit n'a pas été ajouté : " +
            e.message
        )
      )
      .finally(() => {
        setEdit(false);
      });
  };

  if (!infos.corps) return <Olympe.Loader />;

  return (
    <Olympe.Wrapper horizontal>
      <Olympe.SubItem>
        <ItemSetup>
          <Olympe.ItemTitle>
            <span>
              <Olympe.Tooltips
                title="Revenir à la page précédente."
                classes="icon"
                placement="left"
              >
                <Olympe.Button.Basic
                  toExecute={() => navigate(-1)}
                  icon={<ArrowLeftCircle />}
                />
              </Olympe.Tooltips>

              <span style={{ marginLeft: 20 }}>Produit</span>
            </span>
          </Olympe.ItemTitle>

          {logo.label
            ? (
              <img
                src={infos.url + logo.label.trim()}
                alt={fileName}
                height={150}
                width={150}
                style={{ marginBottom: 30, objectFit: "cover" }}
              />
            )
            : (
              <p
                style={{
                  height: 150,
                  width: 150,
                  backgroundColor: "#ddd",
                  borderColor: "#ddd",
                  borderWidth: 1,
                  margin: "auto",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: 30,
                  padding: 10,
                  color: "#000",
                  fontWeight: "bold",
                  borderRadius: 25
                }}
              >
                Veuillez choisir une image pour ce produit.
              </p>
            )}

          <div className="usrDetails productName">
            <Package />
            <Olympe.Input.Text
              type="text"
              onChange={handleSearchFile}
              value={fileName}
              placeholder="Nom du produit"
              autoComplete="off"
            />
          </div>

          <div className="usrDetails">
            <Archive />
            <CreatableSelect
              className="select"
              options={infos.corps || []}
              placeholder="Fabricant"
              defaultValue={corp}
              onChange={(t) => setCorp(t)}
              formatCreateLabel={(newVal) => `Ajouter : ${newVal}`}
              noOptionsMessage={() => "Aucun fabricant trouvé 😬"}
            />
          </div>

          <div className="usrDetails">
            <Image />
            <Select
              className="select"
              options={infos.imgs || []}
              placeholder="Logo du produit"
              defaultValue={logo}
              onChange={(t) => setLogo(t)}
              noOptionsMessage={() => "Aucune image trouvée 😬"}
            />
          </div>

          <div className="footerInfos">
            {!edit
              ? (
                <Olympe.Button.Basic
                  label="Valider"
                  icon={<CheckCircle />}
                  toExecute={modified ? editProduct : false}
                />
              )
              : (
                <Olympe.MiniLoader />
              )}
          </div>
        </ItemSetup>
      </Olympe.SubItem>
    </Olympe.Wrapper>
  );
}
