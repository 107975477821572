import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/fr";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import $ from "jquery";
import axios from "axios";
import { LinearProgress } from "@mui/material";
import { Olympe } from "dv-olympe";
import {
  Activity,
  AlertCircle,
  ArrowLeftCircle,
  AtSign,
  Bell,
  BellOff,
  Briefcase,
  Calendar,
  CheckCircle,
  Clock,
  Cpu,
  Edit2,
  File,
  FilePlus,
  Folder,
  GitBranch,
  GitCommit,
  Globe,
  Hash,
  Home,
  Info,
  Key,
  Layers,
  LogIn,
  MapPin,
  MessageCircle,
  Package,
  Phone,
  PhoneOff,
  PlusCircle,
  Smartphone,
  Trash,
  Upload,
  UserCheck,
  Watch,
  Wind,
  X,
  XCircle
} from "react-feather";

import { ActiveTime, totalTimeInApp } from "../datas/UtilsFunctions";
import { axiosDelete, axiosGet, axiosPut } from "../datas/fetch";
import { MainContext } from "../datas/context";
import { ItemFile, ItemHistory } from "../components/StyledItem";
import {
  ButtonsList,
  HorizontalCard,
  HorizontalWrap
} from "../components/StyledElements";

const columns = [
  {
    name: "Titre",
    selector: (row) => (
      <span className="tableTitle">
        {row.toastType === "success"
          ? (
            <CheckCircle className="success" />
          )
          : row.toastType === "error"
            ? (
              <AlertCircle className="error" />
            )
            : (
              <Info className="info" />
            )}
        <span>{Olympe.Utils.truncStr({ str: row.toastTitle, len: 20 })}</span>
      </span>
    ),
    grow: 1,
    hide: "sm"
  },
  {
    name: "Description",
    selector: (row) =>
      Olympe.Utils.truncStr({ str: row.toastContent, len: 30 }),
    hide: "md",
    grow: 2
  },
  {
    name: "Date",
    selector: (row) => row.curtime,
    format: (row) => Olympe.Utils.SentLogTime(row.curtime),
    sortable: true,
    grow: 1
  }
];

export default function AccountView () {
  const context = React.useContext(MainContext);
  const [logs, setLogs] = useState([]);
  const [datas, setDatas] = useState({});
  const [search, setSearch] = useState("");
  const [logsLoad, setLogsLoad] = useState(true);

  const [resetId, setResetId] = useState(false);
  const [deleteUsr, setDelete] = useState(false);
  const [resetPwd, setResetPwd] = useState(false);
  const [resetAct, setResetAct] = useState(false);

  const [open, setOpen] = useState(false);
  const [openId, setOpenId] = useState(false);
  const [openPwd, setOpenPwd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const closeModal = () => setOpen(false);
  const closeModalId = () => setOpenId(false);
  const closeModalPwd = () => setOpenPwd(false);
  const closeModalDelete = () => setOpenDelete(false);

  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(0);
  const [fileLoad, setFileLoad] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(null);

  const selectFile = (event) => {
    setSelectedFiles(event.target.files);

    if (event.target.files[0]) {
      setValue("fileName", magicFileName(event.target.files[0].name));
    } else {
      setSelectedFiles(null);
    }
  };

  const params = useParams();
  const navigate = useNavigate();

  const { register, setValue, getValues, watch } = useForm();

  useEffect(() => {
    const refreshInfos = async () => {
      axiosGet({
        apiKey: context.api.key,
        uri: `users/?item=user&usrId=${params.usrId}`
      }).then((data) => {
        if (!data.id) {
          toast.error("Utilisateur non trouvé.", { icon: "🔎" });
          navigate("/accounts/");
          return;
        }
        setDatas(data);
      });

      axiosGet({
        apiKey: context.api.key,
        uri: `users/?item=logs&usrId=${params.usrId}`
      }).then((data) => {
        setLogs(data);
      });

      if (logsLoad) setLogsLoad(false);
    };

    watch("fileName");
    refreshInfos();
    const datasInterval = setInterval(() => refreshInfos(), 2000);

    return () => {
      clearInterval(datasInterval);
    };
  }, []);

  const handleSearch = (event) => setSearch(event.target.value);

  const getExpoToken = (str = "") => {
    if (str.includes("ExponentPushToken")) {
      return str.replace("ExponentPushToken[", "").replace("]", "");
    } else if (str === "not_a_device") return "Emulateur";
    else if (str === "notification_not_allowed") {
      return "Notifications bloquées";
    }
  };

  const linkify = (inputText) => {
    let replacedText;
    const replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
    const replacePattern1 =
      /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
    const replacePattern3 =
      /(([a-zA-Z0-9\-_.])+@[a-zA-Z_]+?(\.[a-zA-Z]{2,6})+)/gim;

    replacedText = inputText.replace(
      replacePattern1,
      "<a href=\"$1\" target=\"_blank\">$1</a>"
    );
    replacedText = replacedText.replace(
      replacePattern2,
      "$1<a href=\"http://$2\" target=\"_blank\">$2</a>"
    );

    replacedText = replacedText.replace(
      replacePattern3,
      "<a href=\"mailto:$1\">$1</a>"
    );

    return replacedText;
  };

  const ExpandedComponent = ({ data }) => {
    const type =
      data.toastType === "success"
        ? (
          <CheckCircle />
        )
        : data.toastType === "error"
          ? (
            <AlertCircle />
          )
          : (
            <Info />
          );
    return (
      <div className="logsWrapper">
        <div className="logsContainer">
          <h3>{data.toastTitle}</h3>
          <h4
            dangerouslySetInnerHTML={{ __html: linkify(data.toastContent) }}
          />

          <div className="logsDetails">
            {type}
            <span className="onlyText">
              {Olympe.Utils.stringUpper(data.toastType)}
            </span>
          </div>

          <div className="logsDetails">
            <GitBranch />
            <span className="logsDetailsTitle">App :</span>
            <span>{data.appVersion}</span>
          </div>

          <div className="logsDetails">
            <Layers />
            <span className="logsDetailsTitle">Index :</span>
            <span>{data.id}</span>
          </div>

          <div className="logsDetails">
            <Clock />
            <span className="onlyText">
              {moment(data.curtime)
                .format("DD MMM YYYY à HH:mm")
                .replace(":", "h")}
            </span>
          </div>
        </div>

        <div className="logsContainer">
          <h3>Appareil</h3>
          <h4>Informations complémentaires concernant l'appareil.</h4>

          {data.isDevice !== "1" && (
            <div className="logsDetails">
              <PhoneOff />
              <span className="logsDetailsTitle">Emulateur ou simulateur</span>
            </div>
          )}

          <div className="logsDetails">
            <Smartphone />
            <span className="logsDetailsTitle">
              {data.modelName}, {data.deviceBrand}
            </span>
          </div>

          <div className="logsDetails">
            <Cpu />
            <span className="logsDetailsTitle">CPU(s) :</span>
            <span>{data.cpuSupported}</span>
          </div>

          <div className="logsDetails">
            <GitCommit />
            <span className="onlyText">
              {data.osName} {data.osVersion}
            </span>
          </div>

          <div className="logsDetails">
            <Globe />
            <span className="logsDetailsTitle">Année :</span>
            <span>{data.yearClass}</span>
          </div>
        </div>
      </div>
    );
  };

  const getSubHeaderComponent = () => (
    <Olympe.Input.Text
      className="search"
      type="text"
      onChange={handleSearch}
      value={search}
      placeholder="Rechercher..."
      autoComplete="off"
    />
  );

  const filteredDatas = () =>
    logs.filter(
      (obj) =>
        obj.toastType.includes(search) ||
        obj.toastTitle.includes(search) ||
        obj.toastContent.includes(search) ||
        obj.curtime.includes(search)
    );

  const resetActivity = () => {
    setResetAct(true);
    axiosPut({
      apiKey: context.api.key,
      uri: "user/?item=activity",
      opt: { clid: datas.id }
    })
      .then((data) => {
        if (data !== "true") {
          throw new Error(data || "réponse serveur erronée.");
        }
        toast.success("L'activité de l'utilisateur a bien été supprimée.", {
          icon: <Activity />
        });
      })
      .catch((e) =>
        toast.error(
          "Une erreur est survenue, l'activité de l'utilisateur n'a pas été réinitialisée : " +
            e.message
        )
      )
      .finally(() => {
        setResetAct(false);
        setOpen(false);
      });
  };

  const resetPassword = () => {
    setResetPwd(true);
    axiosPut({
      apiKey: context.api.key,
      uri: "ask/?item=newPwd",
      opt: { clid: datas.id }
    })
      .then((data) => {
        if (data !== "true") {
          throw new Error(data || "réponse serveur erronée.");
        }
        toast.success(
          "Le mot de passe de l'utilisateur a bien été réinitialisé.",
          { icon: <Key /> }
        );
      })
      .catch((e) =>
        toast.error(
          "Une erreur est survenue, le mot de passe n'a pas été réinitialisé : " +
            e.message
        )
      )
      .finally(() => {
        setResetPwd(false);
        setOpenPwd(false);
      });
  };

  const resetIdentifiant = () => {
    setResetId(true);
    axiosPut({
      apiKey: context.api.key,
      uri: "ask/?item=newId",
      opt: { clid: datas.id }
    })
      .then((data) => {
        if (data !== "true") {
          throw new Error(data || "réponse serveur erronée.");
        }
        toast.success(
          "L'identifiant de l'utilisateur a bien été réinitialisé.",
          { icon: <Wind /> }
        );
        navigate("/accounts/");
      })
      .catch((e) =>
        toast.error(
          "Une erreur est survenue, l'identifiant n'a pas été réinitialisé : " +
            e.message
        )
      )
      .finally(() => {
        setResetId(false);
        setOpenId(false);
      });
  };

  const deleteUser = () => {
    setDelete(true);
    axiosDelete({
      apiKey: context.api.key,
      uri: "user",
      opt: { clid: datas.id }
    })
      .then((data) => {
        if (data !== "true") {
          throw new Error(data || "réponse serveur erronée.");
        }
        toast.success("L'utilisateur a bien été supprimé.", {
          icon: <Trash />
        });
        navigate("/accounts/");
      })
      .catch((e) =>
        toast.error(
          "Une erreur est survenue, l'utilisateur n'a pas été supprimé : " +
            e.message
        )
      )
      .finally(() => {
        setDelete(false);
        setOpenDelete(false);
      });
  };

  const magicFileName = (str = "") => {
    if (str.length === 0) {
      return "Nouveau fichier";
    }

    const tmp = str.replace(/\.[^/.]+$/, "");

    tmp.replace(/[&/\\#,+$~%.'"*?<>{}]/g, "");
    return tmp;
  };

  const UploadFile = () => {
    if (!selectedFiles) {
      return console.error("Empty file");
    }

    let count = 0;
    const formData = new FormData();
    const currentFile = selectedFiles[0];

    const fileName =
      getValues("fileName").length === 0
        ? "Nouveau Fichier"
        : magicFileName(getValues("fileName"));

    formData.append("file", currentFile);
    setProgress(0);
    setBuffer(0);
    setFileLoad(true);

    const toastId = toast.info("Préparation au téléchargement", {
      isLoading: true,
      autoClose: false
    });

    return axios
      .post(
        `https://api.mayansenergies.com/v1/file/?fileName=${fileName}&clid=${datas.id}&item=file`,
        formData,
        {
          auth: { username: context.api.key },
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            count++;
            const prog = progressEvent.loaded / progressEvent.total;

            if (buffer === 0) setBuffer(prog * 100 * 2);

            setBuffer(Math.round(100 * prog) + Math.round(100 * prog) / count);
            setProgress(Math.round(100 * prog));

            if (prog < 1) {
              toast.update(toastId, {
                render: `Téléchargé à ${Math.round(prog * 100)} %`,
                progress,
                isLoading: true
              });
            }
          }
        }
      )
      .then(({ data }) => {
        if (data !== "true") {
          throw new Error(data || "réponse serveur erronée.");
        }

        toast.update(toastId, {
          render: `Le fichier "${fileName}" a bien été téléchargé.`,
          type: "success",
          isLoading: false,
          autoClose: true,
          icon: <Upload />
        });
      })
      .catch((e) => {
        toast.update(toastId, {
          render: `Le fichier "${fileName}" n'a pas été téléchargé : ${e.message}`,
          type: "error",
          isLoading: false,
          autoClose: true
        });
      })
      .finally(() => {
        setProgress(0);
        setFileLoad(false);
        setSelectedFiles(null);
      });
  };

  const renderImgCard = (elt) => (
    <HorizontalCard
      key={elt.setId}
      onClick={() => navigate(`/setups/details/${elt.id}`)}
    >
      <img
        src={elt.image}
        alt={elt.product}
        height={100}
        width={100}
        className="contain"
      />
      <h3>
        {Olympe.Utils.truncStr({
          str: elt.product || "nc",
          len: 15
        })}
      </h3>
      <p>{elt.fabricant.toUpperCase()}</p>
      <span>
        {moment(elt.date).format("LL")}
        {elt.contrat?.length > 0 && (
          <>
            {" "}
            <Olympe.Tooltips
              title={"Cette installation est sous contrat."}
              notSpan
            >
              <CheckCircle
                color={Olympe.Color.primary}
                size={12}
                style={{ marginTop: 3 }}
              />
            </Olympe.Tooltips>
          </>
        )}
      </span>
    </HorizontalCard>
  );

  if (!datas.id) return <Olympe.Loader />;

  return (
    <Olympe.Wrapper>
      <Olympe.Modal
        open={open}
        loading={resetAct}
        onClose={closeModal}
        title="Réinitialiser l'activité"
        verifTxt={datas.name}
        content={
          <>
            Réinitialiser l'activité remet à zéro les statistiques de connexion
            relatives à cet utilisateur.
          </>
        }
        buttons={[
          {
            isCancel: true,
            label: "Annuler",
            icon: <XCircle />,
            exec: closeModal
          },
          {
            isDanger: true,
            label: "Réinitialiser",
            icon: <Trash />,
            exec: resetActivity
          }
        ]}
      />

      <Olympe.Modal
        open={openId}
        loading={resetId}
        onClose={closeModalId}
        verifTxt={datas.name}
        title="Réinitialiser l'identifiant"
        content={
          <>
            Réinitialiser l'identifiant peut prendre un certain temps car il
            faut actualiser toutes les bases de données et champs reliés à cet
            utilisateur.
          </>
        }
        buttons={[
          {
            isCancel: true,
            label: "Annuler",
            icon: <XCircle />,
            exec: closeModalId
          },
          {
            isDanger: true,
            label: "Réinitialiser",
            icon: <Wind />,
            exec: resetIdentifiant
          }
        ]}
      />

      <Olympe.Modal
        open={openPwd}
        loading={resetPwd}
        onClose={closeModalPwd}
        title="Réinitialiser le mot de passe"
        content={
          <>
            Cette action va générer un nouveau code de connexion pour
            l'utilisateur. Un mail lui sera envoyé comprenant ce nouveau code.
          </>
        }
        buttons={[
          {
            isCancel: true,
            label: "Annuler",
            icon: <XCircle />,
            exec: closeModalPwd
          },
          {
            label: "Réinitialiser",
            icon: <Edit2 />,
            exec: resetPassword
          }
        ]}
      />

      <Olympe.Modal
        open={openDelete}
        loading={deleteUsr}
        verifTxt={datas.name}
        onClose={closeModalDelete}
        title="Supprimer l'utilisateur"
        content={
          <>
            Supprimer un utilisateur est irréversible ; ses données sont perdues
            à l'exception des fichiers et rendez-vous reliés à celui-ci.
          </>
        }
        buttons={[
          {
            isCancel: true,
            label: "Annuler",
            icon: <XCircle />,
            exec: closeModalDelete
          },
          {
            isDanger: true,
            label: "Supprimer",
            icon: <Trash />,
            exec: deleteUser
          }
        ]}
      />

      <ButtonsList clr={context.clr}>
        <div className="button userPannel">
          <h2>{datas.name}</h2>
          <span className="icon">
            {Olympe.Utils.AvatarSvgHtml({
              seed: datas.name
            })}
          </span>
        </div>

        <span className="list">
          <Link className="button" to={`/setups/${params.usrId}`}>
            <Olympe.Tooltips
              title={
                datas.setups.length > 0
                  ? `Voir ${
                    datas.setups.length > 1
                      ? `les ${datas.setups.length} installations`
                      : "l'installation"
                  } de ${datas.name}`
                  : "Aucune installation reliée à ce compte."
              }
              placement="bottom"
              classes="icon"
            >
              <Package />
            </Olympe.Tooltips>
          </Link>

          <Link className="button" to={`/files/${params.usrId}`}>
            <Olympe.Tooltips
              title={
                datas.files.length > 0
                  ? `Voir ${
                    datas.files.length > 1
                      ? `les ${datas.files.length} fichiers`
                      : "le fichier"
                  } de ${datas.name}`
                  : "Aucun fichier relié à ce compte."
              }
              placement="bottom"
              classes="icon"
            >
              <Folder />
            </Olympe.Tooltips>
          </Link>

          <Link className="button" to={`/meets/${params.usrId}`}>
            <Olympe.Tooltips
              title={"Voir les rendez-vous reliés à ce compte."}
              placement="bottom"
              classes="icon"
            >
              <Calendar />
            </Olympe.Tooltips>
          </Link>

          <Link className="button" to={`/chats/${params.usrId}`}>
            <Olympe.Tooltips
              title={`Ouvrir la messagerie de ${datas.name}`}
              placement="bottom"
              classes="icon"
            >
              {datas.logs.chats ? <span className="badgeOn" /> : null}
              <MessageCircle />
            </Olympe.Tooltips>
          </Link>
        </span>
      </ButtonsList>

      <Olympe.SubItem>
        <Olympe.Item>
          <Olympe.ItemTitle>
            <span>
              <Olympe.Tooltips
                title="Revenir à la page précédente."
                classes="icon"
                placement="left"
              >
                <Olympe.Button.Basic
                  toExecute={() => navigate(-1)}
                  icon={<ArrowLeftCircle />}
                />
              </Olympe.Tooltips>

              <span style={{ marginLeft: 20 }}>Informations</span>
            </span>
          </Olympe.ItemTitle>

          <p className="usrDetails">
            <AtSign />
            <span>{datas.mail}</span>
          </p>

          <p className="usrDetails">
            <Phone />
            <span>{datas.phon}</span>
          </p>

          <p className="usrDetails">
            {datas.type === "cl_pro"
              ? (
                <>
                  <Briefcase />
                  <span>Professionel</span>
                </>
              )
              : (
                <>
                  <Home />
                  <span>Particulier</span>
                </>
              )}
          </p>

          <p className="usrDetails">
            <MapPin />
            <span>
              {datas.addr.length > 1
                ? (
                  <>{datas.addr.length} adresses recensées</>
                )
                : (
                  datas.addr[0]
                )}
            </span>
          </p>

          <p className="usrDetails">
            {datas.noti === "true"
              ? (
                <>
                  <Bell />
                  <span>Notifications activées</span>
                </>
              )
              : (
                <>
                  <BellOff />
                  <span>Notifications désactivées</span>
                </>
              )}
          </p>

          <div className="footerInfos">
            <Olympe.Button.Basic
              label="Modifier les informations"
              icon={<Edit2 />}
              toExecute={() => navigate(`/accounts/${params.usrId}/edit/`)}
            />
          </div>
        </Olympe.Item>

        <Olympe.Item>
          <Olympe.ItemTitle>Activité</Olympe.ItemTitle>

          <p className="usrDetails">
            <LogIn />
            <Olympe.Tooltips title="Nombre total de connexions">
              {datas.logs.count > 0
                ? (
                  <>
                    {datas.logs.count} connexion
                    {datas.logs.count > 0 ? "s" : ""}
                  </>
                )
                : (
                  "Pas d'activité"
                )}
            </Olympe.Tooltips>
          </p>

          <p className="usrDetails">
            <Clock />
            <Olympe.Tooltips title="Temps total de connexion">
              {totalTimeInApp(datas.logs.total)}
            </Olympe.Tooltips>
          </p>

          <p className="usrDetails">
            <Activity />
            <Olympe.Tooltips title={"Dernière activité enregistrée"}>
              {datas.logs.last === datas.logs.first
                ? "Aucune activité"
                : ActiveTime(datas.logs.last)}
            </Olympe.Tooltips>
          </p>

          <p className="usrDetails">
            <Watch />
            <Olympe.Tooltips title={"Durée moyenne de connexion"}>
              {datas.logs.count === 0
                ? "Aucune activité"
                : totalTimeInApp(
                  Math.round(datas.logs.total / datas.logs.count, 2)
                )}
            </Olympe.Tooltips>
          </p>

          <p className="usrDetails">
            <UserCheck />
            <Olympe.Tooltips title={"Utilisateur enregistré le"}>
              {Olympe.Utils.stringUpper(
                moment(datas.logs.first).format("dddd Do MMMM YYYY")
              )}
            </Olympe.Tooltips>
          </p>

          <div className="footerInfos">
            <Olympe.Button.Basic
              label="Réinitialiser les valeurs"
              icon={<Trash />}
              toExecute={() => setOpen(true)}
            />
          </div>
        </Olympe.Item>
      </Olympe.SubItem>

      <Olympe.SubItem>
        {datas.addr.length > 1 && (
          <Olympe.ItemFullWidth>
            <Olympe.ItemTitle>Adresses de résidence</Olympe.ItemTitle>

            {datas.addr.map((addr, idx) => (
              <p
                className="usrDetails"
                key={`${idx}-${addr}`}
                style={{ marginTop: 3, marginBottom: 3 }}
              >
                <MapPin />
                <span className="codeDesc">
                  Adresse n°{idx + 1} : {addr}
                </span>
              </p>
            ))}
          </Olympe.ItemFullWidth>
        )}
      </Olympe.SubItem>

      <Olympe.SubItem>
        <ItemFile>
          <input
            type="file"
            onChange={selectFile}
            accept=".pdf"
            id="pickFile"
          />

          <Olympe.ItemTitle>
            <span>
              <FilePlus />
              Ajouter un fichier
            </span>
          </Olympe.ItemTitle>

          <div className="loadFile">
            <p>
              Ajouter un fichier depuis votre appareil, puis donnez lui une
              brève description qui sera visible par l'utilisateur.
            </p>

            {!selectedFiles && (
              <Olympe.Button.FilePick
                className="btnGetFiles"
                onClick={() => $("#pickFile").trigger("click")}
              >
                Choisir un fichier
              </Olympe.Button.FilePick>
            )}

            {selectedFiles && (
              <Olympe.Input.FormFile>
                <span>
                  <File />

                  <Olympe.Input.File
                    type="input"
                    placeholder="Description du fichier"
                    {...register("fileName")}
                  />

                  <Olympe.Button.FileCancel
                    onClick={() => setSelectedFiles(null)}
                  >
                    <X />
                  </Olympe.Button.FileCancel>
                </span>

                <Olympe.Button.FileUpload
                  className="btnUpload"
                  onClick={UploadFile}
                >
                  Télécharger
                </Olympe.Button.FileUpload>
              </Olympe.Input.FormFile>
            )}
          </div>

          {fileLoad && (
            <LinearProgress
              value={progress}
              valueBuffer={buffer}
              variant="buffer"
            />
          )}
        </ItemFile>
      </Olympe.SubItem>

      {datas.setups?.length > 0 && (
        <Olympe.SubItem>
          <Olympe.ItemFullWidth>
            <Olympe.ItemTitle>
              <span>
                <Package />
                Installation{datas.setups?.length > 1 ? "s" : ""}
              </span>

              <Olympe.Button.Basic
                label="Ajouter"
                icon={<PlusCircle />}
                toExecute={() => navigate(`/setups/add/${datas.id}`)}
              />
            </Olympe.ItemTitle>

            <HorizontalWrap>
              {datas?.setups.map((elt) => renderImgCard(elt))}
            </HorizontalWrap>
          </Olympe.ItemFullWidth>
        </Olympe.SubItem>
      )}

      <Olympe.SubItem>
        <Olympe.ItemFullWidth>
          <Olympe.ItemTitle>Codes uniques</Olympe.ItemTitle>

          <p className="usrDetails">
            <Key />
            <span className="codeDesc">
              Code unique reliant l'utilisateur à ses documents, installations,
              informations et autre ; ce code est automatiquement généré à la
              création de celui-ci est reste inchangé dans le temps :{" "}
              <Olympe.Tooltips
                title={"Identifiant unique"}
                placement="bottom"
                classes="codeUnique"
              >
                {datas.id.replace("user_", "")}
              </Olympe.Tooltips>
            </span>
          </p>
          <p className="usrDetails">
            <Hash />
            <span className="codeDesc">
              Code unique généré à partir du bundle de l'application et de la
              signature de l'appareil ; il sert à recevoir des notifications
              même avec l'application fermée :{" "}
              <Olympe.Tooltips
                title={"Code de notifications"}
                placement="bottom"
                classes="codeUnique"
              >
                {getExpoToken(datas.logs.expoToken)}
              </Olympe.Tooltips>
            </span>
          </p>
          <div className="footerInfos">
            <Olympe.Button.Basic
              label="Réinitialiser le mot de passe"
              icon={<Edit2 />}
              toExecute={() => setOpenPwd(true)}
            />
          </div>
        </Olympe.ItemFullWidth>
      </Olympe.SubItem>

      <Olympe.SubItem>
        <ItemHistory>
          <Olympe.ItemTitle>Historique</Olympe.ItemTitle>

          <div className="logsTable">
            <Olympe.Table.Wrap
              clr={context.clr}
              responsive
              columns={columns}
              data={filteredDatas()}
              noDataComponent={Olympe.Table.Empty()}
              pagination
              paginationComponentOptions={Olympe.Table.Pagination}
              progressPending={logsLoad}
              progressComponent={<Olympe.MiniLoader />}
              expandableRows
              expandableRowsComponent={ExpandedComponent}
              subHeader
              subHeaderComponent={getSubHeaderComponent()}
              theme="customTheme"
              customStyles={Olympe.Table.Style}
            />
          </div>
        </ItemHistory>
      </Olympe.SubItem>

      <Olympe.SubItem>
        <Olympe.ItemFullWidth>
          <Olympe.ItemTitle>Zone dangereuse</Olympe.ItemTitle>

          <p className="usrDetails">
            <Wind />
            <span className="codeDesc">
              Réinitialiser l'identifiant unique de l'utilisateur permet de
              régler un soucis de type "double id" (deux utilisateurs dépendant
              du même token). L'effet est immédiat ; les utilisateurs connectés
              seront déconnectés. <br />
              <strong>Attention :</strong> ne pas utiliser à outrance car
              possibilité de bug ou perte de données.
            </span>
          </p>
          <p className="usrDetails">
            <Trash />
            <span className="codeDesc">
              Supprimer un utilisateur est irréversible ; ses données sont
              perdues à l'exception des fichiers et rendez-vous reliés à
              celui-ci. Ces derniers pourront être supprimés manuellement depuis
              leur section respective.
            </span>
          </p>
          <div className="footerInfos">
            <Olympe.Button.Basic
              label="Réinitialiser l'identifiant"
              icon={<Wind />}
              anim="danger"
              toExecute={() => setOpenId(true)}
            />

            <Olympe.Button.Basic
              label="Supprimer le compte"
              icon={<Trash />}
              anim="danger"
              toExecute={() => setOpenDelete(true)}
            />
          </div>
        </Olympe.ItemFullWidth>
      </Olympe.SubItem>
    </Olympe.Wrapper>
  );
}
