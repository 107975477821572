import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import {
  AlertTriangle,
  Archive,
  ArrowLeftCircle,
  CheckCircle,
  Edit2,
  Hash,
  Image,
  Package,
  Trash,
  XCircle
} from "react-feather";

import { axiosDelete, axiosGet, axiosPut } from "../datas/fetch";
import { ItemSetup } from "../components/StyledItem";
import { MainContext } from "../datas/context";
import { Olympe } from "dv-olympe";

export default function ProductsDetails () {
  const context = React.useContext(MainContext);
  const params = useParams();
  const navigate = useNavigate();

  const [infos, setInfos] = useState({});
  const [edit, setEdit] = useState(false);
  const [modified, setModified] = useState(false);
  const [delProduct, setDelProduct] = useState(false);
  const [fileName, setFileName] = useState("");

  const [corp, setCorp] = useState({});
  const [logo, setLogo] = useState({});

  const [openDelete, setOpenDelete] = useState(false);
  const closeModalDelete = () => setOpenDelete(false);

  useEffect(() => {
    const refreshInfos = async () => {
      axiosGet({
        apiKey: context.api.key,
        uri: `products/?id=${params.productId}`
      }).then((data) => {
        if (!data.id) {
          toast.error("Produit non trouvé.", { icon: "🔎" });
          navigate("/products/");
          return;
        }
        setInfos(data);
        setFileName(data.name || "");
        setCorp({
          label: data.corp.trim(),
          value: data.corp
            .trim()
            .toLowerCase()
            .replace(/[^a-zA-Z0-9]+/g, "")
        });

        setLogo({
          label: data.logo.trim(),
          value: data.logo
            .trim()
            .toLowerCase()
            .replace(/[^a-zA-Z0-9]+/g, "")
        });
      });
    };

    if (!params.productId) {
      toast.error("Produit non précisé.", { icon: <Package /> });
      navigate("/products/");
    }

    refreshInfos();

    return () => refreshInfos;
  }, [navigate]);

  useEffect(() => {
    if (
      fileName === infos.name &&
      corp.label === infos.corp &&
      logo.label === infos.logo
    ) {
      setModified(false);
    } else {
      setModified(true);
    }
  }, [corp, logo, fileName]);

  const handleSearchFile = (event) => setFileName(event.target.value);

  const editProduct = () => {
    const proName = fileName;
    const proCorp = corp.label || null;
    const proLogo = logo.label || null;

    if (proName.length === 0) {
      toast.info(
        "Le nom d'un produit est obligatoire, veuillez remplir ce champ."
      );
      return;
    }

    if (!proCorp) {
      toast.info("Veuillez sélectionner ou ajouter un fabricant.");
      return;
    }

    if (!proLogo) {
      toast.info("Veuillez sélectionner une image pour le produit.");
      return;
    }

    setEdit(true);
    axiosPut({
      apiKey: context.api.key,
      uri: "products",
      opt: { productId: infos.id, proName, proCorp, proLogo }
    })
      .then((data) => {
        if (data !== "true") {
          throw new Error(data || "réponse serveur erronée.");
        }
        toast.success("Le produit a bien été modifié.", {
          icon: <Edit2 />
        });
        navigate(`/products/${infos.corp}`);
      })
      .catch((e) =>
        toast.error(
          "Une erreur est survenue, le produit n'a pas été modifié : " +
            e.message
        )
      )
      .finally(() => {
        setEdit(false);
      });
  };

  const deleteProduct = () => {
    setDelProduct(true);
    axiosDelete({
      apiKey: context.api.key,
      uri: "products",
      opt: { productId: infos.id }
    })
      .then((data) => {
        if (data !== "true") {
          throw new Error(data || "réponse serveur erronée.");
        }
        toast.success("Le produit a bien été supprimé de la base de données.", {
          icon: <Trash />
        });
        navigate("/products/");
      })
      .catch((e) =>
        toast.error(
          "Une erreur est survenue, le produit n'a pas été supprimé : " +
            e.message
        )
      )
      .finally(() => {
        setDelProduct(false);
      });
  };

  if (!infos.id) return <Olympe.Loader />;

  return (
    <Olympe.Wrapper horizontal>
      <Olympe.Modal
        open={openDelete}
        loading={delProduct}
        verifTxt={fileName}
        onClose={closeModalDelete}
        title="Supprimer le produit"
        content={
          <>
            Supprimer un produit est irréversible ; ses informations sont
            supprimées de la base de données à la condition qu'aucune
            installation n'y soit reliée.
          </>
        }
        buttons={[
          {
            isCancel: true,
            label: "Annuler",
            icon: <XCircle />,
            exec: closeModalDelete
          },
          {
            isDanger: true,
            label: "Supprimer",
            icon: <Trash />,
            exec: deleteProduct
          }
        ]}
      />

      <Olympe.SubItem>
        <ItemSetup>
          <Olympe.ItemTitle>
            <span>
              <Olympe.Tooltips
                title="Revenir à la page précédente."
                classes="icon"
                placement="left"
              >
                <Olympe.Button.Basic
                  toExecute={() => navigate(-1)}
                  icon={<ArrowLeftCircle />}
                />
              </Olympe.Tooltips>

              <span style={{ marginLeft: 20 }}>Produit</span>
            </span>
          </Olympe.ItemTitle>

          <img
            src={infos.url
              .replace(infos.url.split("/").pop(), logo.label)
              .trim()}
            alt={fileName}
            height={150}
            width={150}
            style={{ marginBottom: 30, objectFit: "cover" }}
          />

          <div className="usrDetails productName">
            <Package />
            <Olympe.Input.Text
              type="text"
              onChange={handleSearchFile}
              value={fileName}
              placeholder="Nom du produit"
              autoComplete="off"
            />
          </div>

          <div className="usrDetails">
            <Archive />
            <CreatableSelect
              className="select"
              options={infos.corps || []}
              placeholder="Fabricant"
              defaultValue={corp}
              onChange={(t) => setCorp(t)}
              formatCreateLabel={(newVal) => `Ajouter : ${newVal}`}
              noOptionsMessage={() => "Aucun fabricant trouvé 😬"}
            />
          </div>

          {infos.error === 0 && logo.label === infos.logo && (
            <p className="usrDetails">
              <AlertTriangle color={Olympe.Color.tomato} />
              <span className="codeDesc">
                Cette image est introuvable selon le dernier rapport.
              </span>
            </p>
          )}

          <div className="usrDetails">
            <Image />
            <Select
              className="select"
              options={infos.imgs || []}
              placeholder="Logo du produit"
              defaultValue={logo}
              onChange={(t) => setLogo(t)}
              noOptionsMessage={() => "Aucune image trouvée 😬"}
            />
          </div>

          <p className="usrDetails">
            <Hash />
            <span>Utilisé {infos.count || 0} fois dans le serveur.</span>
          </p>

          <div className="footerInfos">
            {!edit
              ? (
                <>
                  <Olympe.Button.Basic
                    label="Supprimer"
                    icon={<Trash />}
                    anim="danger"
                    toExecute={() => setOpenDelete(true)}
                  />

                  <Olympe.Button.Basic
                    label="Valider"
                    icon={<CheckCircle />}
                    toExecute={modified ? editProduct : false}
                  />
                </>
              )
              : (
                <Olympe.MiniLoader />
              )}
          </div>
        </ItemSetup>
      </Olympe.SubItem>
    </Olympe.Wrapper>
  );
}
