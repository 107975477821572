import moment from "moment";
import "moment/locale/fr";

export const ActiveTime = (lastSeenString = null) => {
  const nowDate = moment();
  const lastSeen = moment(lastSeenString);
  const diff = nowDate.diff(lastSeen, "seconds");

  if (diff < 5) {
    return "En ligne";
  }
  if (diff < 60) {
    const units = nowDate.diff(lastSeen, "seconds");
    return `En ligne il y a ${units} seconde${units > 1 ? "s" : ""}`;
  }
  if (diff < 3600) {
    const units = nowDate.diff(lastSeen, "minutes");
    return `En ligne il y a ${units} minute${units > 1 ? "s" : ""}`;
  } else if (diff < 86400) {
    const units = nowDate.diff(lastSeen, "hours");
    return `En ligne il y a ${units} heure${units > 1 ? "s" : ""}`;
  } else if (diff < 604800) {
    const units = nowDate.diff(lastSeen, "days");
    return `En ligne il y a ${units} jour${units > 1 ? "s" : ""}`;
  } else if (diff < 2628000) {
    const units = nowDate.diff(lastSeen, "weeks");
    return `En ligne il y a ${units} semaine${units > 1 ? "s" : ""}`;
  } else if (diff < 31536000) {
    const units = nowDate.diff(lastSeen, "months");
    return `En ligne il y a ${units} mois`;
  } else {
    const units = nowDate.diff(lastSeen, "years");
    return `En ligne il y a ${units} an${units > 1 ? "s" : ""}`;
  }
};

export const shortTime = (diff = 0) => {
  if (diff < 60) {
    return `${diff} sec${diff > 1 ? "s" : ""}`;
  } else {
    return `${Math.floor(diff / 60)} min ${Math.floor(diff % 60)} sec${
      diff % 60 > 1 ? "s" : ""
    }`;
  }
};

export const totalTimeInApp = (secs = 0) => {
  if (secs < 60) return `${secs} seconde${secs > 1 ? "s" : ""}`;
  else if (secs < 3600) {
    const min = Math.floor(secs / 60);
    const sec = Math.floor(secs % 60);

    return `${min} minute${min > 1 ? "s" : ""} et ${sec} seconde${
      sec > 1 ? "s" : ""
    }`;
  } else if (secs < 86400) {
    const min = Math.floor((secs % 3600) / 60);
    const hr = Math.floor(secs / 3600);

    return `${hr} heure${hr > 1 ? "s" : ""} et ${min} minute${
      min > 1 ? "s" : ""
    }`;
  } else {
    const dy = Math.floor(secs / 86400);
    const hr = Math.floor((secs / 3600) % 24);

    return `${dy} jour${dy > 1 ? "s" : ""} et ${hr} heure${hr > 1 ? "s" : ""} `;
  }
};

export const getInstTime = (hours = 0) => {
  const m = Math.floor(hours % 60);
  const h = Math.floor(hours / 60);

  return hours % 60 === 0
    ? `${h}h`
    : h > 0
      ? `${h}h${m < 10 ? "0" : ""}${m}`
      : `${m < 10 ? "0" : ""}${m}min`;
};
