import React, { useState } from "react";
import { AtSign, Check, Info, Key, Plus, Repeat, User } from "react-feather";
import { useForm } from "react-hook-form";
import $ from "jquery";
import { toast } from "react-toastify";
import axios from "axios";
import { Olympe } from "dv-olympe";

import { axiosGet } from "../datas/fetch";
import { MainContext } from "../datas/context";
import { ListPwd } from "../components/StyledElements";

export default function SettingsPage () {
  const context = React.useContext(MainContext);
  const [datas, setDatas] = useState([]);
  const [infosLoad, setInfosLoad] = useState(false);
  const [pswdLoad, setPwdLoad] = useState(false);
  const [inputPwd, setInputPwd] = useState(false);

  const {
    register,
    setFocus,
    setValue,
    reset,
    getValues,
    watch,
    formState: { dirtyFields }
  } = useForm();

  React.useEffect(() => {
    const refreshInfos = async () => {
      axiosGet({
        apiKey: context.api.key,
        uri: "corp/"
      })
        .then((data) => {
          if (!data.infos) {
            throw new Error("Données d'entreprises erronées.");
          }
          setDatas(data.infos);
        })
        .catch((e) => {
          toast.error(
            `Chargement des données impossible, veuillez réessayer plus tard : ${e.message}`,
            {
              autoClose: false
            }
          );
        });
    };

    watch(["clName", "clMail", "clRole"]);

    refreshInfos();
    const datasInterval = setInterval(() => refreshInfos(), 3000);

    return () => {
      clearInterval(datasInterval);
    };
  }, []);

  if (!context.corp.infos || !datas.corpName) return <Olympe.Loader />;

  const submitPwd = () => {
    setPwdLoad(true);
    const pwd = getValues("pwd1");

    if (countPass(pwd) !== 0) {
      toast.error("Le mot de passe saisi ne remplit pas les conditions.");
      setFocus("pwd1");
      return;
    }
    axios
      .put(
        "https://api.damien-vergobbi.fr/v1/password/",
        {
          pwd
        },
        {
          auth: {
            username: context.api.key
          }
        }
      )
      .then(({ data }) => {
        if (data !== "true") {
          throw new Error(data || "requête serveur erronée");
        }

        toast.success("Le mot de passe du compte a bien été modifié.", {
          icon: <Check />
        });

        setValue("pwd1", "");
        setValue("pwd2", "");
      })
      .catch((e) => {
        toast.error(
          "Une erreur est survenue, le mot de passe du compte n'a pas été modifié : " +
            e.message
        );
      })
      .finally(() => setPwdLoad(false));
  };

  const submitAccount = () => {
    setInfosLoad(true);
    const mail = getValues("clMail");
    const name = getValues("clName");

    axios
      .put(
        "https://api.damien-vergobbi.fr/v1/account/",
        {
          mail,
          name
        },
        {
          auth: {
            username: context.api.key
          }
        }
      )
      .then(({ data }) => {
        if (data !== "true") {
          throw new Error(data || "requête serveur erronée");
        }

        toast.success("Les informations du compte ont bien été modifiées.");

        reset({
          clMail: mail,
          clName: name
        });
      })
      .catch((e) => {
        toast.error(
          "Une erreur est survenue, les informations du compte n'ont pas été modifiées : " +
            e.message
        );
      })
      .finally(() => setInfosLoad(false));
  };

  const verifEmail = (elt, original) => {
    const rgx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (!rgx.test(elt.value)) {
      toast.error("Email non conforme, l'ancienne adresse a été rétablit.");
      setValue(elt.name, original);
      setFocus(elt.name);
    }
  };

  const verifName = (elt, original) => {
    const rgx =
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;

    if (!rgx.test(elt.value)) {
      toast.error("Nom non conforme, l'ancien a été rétablit.");
      setValue(elt.name, original);
      setFocus(elt.name);
    }
  };

  const verifPass = (val = "") => {
    setInputPwd(true);
    let check = 0;
    if (val.length > 5) {
      $("#ListPwd").find("span[data-val='len']").addClass("filled");
    } else {
      $("#ListPwd").find("span[data-val='len']").removeClass("filled");
      check = 1;
    }

    if (/\d/.test(val)) {
      $("#ListPwd").find("span[data-val='int']").addClass("filled");
    } else {
      $("#ListPwd").find("span[data-val='int']").removeClass("filled");
      check = 1;
    }

    if (/[A-Z]/.test(val)) {
      $("#ListPwd").find("span[data-val='maj']").addClass("filled");
    } else {
      $("#ListPwd").find("span[data-val='maj']").removeClass("filled");
      check = 1;
    }

    if (/[a-z]/.test(val)) {
      $("#ListPwd").find("span[data-val='min']").addClass("filled");
    } else {
      $("#ListPwd").find("span[data-val='min']").removeClass("filled");
      check = 1;
    }

    if (/[!@#$%^&*()_+\-=[\]{};:\\|,./?~]/.test(val)) {
      $("#ListPwd").find("span[data-val='spe']").addClass("filled");
    } else {
      $("#ListPwd").find("span[data-val='spe']").removeClass("filled");
      check = 1;
    }
    return check;
  };

  const countPass = (val = "") => {
    if (val.length < 6) return 1;
    if (!/\d/.test(val)) return 1;
    if (!/[A-Z]/.test(val)) return 1;
    if (!/[a-z]/.test(val)) return 1;
    if (!/[!@#$%^&*()_+\-=[\]{};:\\|,./?~]/.test(val)) return 1;
    return 0;
  };

  return (
    <Olympe.Wrapper centered>
      <Olympe.SubItem>
        <Olympe.Item>
          <Olympe.ItemTitle>
            <span>
              <User /> Informations personnelles
            </span>
          </Olympe.ItemTitle>

          <div className="usrDetails">
            <User />

            <div className="basicInput">
              <Olympe.Input.Text
                type="text"
                {...register("clName")}
                defaultValue={context.corp.infos.name}
                autoComplete="off"
                placeholder="Prénom Nom"
                onKeyPress={(e) => {
                  e.key === "Enter" && Olympe.Utils.enterInput(e);
                }}
                onBlur={(e) => verifName(e.target, context.corp.infos.name)}
              />
            </div>
          </div>

          <div className="usrDetails">
            <AtSign />
            <div className="basicInput">
              <Olympe.Input.Text
                type="text"
                {...register("clMail")}
                defaultValue={context.corp.infos.mail}
                autoComplete="off"
                placeholder="Adresse mail"
                onKeyPress={(e) => {
                  e.key === "Enter" && Olympe.Utils.enterInput(e);
                }}
                onBlur={(e) => verifEmail(e.target, context.corp.infos.mail)}
              />
            </div>
          </div>

          <div className="usrDetails">
            <Key />
            <p>
              {context.corp.infos.permRole} (Niveau {context.corp.infos.perm})
            </p>
          </div>

          <div className="footerInfos">
            {infosLoad
              ? (
                <Olympe.MiniLoader />
              )
              : (
                <Olympe.Button.Basic
                  label="Enregister les modifications"
                  icon={<Check />}
                  toExecute={
                    dirtyFields.clMail || dirtyFields.clName
                      ? submitAccount
                      : false
                  }
                />
              )}
          </div>
        </Olympe.Item>

        <Olympe.Item>
          <Olympe.ItemTitle>
            <span>
              <Key /> Mot de passe
            </span>
          </Olympe.ItemTitle>

          <input
            readOnly
            type="text"
            value={context.corp.infos.mail}
            autoComplete="username email"
            style={{ display: "none" }}
          />

          <div className="usrDetails">
            <Plus />

            <div className="basicInput">
              <Olympe.Input.Text
                type="password"
                {...register("pwd1")}
                autoCapitalize="off"
                autoCorrect="off"
                defaultValue=""
                autoComplete="new-password"
                placeholder="Nouveau mot de passe"
                onKeyPress={(e) => {
                  e.key === "Enter" && Olympe.Utils.enterInput(e);
                }}
                onKeyUp={(e) => verifPass(e.target.value)}
                onFocus={(e) => {
                  verifPass(e.target.value);
                }}
                onBlur={() => setInputPwd(false)}
              />
            </div>
          </div>

          <div className="usrDetails">
            <Repeat />
            <div className="basicInput">
              <Olympe.Input.Text
                type="password"
                {...register("pwd2")}
                autoCapitalize="off"
                autoCorrect="off"
                defaultValue=""
                autoComplete="new-password"
                placeholder="Répétez le mot de passe"
                onKeyPress={(e) => {
                  e.key === "Enter" && Olympe.Utils.enterInput(e);
                }}
              />
            </div>
          </div>

          <div className="usrDetails">
            <Info />
            <p>Il s'agit du mot de passe de ce compte.</p>
          </div>

          <div className="footerInfos">
            {pswdLoad
              ? (
                <Olympe.MiniLoader />
              )
              : (
                <Olympe.Button.Basic
                  label="Mettre à jour"
                  icon={<Check />}
                  toExecute={
                    (dirtyFields.pwd1 || dirtyFields.pwd2) &&
                  countPass(getValues("pwd1")) === 0 &&
                  getValues("pwd1") === getValues("pwd2")
                      ? submitPwd
                      : false
                  }
                />
              )}
          </div>
        </Olympe.Item>
      </Olympe.SubItem>

      <Olympe.SubItem>
        {inputPwd && (
          <ListPwd id="ListPwd">
            <span data-val="len">Minimum 6 caractères</span>
            <span data-val="maj">Une Majuscule</span>
            <span data-val="min">Une minuscule</span>
            <span data-val="int">Un chiffre</span>
            <span data-val="spe">Un caractère spécial</span>
          </ListPwd>
        )}
      </Olympe.SubItem>
    </Olympe.Wrapper>
  );
}
