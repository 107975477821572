import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Activity,
  AlertTriangle,
  ArrowLeftCircle,
  Database,
  Edit2,
  File,
  FileText,
  Folder,
  Hash,
  Link,
  Trash,
  User,
  XCircle
} from "react-feather";
import $ from "jquery";

import { axiosDelete, axiosGet, axiosPut } from "../datas/fetch";
import { MainContext } from "../datas/context";
import { ItemPdf } from "../components/StyledItem";
import { Olympe } from "dv-olympe";

export default function FilesDetails () {
  const context = React.useContext(MainContext);
  const params = useParams();
  const navigate = useNavigate();

  const [infos, setInfos] = useState({});
  const [edit, setEdit] = useState(false);
  const [delFile, setDelFile] = useState(false);
  const [fileName, setFileName] = useState("");

  const [openDelete, setOpenDelete] = useState(false);
  const closeModalDelete = () => setOpenDelete(false);

  useEffect(() => {
    const refreshInfos = async () => {
      axiosGet({
        apiKey: context.api.key,
        uri: `files/?id=${params.fileId}`
      }).then((data) => {
        if (!data.fileId) {
          toast.error("Fichier non trouvé.", { icon: "🔎" });
          navigate("/files/");
          return;
        }
        setInfos(data);
        setFileName(data.fileName);
      });
    };

    if (!params.fileId) {
      toast.error("Fichier non précisé.", { icon: <File /> });
      navigate("/files/");
    }

    refreshInfos();

    return () => refreshInfos;
  }, [navigate]);

  const handleSearchFile = (event) => setFileName(event.target.value);

  const deleteFile = () => {
    setDelFile(true);
    axiosDelete({
      apiKey: context.api.key,
      uri: "file",
      opt: { fileId: infos.fileId }
    })
      .then((data) => {
        if (data !== "true" && data !== "trueDel") {
          throw new Error(data || "réponse serveur erronée.");
        }
        toast.success(
          data === "trueDel"
            ? "Le fichier a bien été supprimé du serveur et de la base de données."
            : "Le fichier a bien été supprimé de la base de données.",
          {
            icon: <Trash />
          }
        );
        navigate(`/files/${infos.usrId}`);
      })
      .catch((e) =>
        toast.error(
          "Une erreur est survenue, le fichier n'a pas été supprimé : " +
            e.message
        )
      )
      .finally(() => {
        setDelFile(false);
      });
  };

  const editDescFile = () => {
    setEdit(true);
    axiosPut({
      apiKey: context.api.key,
      uri: "ask/?item=filename",
      opt: { fileId: infos.fileId, newDesc: fileName }
    })
      .then((data) => {
        if (data !== "true") {
          throw new Error(data || "réponse serveur erronée.");
        }
        toast.success("La description du fichier a bien été modifiée.", {
          icon: <Edit2 />
        });
        navigate(`/files/${infos.usrId}`);
      })
      .catch((e) =>
        toast.error(
          "Une erreur est survenue, la description n'a pas été modifiée : " +
            e.message
        )
      )
      .finally(() => {
        setEdit(false);
      });
  };

  const ClickOnUrl = (e) => {
    if (!e.target || !navigator.clipboard) {
      toast.error("URL du fichier non copié.");
      return;
    }

    $(e.target)[0].setSelectionRange(0, e.target.value.length);

    navigator.clipboard
      .writeText(e.target.value)
      .then(function () {
        toast.success("URL du fichier copié avec succès.");
      })
      .catch(function () {
        toast.error("URL du fichier non copié.");
      });
  };

  if (!infos.fileId) return <Olympe.Loader />;

  return (
    <Olympe.WrapPdf>
      <Olympe.Modal
        open={openDelete}
        loading={delFile}
        verifTxt={fileName}
        onClose={closeModalDelete}
        title="Supprimer le fichier"
        content={
          <>
            Supprimer un fichier est irréversible ; ses informations sont
            supprimées de la base de données et celui-ci est supprimé du serveur
            sauf s'il est utilisé autre part.
          </>
        }
        buttons={[
          {
            isCancel: true,
            label: "Annuler",
            icon: <XCircle />,
            exec: closeModalDelete
          },
          {
            isDanger: true,
            label: "Supprimer",
            icon: <Trash />,
            exec: deleteFile
          }
        ]}
      />

      <ItemPdf className="infos">
        {!infos.fileUrl && (
          <p className="usrDetails">
            <AlertTriangle />
            <span>Ce fichier est erroné.</span>
          </p>
        )}

        <div className="usrDetails">
          <FileText />

          <div className="input">
            <Olympe.Tooltips
              title="Description du fichier"
              placement="bottom"
              notSpan
            >
              <Olympe.Input.Text
                type="text"
                onChange={handleSearchFile}
                value={fileName}
                placeholder="Nom du fichier"
                autoComplete="off"
              />
            </Olympe.Tooltips>
          </div>
        </div>

        <p className="usrDetails">
          <User />
          <Olympe.Tooltips title="Utilisateur relié">
            {infos.fileUsr}
          </Olympe.Tooltips>
        </p>

        {infos.fileUrl && (
          <p className="usrDetails">
            <Link />
            <Olympe.Tooltips title="Copier l'URL du fichier" notSpan>
              <input
                className="urlPdf"
                readOnly
                onClick={ClickOnUrl}
                value={infos.fileUrl && infos.fileUrl}
              />
            </Olympe.Tooltips>
          </p>
        )}

        {infos.fileUrl && (
          <p className="usrDetails">
            <Activity />
            <Olympe.Tooltips title="Dernière modification le">
              {Olympe.Utils.stringUpper(
                moment(infos.lastModified)
                  .format("dddd DD MMMM Y à H:s")
                  .replace(":", "h")
              )}
            </Olympe.Tooltips>
          </p>
        )}

        <p className="usrDetails">
          <Folder />
          <span>Utilisé {infos.used} fois dans le serveur.</span>
        </p>

        <p className="usrDetails">
          <Database />
          <Olympe.Tooltips title="Taille du fichier">
            {Olympe.Utils.getFileSize(infos.size)}
          </Olympe.Tooltips>
        </p>

        <p className="usrDetails">
          <Hash />
          <Olympe.Tooltips title="Identifiant du fichier">
            n° {infos.fileId}
          </Olympe.Tooltips>
        </p>

        <div className="footerInfos">
          <Olympe.Tooltips
            title="Revenir à la page précédente."
            classes="icon"
            placement="left"
          >
            <Olympe.Button.Basic
              toExecute={() => navigate(-1)}
              icon={<ArrowLeftCircle />}
            />
          </Olympe.Tooltips>

          <Olympe.Button.Basic
            label={edit ? "Modification..." : "Modifier"}
            icon={<Edit2 />}
            toExecute={
              !edit && fileName.length > 0 && fileName !== infos.fileName
                ? editDescFile
                : false
            }
          />

          <Olympe.Button.Basic
            label="Supprimer le fichier"
            icon={<Trash />}
            toExecute={() => setOpenDelete(true)}
            anim="danger"
          />
        </div>
      </ItemPdf>

      <ItemPdf>
        {infos.fileUrl
          ? (
            <>
              {infos.size > 3000000 && (
                <p className="pdfWaring">
                  <AlertTriangle />
                  <span>
                    Ce fichier est lourd ({">"} 3MB), est-il compressé ?
                  </span>
                </p>
              )}
              <iframe src={infos.fileUrl} frameBorder="0" height={700}></iframe>
            </>
          )
          : (
            <p className="errorFile">
              <AlertTriangle />
              <br />
              Ce fichier ne se trouve pas sur le serveur. Il a du être supprimé
              par erreur. Veuillez supprimer ce fichier invalide puis le recréer.
              <br />
              <br />
              Remplacez / supprimez le au plus vite pour éviter des perturbations
              aux utilisateurs.
            </p>
          )}
      </ItemPdf>
    </Olympe.WrapPdf>
  );
}
