import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import moment from "moment";
import {
  AlertTriangle,
  ArrowLeftCircle,
  Book,
  BookOpen,
  Calendar,
  CheckCircle,
  DollarSign,
  Edit,
  File,
  FileMinus,
  Image,
  MapPin,
  Package,
  PlusCircle,
  Trash,
  Users,
  Video,
  Watch
} from "react-feather";

import { MainContext } from "../datas/context";
import { axiosDelete, axiosGet, axiosPut } from "../datas/fetch";
import {
  ItemSeeFile,
  ItemSetup,
  ItemDeleteFile
} from "../components/StyledItem";
import { Olympe } from "dv-olympe";

export default function SetupsEdit () {
  const context = React.useContext(MainContext);
  const params = useParams();
  const navigate = useNavigate();

  const [infos, setInfos] = useState({});
  const [techs, setTechs] = useState([]);
  const [addrs, setAddrs] = useState([]);
  const [docu, setDocu] = useState([]);
  const [docuType, setDocuType] = useState("file");

  const { register, getValues, setValue, setFocus, watch } = useForm();

  const refreshInfos = async () => {
    axiosGet({
      apiKey: context.api.key,
      uri: `setups/?id=${params.fileId}&edit`
    }).then((data) => {
      if (!data.instId) {
        toast.error("Installation non trouvée.", { icon: "🔎" });
        navigate("/setups/");
        return;
      }
      setInfos(data);

      setTechs(
        data.instTech.split(", ").map((elt) => ({
          label: elt.trim(),
          value: elt
            .trim()
            .toLowerCase()
            .replace(/[^a-zA-Z0-9]+/g, "")
        }))
      );

      setAddrs({
        label: data.instAddr.trim(),
        value: data.instAddr
          .trim()
          .toLowerCase()
          .replace(/[^a-zA-Z0-9]+/g, "")
      });

      setDocu(data.documentation || []);
    });
  };

  useEffect(() => {
    if (!params.fileId) {
      toast.error("Installation non précisée.", { icon: <Package /> });
      navigate("/setups/");
    }

    watch([
      "fileDevis",
      "fileContrat",
      "fileFacture",
      "instDate",
      "instDuraH",
      "instDuraM",
      "instPrice",
      "docuName",
      "docuLink"
    ]);

    refreshInfos();
  }, [navigate]);

  const deleteFile = (type = "") => {
    const fileType =
      type === "contrat"
        ? "install_contrat"
        : type === "devis"
          ? "install_devis"
          : type === "facture"
            ? "install_facture"
            : null;

    if (!fileType) {
      console.error("Wrong file type.");
      return;
    }

    return axiosDelete({
      apiKey: context.api.key,
      uri: "setupFile/",
      opt: {
        instId: infos.instId,
        fileType
      }
    })
      .then((data) => {
        if (data !== "true") {
          throw new Error(data || "réponse serveur erronée.");
        }
        toast.success(`Le fichier de type "${type}" a bien été supprimé.`, {
          icon: <Trash />
        });
        refreshInfos();
      })
      .catch((e) => {
        toast.error(
          `Le fichier de type "${type}" n'a pas été supprimé : ${e.message}`
        );
      });
  };

  const sendModif = () => {
    const duration = (
      parseInt(getValues("instDuraH")) * 60 +
      parseInt(getValues("instDuraM"))
    ).toString();

    const date = getValues("instDate");
    const price = getValues("instPrice")?.replace(",", ".").toString();

    const tech = techs
      .map((elt, idx) => `${idx > 0 ? " " : ""}${elt.label.trim()}`)
      .toString();

    const addr = addrs.label;

    axiosPut({
      apiKey: context.api.key,
      uri: "setup/",
      opt: {
        instId: infos.instId,
        duration,
        price,
        date,
        tech,
        addr
      }
    })
      .then((data) => {
        if (data !== "true") {
          throw new Error(data || "réponse serveur erronée.");
        }
        toast.success("L'installation a bien été modifiée.", {
          icon: <Edit />
        });
        navigate(`/setups/${infos.clid}`);
      })
      .catch((e) => {
        toast.error(
          "Une erreur est survenue, l'installation n'a pas été modifiée : " +
            e.message
        );
      });
  };

  const deleteDocu = (link, idx) => {
    const tmp = [];

    for (let i = 0; i < docu.length; i++) {
      if (docu[i].link !== link && i !== idx) {
        tmp.push(docu[i]);
      }
    }

    sendDocu(tmp);
  };

  const addDocu = () => {
    const name = getValues("docuName");
    const link = getValues("docuLink");
    const type = docuType;

    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );

    if (name.length === 0) {
      toast.info("Veuillez entrer une description valide.");
      setFocus("docuName");
      return;
    }

    if (
      !link.startsWith("https://www.youtube.com/embed/") &&
      !pattern.test(link)
    ) {
      toast.info(
        "Impossible d'ajouter ce lien, vérifier qu'il pointe vers une image, un pdf ou une vidéo ou que ce soit un lien youtube \"embed\"."
      );
      setFocus("docuLink");
      return;
    }

    if (name.length === 0) {
      toast.error("Veuillez ajouter un nom à la nouvelle documentation.");
      return;
    } else if (link.length === 0) {
      toast.error("Veuillez ajouter un lien à la nouvelle documentation.");
      return;
    }

    sendDocu([...docu, { name, type, link }]);
    setValue("docuName", "");
    setValue("docuLink", "");
    setDocuType("file");
  };

  const sendDocu = (newDocu = []) => {
    setDocu(newDocu);

    axiosPut({
      apiKey: context.api.key,
      uri: "setupDocu",
      opt: {
        instId: infos.instId,
        docu: JSON.stringify(newDocu)
      }
    })
      .then((data) => {
        if (data !== "true") {
          throw new Error(data || "réponse serveur erronée.");
        }
        toast.success("La documentation a bien été modifiée.", {
          icon: <BookOpen />
        });
      })
      .catch((e) => {
        toast.error(`La documentation n'a pas été modifiée : ${e.message}`);
      })
      .finally(() => refreshInfos());
  };

  const getDocuType = (link = "") => {
    if (/\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(link)) setDocuType("image");
    else if (
      /\.(mp4|webm|mov|AVI)$/.test(link) ||
      link.includes("youtube.com")
    ) {
      setDocuType("video");
    } else setDocuType("file");
  };

  if (!infos.instId) return <Olympe.Loader />;

  return (
    <Olympe.Wrapper>
      <Olympe.SubItem>
        <ItemSetup>
          <Olympe.ItemTitle>
            <span>
              <Olympe.Tooltips
                title="Revenir à la page précédente."
                classes="icon"
                placement="left"
              >
                <Olympe.Button.Basic
                  toExecute={() => navigate(-1)}
                  icon={<ArrowLeftCircle />}
                />
              </Olympe.Tooltips>

              <span style={{ marginLeft: 20 }}>Installation</span>
            </span>
          </Olympe.ItemTitle>

          {!infos.product.name && (
            <p className="usrDetails">
              <AlertTriangle />
              <span>Cette installation est erronée.</span>
            </p>
          )}

          <div className="usrDetails">
            <Users />
            <CreatableSelect
              isMulti
              className="select"
              options={infos.list.emps}
              placeholder="Techniciens"
              defaultValue={techs}
              onChange={(t) => setTechs(t)}
              formatCreateLabel={(newVal) => `Ajouter : ${newVal}`}
              noOptionsMessage={() => "Aucun technicien trouvé 😬"}
            />
          </div>

          <div className="usrDetails">
            <MapPin />
            <CreatableSelect
              className="select"
              options={infos.list.addr}
              placeholder="Adresse de résidence"
              defaultValue={addrs}
              onChange={(t) => setAddrs(t)}
              noOptionsMessage={() => "Aucune adresse trouvée 😬"}
              formatCreateLabel={(newVal) => `Ajouter : ${newVal}`}
            />
          </div>

          <p className="usrDetails">
            <Watch />

            <Olympe.Tooltips title="Durée des travaux en heures">
              <Olympe.Input.Text
                type="number"
                min={0}
                step={1}
                max={1000}
                {...register("instDuraH")}
                defaultValue={Math.floor(Number(infos.instDura) / 60)}
                placeholder="h"
                autoComplete="off"
                onKeyPress={(e) => {
                  e.key === "Enter" && Olympe.Utils.enterInput(e);
                }}
              />
            </Olympe.Tooltips>

            <span className="timeSep">h</span>

            <Olympe.Tooltips title="Durée des travaux en minutes">
              <Olympe.Input.Text
                type="number"
                min={0}
                step={5}
                max={60}
                {...register("instDuraM")}
                defaultValue={Math.floor(Number(infos.instDura) % 60)}
                placeholder="min"
                autoComplete="off"
                onKeyPress={(e) => {
                  e.key === "Enter" && Olympe.Utils.enterInput(e);
                }}
              />
            </Olympe.Tooltips>
            <span className="timeSep">min.</span>
          </p>

          <p className="usrDetails">
            <DollarSign />

            <Olympe.Tooltips title="Prix total de l'installation en euros">
              <Olympe.Input.Text
                type="number"
                min={0}
                step={0.01}
                max={1000000}
                {...register("instPrice")}
                defaultValue={parseFloat(infos.instPrice?.replace(",", ".")) || 0}
                placeholder="Prix total"
                autoComplete="off"
                onKeyPress={(e) => {
                  e.key === "Enter" && Olympe.Utils.enterInput(e);
                }}
              />
            </Olympe.Tooltips>

            <span className="timeSep">€</span>
          </p>

          <p className="usrDetails">
            <Calendar />
            <Olympe.Tooltips title="Jour de la fin des travaux">
              <Olympe.Input.Text
                type="date"
                {...register("instDate")}
                defaultValue={infos.instDate}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.key === "Enter" && Olympe.Utils.enterInput(e);
                }}
              />
            </Olympe.Tooltips>
            <span className="fullDate">
              {getValues("instDate") &&
                getValues("instDate").length > 0 &&
                moment(getValues("instDate")).format("ddd D MMMM Y")}
            </span>
          </p>

          <div className="footerInfos">
            <Olympe.Button.Basic
              label="Valider"
              icon={<CheckCircle />}
              toExecute={sendModif}
            />
          </div>
        </ItemSetup>
      </Olympe.SubItem>

      <Olympe.SubItem>
        <ItemSeeFile>
          <Olympe.ItemTitle>
            <span>
              <BookOpen /> Documentation
            </span>
          </Olympe.ItemTitle>

          <p>
            Voici la liste des images et vidéos reliées à cette installation.
            Elle peut être modifiée à tout moment, dans la section{" "}
            <i>Modifier l'installation</i>. Elle est uniquement visible sur les
            comptes particuliers.
          </p>

          {docu && docu.length > 0
            ? (
              <div className="links">
                {docu.map((elt, idx) => (
                  <div className="link" key={`${idx}-${elt.link}`}>
                    <p className="title">
                      {elt.type === "image"
                        ? (
                          <Image />
                        )
                        : elt.type === "video"
                          ? (
                            <Video />
                          )
                          : (
                            <File />
                          )}{" "}
                      <span className="desc">
                        {elt.name.startsWith("ERROR")
                          ? (
                            <>
                              <AlertTriangle /> {elt.name.replace("ERROR", "")}
                            </>
                          )
                          : (
                            elt.name
                          )}
                      </span>
                    </p>
                    <Olympe.Button.Basic
                      icon={<Trash />}
                      anim="danger"
                      toExecute={() => deleteDocu(elt.link, idx)}
                    />
                  </div>
                ))}
              </div>
            )
            : (
              <h3>
                <Book /> Aucune documentation
              </h3>
            )}

          <div className="addDocu">
            <div className="fields">
              <Olympe.Input.Text
                type="text"
                {...register("docuName")}
                placeholder="Description de la documentation"
                defaultValue={""}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.key === "Enter" && Olympe.Utils.enterInput(e);
                }}
              />
              <Olympe.Input.Text
                type="text"
                {...register("docuLink")}
                placeholder="Lien vers l'image ou la vidéo"
                defaultValue={""}
                autoComplete="off"
                onChange={(e) => getDocuType(e.target.value)}
                onKeyPress={(e) => {
                  e.key === "Enter" && Olympe.Utils.enterInput(e);
                }}
              />
            </div>

            <span>
              {docuType === "image"
                ? (
                  <Image />
                )
                : docuType === "video"
                  ? (
                    <Video />
                  )
                  : (
                    <File />
                  )}
            </span>

            <Olympe.Button.Basic
              label="Ajouter"
              icon={<PlusCircle />}
              toExecute={addDocu}
            />
          </div>
        </ItemSeeFile>
      </Olympe.SubItem>

      <Olympe.SubItem>
        <ItemDeleteFile clr={context.clr}>
          <Olympe.ItemTitle>
            <span>
              <FileMinus /> Supprimer des documents
            </span>
          </Olympe.ItemTitle>

          <p>
            Supprimer des documents est immédiat ; leur lien avec l'installation
            est brisé et le fichier est supprimé du serveur.
          </p>

          <div className="content">
            {infos.contratId && (
              <Olympe.Button.Basic
                label="Supprimer le contrat"
                icon={<Trash />}
                anim="danger"
                toExecute={() => deleteFile("contrat")}
              />
            )}

            {infos.devisId && (
              <Olympe.Button.Basic
                label="Supprimer le devis"
                icon={<Trash />}
                anim="danger"
                toExecute={() => deleteFile("devis")}
              />
            )}

            {infos.factureId && (
              <Olympe.Button.Basic
                label="Supprimer la facture"
                icon={<Trash />}
                anim="danger"
                toExecute={() => deleteFile("facture")}
              />
            )}

            {!infos.contratId && !infos.devisId && !infos.factureId && (
              <h3>Aucun document relié</h3>
            )}
          </div>
        </ItemDeleteFile>
      </Olympe.SubItem>
    </Olympe.Wrapper>
  );
}
