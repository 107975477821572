import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { FaNewspaper } from "react-icons/fa";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Filler
} from "chart.js";
import {
  Archive,
  BookOpen,
  Calendar,
  Check,
  Home,
  Image,
  PlusCircle,
  XCircle
} from "react-feather";
import { Doughnut, Line, Pie } from "react-chartjs-2";
import { useForm } from "react-hook-form";
import Select from "react-select";
import $ from "jquery";
import moment from "moment";

import { MainContext } from "../datas/context";
import { axiosDelete, axiosGet, axiosPost, axiosPut } from "../datas/fetch";
import { Olympe } from "dv-olympe";
import {
  ActuList,
  MainActu,
  WelcomeMessage
} from "../components/StyledElements";

export default function ApplicationPage () {
  const context = useContext(MainContext);
  const [datas, setDatas] = useState([]);
  const [mainLoad, setMainLoad] = useState(false);
  const [actuLoad, setActuLoad] = useState(false);
  const [delLoad, setDelLoad] = useState(false);
  const [logo, setLogo] = useState({});

  const [actuId, setActuId] = useState(false);
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);

  const {
    register,
    reset,
    setFocus,
    setValue,
    getValues,
    watch,
    formState: { dirtyFields }
  } = useForm();

  useEffect(() => {
    const refreshInfos = async () => {
      axiosGet({
        apiKey: context.api.key,
        uri: "actus/"
      })
        .then((data) => {
          if (!data) {
            throw new Error("Données d'application erronées.");
          }
          setDatas(data);
        })
        .catch((e) => {
          toast.error(
            `Chargement des données impossible, veuillez réessayer plus tard : ${e.message}`,
            {
              autoClose: false
            }
          );
        });
    };

    refreshInfos();
    const datasInterval = setInterval(() => refreshInfos(), 3000);

    watch(["mainTitle", "mainDesc"]);

    return () => {
      clearInterval(datasInterval);
    };
  }, []);

  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    Filler,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement
  );

  if (!datas.actus) return <Olympe.Loader />;

  const doughnutData = {
    labels: [" Utilisateurs ", " Invités "],
    datasets: [
      {
        label: "",
        data: [datas.infos.act_user, datas.infos.act_guest],
        backgroundColor: [context.clr.blue, context.clr.light],
        borderWidth: 0
      }
    ]
  };

  let gradient = null;
  let prevGradient = null;

  if ($(".lineChart")[0]) {
    const ctx = $(".lineChart")[0].getContext("2d");

    gradient = ctx.createLinearGradient(0, 25, 0, 300);
    gradient.addColorStop(0, `${context.clr.blue}70`);
    gradient.addColorStop(0.35, `${context.clr.blue}25`);
    gradient.addColorStop(1, `${context.clr.blue}00`);

    prevGradient = ctx.createLinearGradient(0, 25, 0, 300);
    prevGradient.addColorStop(0, `${context.clr.grey}30`);
    prevGradient.addColorStop(0.35, `${context.clr.grey}15`);
    prevGradient.addColorStop(1, `${context.clr.grey}00`);
  }

  const barData = {
    labels: [
      "Connexions",
      "Actualités",
      "Installations",
      "Rendez-vous",
      "Compte",
      "Liens"
    ],
    datasets: [
      {
        label: " Période en cours ",
        data: [
          datas.infos.act_cur_log,
          datas.infos.act_cur_act,
          datas.infos.act_cur_set,
          datas.infos.act_cur_mee,
          datas.infos.act_cur_acc,
          datas.infos.act_cur_lin
        ],
        backgroundColor: gradient || context.clr.blue,
        borderColor: `${context.clr.blue}`,
        pointBackgroundColor: `${context.clr.blue}`,
        lineTension: 0.4,
        borderWidth: 2,
        pointRadius: 3,
        fill: !!gradient
      },
      {
        label: " Période précédente ",
        data: [
          datas.infos.act_prv_log,
          datas.infos.act_prv_act,
          datas.infos.act_prv_set,
          datas.infos.act_prv_mee,
          datas.infos.act_prv_acc,
          datas.infos.act_prv_lin
        ],
        backgroundColor: prevGradient || context.clr.grey,
        borderColor: `${context.clr.grey}`,
        pointBackgroundColor: `${context.clr.grey}`,
        lineTension: 0.4,
        borderWidth: 2,
        pointRadius: 3,
        fill: !!prevGradient
      }
    ]
  };

  const pieData = {
    labels: [
      " Mon compte ",
      " Mes rendez-vous ",
      " Messagerie ",
      " Page d'accueil ",
      " Mes installations "
    ],
    datasets: [
      {
        label: " Pages principales ",
        data: [
          datas.pages.account,
          datas.pages.calendar,
          datas.pages.chat,
          datas.pages.home,
          datas.pages.setup
        ],
        backgroundColor: [
          "#B895C6",
          "#DFBAB2",
          "#769DBE",
          "#7CBEBB",
          "#DECEB8",
          "#6182ae"
        ],
        borderColor: `${context.clr.light}`,
        borderWidth: 1
      }
    ]
  };

  const submitMainPage = () => {
    setMainLoad(true);
    const title = getValues("mainTitle");
    const desc = getValues("mainDesc");

    axiosPut({
      uri: "main_page",
      apiKey: context.api.key,
      opt: {
        title,
        desc
      }
    })
      .then((data) => {
        if (data !== "true") {
          throw new Error(data || "requête serveur erronée");
        }

        toast.success(
          "Les informations de la page d'accueil ont bien été modifiées."
        );

        reset({
          mainTitle: title,
          mainDesc: desc
        });
      })
      .catch((e) => {
        toast.error(
          "Une erreur est survenue, les informations de la page d'accueil n'ont pas été modifiées : " +
            e.message
        );
      })
      .finally(() => setMainLoad(false));
  };

  const submitnewActu = () => {
    setActuLoad(true);
    const title = getValues("newActuTitle");
    const desc = getValues("newActuDesc");

    if (title.length === 0) {
      toast.info("Veuillez entrer un titre valide pour l'actualité.");
      setFocus("newActuTitle");
      return;
    }

    if (desc.length === 0) {
      toast.info("Veuillez entrer une description valide pour l'actualité.");
      setFocus("newActuDesc");
      return;
    }

    axiosPost({
      uri: "actualite",
      apiKey: context.api.key,
      opt: {
        title,
        desc,
        logo: logo.label || ""
      }
    })
      .then((data) => {
        if (data !== "true") {
          throw new Error(data || "requête serveur erronée");
        }

        toast.success("L'actualité a bien été ajoutée.");

        setValue("newActuTitle", "");
        setValue("newActuDesc", "");
        setLogo([]);
      })
      .catch((e) => {
        toast.error(
          "Une erreur est survenue, l'actualité n'a pas été ajoutée : " +
            e.message
        );
      })
      .finally(() => setActuLoad(false));
  };

  const deleteActu = () => {
    const id = actuId;
    if (id === -1) {
      toast.error(
        "Aucun identifiant d'actualité reçu. Contactez l'administrateur si l'erreur persiste."
      );
    }

    setDelLoad(true);

    axiosDelete({
      uri: "actualite/",
      apiKey: context.api.key,
      opt: { id }
    })
      .then((data) => {
        if (data !== "true") {
          throw new Error(data || "requête serveur erronée");
        }

        toast.success("L'actualité a bien été archivée.", {
          icon: <Archive />
        });
      })
      .catch((e) => {
        toast.error(
          "Une erreur est survenue, l'actualité n'a pas été archivée : " +
            e.message
        );
      })
      .finally(() => {
        setDelLoad(false);
        setOpen(false);
      });
  };

  const renderActu = (actu) => (
    <ActuList key={`${actu.id}-${actu.date}`}>
      <p>
        {Olympe.Utils.truncStr({ str: actu.title, len: 70 })}{" "}
        <span className="footer">
          <Olympe.Tooltips
            title={`Actualité mise en ligne le ${moment(actu.date).format(
              "LL"
            )}.`}
            placement="bottom"
          >
            <Calendar /> {moment(actu.date).format("LL")}
          </Olympe.Tooltips>
          -
          <Olympe.Tooltips
            title={`Cette actualité a été ouverte ${actu.count || 0} fois.`}
            placement="bottom"
          >
            <BookOpen /> {Olympe.Utils.numberShorter(actu.count || 0)}
          </Olympe.Tooltips>
        </span>
      </p>
      {delLoad
        ? (
          <Olympe.MiniLoader />
        )
        : (
          <Olympe.Button.Basic
            label="Archiver"
            icon={<Archive />}
            toExecute={() => {
              setActuId(actu.id);
              setOpen(true);
            }}
          />
        )}
    </ActuList>
  );

  return (
    <Olympe.Wrapper>
      <Olympe.Modal
        open={open}
        loading={delLoad}
        onClose={closeModal}
        title="Archiver l'actualité"
        content={
          <>
            Une fois archivée, cette actualité ne sera plus visible depuis
            l'application ; elle pourra être restaurée si besoin par un
            administrateur.
          </>
        }
        buttons={[
          {
            isCancel: true,
            label: "Annuler",
            icon: <XCircle />,
            exec: closeModal
          },
          {
            isDanger: true,
            label: "Archiver",
            icon: <Archive />,
            exec: deleteActu
          }
        ]}
      />

      <Olympe.SubItem>
        <Olympe.Chart
          title="Actions"
          subtitle="Nombre total d'actions réalisées"
          chartTitle={Olympe.Utils.numberShorter(
            Math.floor(Number(datas.infos?.act_user) + Number(datas.infos?.act_guest))
          )}
          chartLegend={
            <>
              <div className="legend">
                <span
                  className="square"
                  style={{ backgroundColor: context.clr.blue }}
                />
                Utilisateurs connectés
              </div>

              <div className="legend">
                <span
                  className="square"
                  style={{ backgroundColor: context.clr.light }}
                />
                Mode invité
              </div>
            </>
          }
          chart={
            <Doughnut
              data={doughnutData}
              options={{
                responsive: true,
                plugins: { legend: { display: false } },
                cutout: 62
              }}
            />
          }
        />

        <Olympe.Chart
          title="Visite des pages"
          subtitle="Nombre d'ouverture de pages principales"
          chartLegend={
            <div className="legend lonely">
              Ces données sont regroupées parmis tous les comptes depuis 365
              jours.
            </div>
          }
          chart={
            <Pie
              data={pieData}
              options={{
                responsive: true,
                plugins: { legend: { display: false } }
              }}
            />
          }
        />
      </Olympe.SubItem>

      <Olympe.SubItem>
        <Olympe.Chart
          large
          title="Toutes les actions"
          subtitle="Nombre d'actions réalisées ces 30 derniers jours comparé à la période précédente."
          chartLegend={
            <>
              <div className="legend">
                <span
                  className="square"
                  style={{ backgroundColor: context.clr.blue }}
                />
                30 derniers jours
              </div>

              <div className="legend">
                <span
                  className="square"
                  style={{ backgroundColor: context.clr.light }}
                />
                Période précédente
              </div>
            </>
          }
          chart={
            <Line
              className="lineChart"
              data={barData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false
                  }
                },
                scales: {
                  x: {
                    ticks: {
                      color: context.clr.grey,
                      padding: 10,
                      autoSkip: false
                    },
                    grid: {
                      color: "rgba(255, 255, 255, 0.2)"
                    }
                  },
                  y: {
                    ticks: {
                      color: context.clr.grey,
                      stepSize: Math.ceil((123 / 3 + 1) / 10) * 10
                    },
                    grid: {
                      color: "rgba(255, 255, 255, 0.2)"
                    }
                  }
                }
              }}
            />
          }
        />
      </Olympe.SubItem>

      <Olympe.SubItem>
        <WelcomeMessage>
          <Olympe.ItemTitle>
            <span>
              <Home /> Message d'accueil
            </span>
          </Olympe.ItemTitle>

          <div className="welcomeWrapper">
            <div className="basicInput">
              <Olympe.Input.Text
                type="text"
                centered
                {...register("mainTitle")}
                defaultValue={datas.main.title}
                autoComplete="off"
                placeholder="Titre principal"
                onKeyPress={(e) => {
                  e.key === "Enter" && Olympe.Utils.enterInput(e);
                }}
              />
            </div>

            <span className="appSep" />

            <div className="usrDetails">
              <textarea
                {...register("mainDesc")}
                defaultValue={datas.main.body}
                placeholder="Ce message sera affiché comme principale description de la page d'accueil."
              />
            </div>
          </div>

          <div className="footerInfos">
            {mainLoad
              ? (
                <Olympe.MiniLoader />
              )
              : (
                <Olympe.Button.Basic
                  label="Valider"
                  icon={<Check />}
                  toExecute={
                    dirtyFields.mainTitle || dirtyFields.mainDesc
                      ? submitMainPage
                      : false
                  }
                />
              )}
          </div>
        </WelcomeMessage>
      </Olympe.SubItem>

      <Olympe.SubItem>
        <Olympe.ItemFullWidth>
          <Olympe.ItemTitle>
            <span>
              <FaNewspaper /> Gérer les actualités
            </span>
          </Olympe.ItemTitle>

          {datas.actus.length === 0 && <p>Aucune actualité encore en ligne.</p>}
          {datas.actus.map((elt) => renderActu(elt))}
        </Olympe.ItemFullWidth>
      </Olympe.SubItem>

      <Olympe.SubItem>
        <Olympe.ItemFullWidth>
          <Olympe.ItemTitle>
            <span>
              <PlusCircle /> Nouvelle actualité
            </span>
          </Olympe.ItemTitle>

          <MainActu>
            <div className="basicInput actuTitle">
              <Olympe.Input.Text
                type="text"
                {...register("newActuTitle")}
                defaultValue=""
                autoComplete="off"
                placeholder="Titre principal"
                onKeyPress={(e) => {
                  e.key === "Enter" && Olympe.Utils.enterInput(e);
                }}
              />
            </div>

            <div className="usrDetails actuImg">
              <Image />
              <Select
                className="select"
                options={datas.imgs || []}
                placeholder="Image (optionnelle)"
                defaultValue={[]}
                onChange={(t) => setLogo(t)}
                noOptionsMessage={() => "Aucune image trouvée 😬"}
              />
            </div>
          </MainActu>

          <div className="usrDetails">
            <textarea
              {...register("newActuDesc")}
              defaultValue=""
              placeholder="Ce message sera affiché comme description de l'actualité."
            />
          </div>

          <div className="footerInfos">
            {actuLoad
              ? (
                <Olympe.MiniLoader />
              )
              : (
                <Olympe.Button.Basic
                  label="Ajouter"
                  icon={<PlusCircle />}
                  toExecute={
                    dirtyFields.newActuTitle && dirtyFields.newActuDesc
                      ? submitnewActu
                      : false
                  }
                />
              )}
          </div>
        </Olympe.ItemFullWidth>
      </Olympe.SubItem>
    </Olympe.Wrapper>
  );
}
