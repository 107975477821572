import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { axiosDelete, axiosGet, axiosPost, axiosPut } from "../datas/fetch";
import { toast } from "react-toastify";
import $ from "jquery";
import moment from "moment";
import {
  AlertTriangle,
  BellOff,
  Check,
  Edit2,
  EyeOff,
  MessageCircle,
  Search,
  Send,
  Trash,
  XCircle
} from "react-feather";

import { MainContext } from "../datas/context";
import { ChatStyled, ConvStyled, SquareAnim } from "../components/StyledChat";
import { Olympe } from "dv-olympe";

export default function ChatView () {
  const context = React.useContext(MainContext);
  const params = useParams();
  const [loading, setLoad] = useState(!!params.usrId);
  const [curEdit, setCurEdit] = useState(null);
  const [unRead, setRead] = useState(false);
  const [edit, setEdit] = useState(false);
  const [infos, setInfos] = useState([]);
  const [chats, setChats] = useState([]);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [textArea, setTextArea] = useState("");
  const [areaEdit, setAreaEdit] = useState("");
  const [editVerif, setEditVerif] = useState("");

  const bottomRef = useRef(null);
  const textAreaRef = useRef(null);
  const textAreaRef2 = useRef(null);

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);

  useEffect(() => {
    refreshInfos();
    const datasInterval = setInterval(() => refreshInfos(), 2000);
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });

    return () => {
      clearInterval(datasInterval);
    };
  }, [navigate, chats, unRead]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chats]);

  useEffect(() => {
    if (textAreaRef.current) {
      const scrollHeight = textAreaRef.current.scrollHeight;

      if (textArea.length === 0) $(textAreaRef.current).removeAttr("style");
      else $(textAreaRef.current).css("height", scrollHeight + "px");
    }
  }, [textAreaRef, textArea]);

  useEffect(() => {
    if (textAreaRef2.current) {
      const scrollHeight = textAreaRef2.current.scrollHeight;

      if (areaEdit.length === 0) $(textAreaRef2.current).removeAttr("style");
      else $(textAreaRef2.current).css("height", scrollHeight + "px");
    }
  }, [textAreaRef2, areaEdit]);

  const refreshInfos = async () => {
    axiosGet({
      apiKey: context.api.key,
      uri: "chats/?item=all"
    }).then((data) => {
      setUsers(data);

      if (params.usrId && !unRead) {
        axiosGet({
          apiKey: context.api.key,
          uri: `chats/?item=${params.usrId}`
        }).then((data) => {
          if (!data.id) {
            toast.error("Utilisateur non trouvé.", { icon: "🔎" });
            navigate("/accounts/");
            return;
          }

          setInfos(data);

          if (loading) setLoad(false);

          if (chats[0] && data.id === infos.id) {
            if (data.chats.length !== chats.length) {
              setChats(data.chats);
            } else {
              for (let i = 0; i < data.chats.length; i++) {
                if (
                  data.chats[i].text !== chats[i].text ||
                  data.chats[i].seen !== chats[i].seen
                ) {
                  setChats(data.chats);
                }
              }
            }
          } else setChats(data.chats);
        });
      }
    });
  };

  const filteredFilesUser = () => {
    const tmp = {
      id: null,
      name: null
    };

    if (params.usrId) {
      for (let i = 0; i < users.length; i++) {
        if (users[i] && users[i].id === params.usrId) {
          tmp.id = users[i].id;
          tmp.name = users[i].name;
          break;
        }
      }
    }

    return tmp;
  };

  const chatDate = ({ date = moment(), chat = true }) => {
    if (moment().isSame(date, "day")) {
      if (chat) return "Aujourd'hui";
      else {
        if (moment().diff(date, "hour") > 4) {
          return `${moment(date).format("HH:mm").replace(":", "h")}`;
        } else return `~ ${Olympe.Utils.SentLogTime(date, true)}`;
      }
    } else if (moment().subtract(1, "day").isSame(date, "day")) {
      return "Hier";
    } else if (moment().subtract(2, "days").isSame(date, "day")) {
      return "Avant-hier";
    } else {
      if (!chat) {
        return moment().isSame(date, "year")
          ? moment(date).format("DD MMM")
          : moment(date).format("DD MMM YYYY");
      }
      return moment(date).format("LL");
    }
  };

  const createChat = () =>
    chats[0] &&
    chats.map((chat, idx) => {
      const regexEmojis =
        /^(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])+$/;
      let classes = chat.clid === "mayansenergies" ? "from-me" : "from-them";

      const newDay =
        idx > 0 && moment(chat.date).isAfter(chats[idx - 1].date, "day");

      if (chats[idx + 1]) {
        if (
          chats[idx + 1].clid === chat.clid &&
          !moment(chat.date).isBefore(chats[idx + 1].date, "day")
        ) {
          classes += " no-tail";
        }
        if (
          chats[idx + 1].clid !== chat.clid &&
          idx > 0 &&
          idx < chats.length
        ) {
          classes += " margin-b_one";
        }
      }
      if (regexEmojis.test(chat.text)) classes += " emoji";
      if (chat.clid === "system") classes = "from-system";

      const footer = (
        <>
          {moment(chat.date).format("HH:mm").replace(":", "h")}
          {chat.clid === "mayansenergies" && chat.seen && (
            <Olympe.Tooltips title="Message lu." placement="bottom">
              <Check />
            </Olympe.Tooltips>
          )}
          {chat.clid === "mayansenergies" && !chat.seen && (
            <button
              type="button"
              onClick={() => {
                setOpen(true);
                setCurEdit(chat.id);
                setAreaEdit(chat.text);
                setEditVerif(chat.text);
              }}
            >
              <Edit2 />
            </button>
          )}
        </>
      );

      return (
        <React.Fragment key={`${idx}-${chat.date}`}>
          {(idx === 0 || newDay) && (
            <span className="newDay">{chatDate({ date: chat.date })}</span>
          )}
          <p className={classes}>
            {chat.text.split("\n").map((str, idx) => (
              <React.Fragment key={`${idx}-${str}`}>
                {idx > 0 && <br />}
                {str}
              </React.Fragment>
            ))}
            <span className="date">
              {chat.clid === "system" ? "~ Système" : footer}
            </span>
          </p>
        </React.Fragment>
      );
    });

  const handleSearch = (event) => setSearch(event.target.value);
  const handleChangeTextArea = (event) => setTextArea(event.target.value);
  const handleChangeEditArea = (event) => setAreaEdit(event.target.value);

  const handleSubmit = () => {
    if (textArea.length === 0) {
      toast.error(
        "Le message n'est pas assez long, il doit faire au moins 1 caractère."
      );
      return;
    }

    axiosPost({
      apiKey: context.api.key,
      uri: "chat/",
      opt: {
        message: textArea,
        chatBdd: `app_chat_${params.usrId}`
      }
    })
      .then(async (data) => {
        if (
          data !== "true" &&
          data !== "Message envoyé, mais sans notification push."
        ) {
          throw new Error(data || "réponse serveur erronnée.");
        }
        setTextArea("");
        $(textAreaRef.current).removeAttr("style");
        if (data === "true") {
          toast.success(`Le message a bien été envoyé à ${infos.name}`, {
            icon: <Send />
          });
        } else {
          toast.success(
            `Le message a bien été envoyé à ${infos.name}, en mode silencieux.`,
            {
              icon: <BellOff />
            }
          );
        }
      })
      .catch((e) => {
        toast.error("Le message n'a pas été envoyé : " + e.message);
      })
      .finally(refreshInfos);
  };

  const handleEdit = () => {
    setEdit(true);
    axiosPut({
      apiKey: context.api.key,
      uri: "chat/?item=edit",
      opt: {
        message: areaEdit,
        chatId: curEdit,
        chatBdd: `app_chat_${params.usrId}`
      }
    })
      .then(async (data) => {
        if (data !== "true") {
          throw new Error(data || "réponse serveur erronnée.");
        }
        setAreaEdit("");
        setEditVerif("");
        setEdit(false);
        closeModal();

        $(textAreaRef2.current).removeAttr("style");
        toast.success("Le message a bien été modifié", {
          icon: <Edit2 />
        });
      })
      .catch((e) => {
        toast.error("Le message n'a pas été modifié : " + e.message);
        setEdit(false);
      })
      .finally(refreshInfos);
  };

  const handleDelete = () => {
    setEdit(true);
    axiosDelete({
      apiKey: context.api.key,
      uri: "chat/",
      opt: {
        chatId: curEdit,
        chatBdd: `app_chat_${params.usrId}`
      }
    })
      .then(async (data) => {
        if (data !== "true") {
          throw new Error(data || "réponse serveur erronnée.");
        }
        setAreaEdit("");
        setEditVerif("");
        setEdit(false);
        closeModal();

        $(textAreaRef2.current).removeAttr("style");
        toast.success("Le message a bien été supprimé.", {
          icon: <Trash />
        });
      })
      .catch((e) => {
        toast.error("Le message n'a pas été supprimé : " + e.message);
        setEdit(false);
      })
      .finally(refreshInfos);
  };

  const setUnRead = () => {
    setRead(true);
    axiosPut({
      apiKey: context.api.key,
      uri: "chat/?item=unread",
      opt: {
        clid: params.usrId,
        chatBdd: `app_chat_${params.usrId}`
      }
    })
      .then(async (data) => {
        if (data !== "true") {
          throw new Error(data || "réponse serveur erronnée.");
        }
        toast.success(
          `La conversation de ${infos.name} a bien été marquée comme non lue.`,
          {
            icon: <EyeOff />
          }
        );
        navigate("/chats/");
        setRead(false);
      })
      .catch((e) => {
        setRead(false);
        toast.error(
          `La conversation de ${infos.name} n'a pas été marquée comme non lue : ${e.message}`
        );
      });
  };

  const filteredDatas = () => {
    const ret = [];
    const tmp = users
      .filter(
        (obj) =>
          obj.id.toLowerCase().includes(search.toLowerCase()) ||
          obj.name.toLowerCase().includes(search.toLowerCase()) ||
          obj.last.toLowerCase().includes(search.toLowerCase())
      )
      .sort((a, b) => b.unread - a.unread)
      .sort((a, b) => (a.time < b.time ? 1 : -1));

    for (let i = 0; i < tmp.length; i++) {
      ret.push({
        id: tmp[i].id,
        name: tmp[i].name,
        desc: (
          <>
            {tmp[i].time === "-1"
              ? (
                <AlertTriangle />
              )
              : tmp[i].time === "-2"
                ? (
                  false
                )
                : (
                  `${chatDate({
                    date: tmp[i].time,
                    chat: false
                  })} ● ${Olympe.Utils.truncStr({
                    str: tmp[i].last,
                    len: 20
                  })}`
                )}
          </>
        ),
        badge: tmp[i].unread
      });
    }
    return ret;
  };

  if (!users[0]) return <Olympe.Loader />;

  return (
    <Olympe.Wrapper horizontal>
      <Olympe.Modal
        open={open}
        loading={edit}
        onClose={closeModal}
        title="Modifier le message"
        content={
          <>
            <p>
              Laissez le message vide pour le supprimer, sinon entrez sa
              nouvelle valeur.
            </p>
            <textarea
              ref={textAreaRef2}
              value={areaEdit}
              onChange={handleChangeEditArea}
              rows={1}
              placeholder="Votre message"
            />
          </>
        }
        buttons={[
          {
            isCancel: true,
            label: "Annuler",
            icon: <XCircle />,
            exec: closeModal
          },
          {
            isDanger: areaEdit.length === 0,
            label: areaEdit.length === 0 ? "Supprimer" : "Modifier",
            icon: areaEdit.length === 0 ? <Trash /> : <Edit2 />,
            exec:
              areaEdit.length === 0
                ? handleDelete
                : editVerif !== "" && areaEdit === editVerif
                  ? false
                  : handleEdit
          }
        ]}
      />

      <Olympe.SubItem>
        <Olympe.List.Menu
          uri="chats"
          label={
            <>
              <MessageCircle /> Messageries
            </>
          }
          placeholder="Utilisateur, identifiant"
          handleSearch={handleSearch}
          search={search}
          content={filteredDatas()}
        />

        <ConvStyled clr={context.clr}>
          {params.usrId
            ? (
              <>
                <div className="headerWrap">
                  <Link className="header" to={`/accounts/${params.usrId}`}>
                    {Olympe.Utils.renderHTML(
                      Olympe.Utils.AvatarSvg({
                        seed: filteredFilesUser().name
                      }),
                      "usrLogo"
                    )}
                    <h2>{filteredFilesUser().name}</h2>
                  </Link>

                  {unRead
                    ? (
                      <Olympe.MiniLoader />
                    )
                    : (
                      <Olympe.Button.Basic
                        label="Marquer comme non lu"
                        icon={<EyeOff />}
                        toExecute={
                          !infos.chats ||
                      (infos.chats.length === 1 &&
                        infos.chats[0].clid === "system")
                            ? null
                            : setUnRead
                        }
                      />
                    )}
                </div>

                <ChatStyled clr={context.clr}>
                  {loading || params.usrId !== infos.id
                    ? (
                      <Olympe.Loader show={true} />
                    )
                    : (
                      createChat()
                    )}
                  <div ref={bottomRef} />
                </ChatStyled>

                <div className="footer">
                  <textarea
                    ref={textAreaRef}
                    value={textArea}
                    onChange={handleChangeTextArea}
                    rows={1}
                    placeholder="Votre message"
                  />
                  <Olympe.Button.Basic
                    label="Envoyer"
                    icon={<Send />}
                    toExecute={textArea.length === 0 ? null : handleSubmit}
                  />
                </div>
              </>
            )
            : (
              <SquareAnim className="empty">
                <Search /> Veuillez sélectionner un utilisateur.
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </SquareAnim>
            )}
        </ConvStyled>
      </Olympe.SubItem>
    </Olympe.Wrapper>
  );
}
