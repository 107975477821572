import styled from "styled-components";
import { Olympe } from "dv-olympe";

export const ItemFile = styled(Olympe.Item)`
  width: 100%;
  max-width: 100%;
  overflow: hidden;

  input[type="file"] {
    display: none;
  }

  .loadFile {
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
      color: ${({ clr = Olympe.Color }) => clr.grey};

      @media screen and (max-width: 700px) {
        font-size: 12px;
        text-align: center;
      }
    }
  }
`;

export const ItemHistory = styled(Olympe.ItemFullWidth)`
  header {
    @media screen and (max-width: 480px) {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding: 0;
    }

    .search {
      max-width: 100%;
      min-width: 200px;

      @media screen and (max-width: 480px) {
        min-width: 80%;
        max-width: 80%;
      }
    }
  }

  .emptyTable {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: 700px) {
      padding: 15px;

      svg {
        width: 15px;
      }
    }

    h1 {
      margin-left: 10px;
      font-size: 1.1rem;

      @media screen and (max-width: 480px) {
        font-size: 0.8rem;
      }
    }
  }

  .logsTable {
    border-radius: 10px;
    padding: 10px;

    header {
      padding: 0;
    }

    > div {
      height: auto;
    }

    .rdt_Table {
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.1);

      .rdt_TableHead {
        width: 100%;

        span {
          font-size: 10px;
          margin-left: 10px;
        }

        .rdt_TableHeadRow {
          border: 1px solid rgba(255, 255, 255, 0.1);
          overflow: hidden;
          background: rgba(255, 255, 255, 0.1);
        }
      }
    }

    .rdt_Pagination {
      border-top: none;
    }

    select {
      outline: none;
    }

    .tableTitle {
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      svg {
        width: 16px;
        height: 16px;
        margin-right: 10px;
      }
      .success {
        color: ${({ clr = Olympe.Color }) => clr.blueLight};
      }

      .error {
        color: ${({ clr = Olympe.Color }) => clr.tomato};
      }
    }

    .logsWrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
      padding: 30px 20px;
      width: 100%;
      overflow: hidden;

      .logsContainer {
        padding: 20px;
        border-radius: 25px;
        text-align: center;
        max-width: 40%;

        background-color: rgba(0, 0, 0, 0.3);
        border: 1px solid rgba(255, 255, 255, 0.2);

        h3 {
          font-size: 1.1rem;
        }

        h4 {
          font-size: 0.8rem;
          color: ${({ clr = Olympe.Color }) => clr.grey};
          word-break: break-word;

          a {
            color: ${({ clr = Olympe.Color }) => clr.light};
          }
        }

        .logsDetails {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          color: ${({ clr = Olympe.Color }) => clr.blueLight};
          padding: 5px 0px;

          svg {
            width: 20px;
          }

          span {
            font-size: 0.9rem;
            color: ${({ clr = Olympe.Color }) => clr.light};
            word-break: break-word;
            white-space: break-spaces;

            &.onlyText {
              text-align: left;
              margin: 0 7px;
            }

            &.logsDetailsTitle {
              margin: 0 7px;
            }
          }
        }
      }

      @media screen and (max-width: 700px) {
        flex-direction: column;
        justify-content: center;
        padding: 5px;
        max-width: 100%;
        overflow-x: hidden;

        .logsContainer {
          max-width: 100%;
          margin: 5px 0;

          .logsDetails {
            flex-direction: column;
          }
        }
      }
    }
  }
`;

export const ItemAddr = styled(Olympe.ItemFullWidth)`
  .selectNbWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    p {
      margin: 0;
      padding: 0;
    }

    .selectNb {
      border: none;
      border-radius: 0;
      width: 60px;
      padding: 3px 10px;
      margin: 5px;
      align-self: flex-end;

      border-bottom: 2px solid ${({ clr = Olympe.Color }) => clr.blueLight};
      outline: none;
      background-color: transparent;
      color: ${({ clr = Olympe.Color }) => clr.light};

      &:focus {
        box-shadow: none;
      }
    }
  }

  #addrListRef {
    svg {
      align-self: flex-start;
      margin-top: 10px;
    }

    @media screen and (max-width: 700px) {
      .usrDetails > svg {
        display: none;
      }
    }

    .addrInput {
      margin: 5px 0;
      width: 100%;

      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;

      .codeDesc {
        margin: 0 5px;
        font-size: 0.9rem;
        align-self: flex-start;
        padding: 5px 0;
        width: 60px;
        text-align: center;

        @media screen and (max-width: 700px) {
          text-align: left;
        }
      }

      @media screen and (max-width: 700px) {
        flex-direction: column;
      }

      .inputs {
        width: calc(100% - 70px);

        @media screen and (max-width: 700px) {
          width: 100%;
        }

        .suggest {
          background-color: ${({ clr = Olympe.Color }) => clr.inputBg};
          display: none;
          align-items: center;
          width: 100%;
          padding: 5px;
          margin: 0;
          border-radius: 0;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;

          p {
            cursor: pointer;
            padding: 5px 10px;
            margin: 5px 0;
            border-radius: 10px;
            color: ${({ clr = Olympe.Color }) => clr.dark};
            font-size: 13px;
            transition: background-color 0.4s;
          }
        }

        input {
          width: 100%;
          margin: 0;

          &[type="checkbox"] {
            display: inline;
            padding: 0;
            width: auto;
          }

          &:focus {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border: 2px solid #2684ff;
            box-shadow: none;
            border-bottom: 1px solid
              ${({ clr = Olympe.Color }) => clr.greyLight};
          }
        }

        &.focused {
          input {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border: 2px solid #2684ff;
            box-shadow: none;
            border-bottom: 1px solid
              ${({ clr = Olympe.Color }) => clr.greyLight};
          }

          .suggest {
            display: block;
            border: 2px solid #2684ff;
            border-top: none;

            p:hover {
              background-color: rgba(
                ${({ clr = Olympe.Color }) => clr.grey},
                0.2
              );
            }
          }
        }
      }
    }
  }
`;

export const ItemPdf = styled(Olympe.ItemFullWidth)`
  justify-content: space-around;
  height: calc(100% - 40px);
  max-height: 1000px;
  margin: 20px 10px;

  width: calc(60% - 10px);
  border-radius: 4px;
  padding: 0;

  &.infos {
    height: fit-content;
    padding: 30px 10px;
    width: 40%;
    border-radius: 10px;

    input.urlPdf {
      width: 90%;
      max-width: 90%;
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: none;
      color: ${({ clr = Olympe.Color }) => clr.blueLight};
      font-size: 0.9rem;

      &:hover {
        cursor: copy;
      }
    }
  }

  @media screen and (max-width: 1305px) {
    width: 90%;
    max-height: auto;

    &.infos {
      width: 90%;
    }
  }

  .pdfWaring {
    text-align: center;
    width: 70%;
    margin: 20px auto;
    display: flex;
    align-items: center;
    font-size: 1.1rem;

    svg {
      color: ${({ clr = Olympe.Color }) => clr.tomato};
      margin-right: 10px;
    }
  }

  .errorFile {
    text-align: center;
    width: 70%;
    margin: auto;

    svg {
      color: ${({ clr = Olympe.Color }) => clr.tomato};
      height: 2.5rem;
      width: 2.5rem;
      margin-bottom: 10px;
    }
  }

  iframe {
    height: 100%;

    @media screen and (max-width: 1305px) {
      width: 100%;
      min-height: 500px;
    }
  }

  .usrDetails {
    margin: 5px;
    padding: 10px;

    .input {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      @media screen and (max-width: 700px) {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
      }

      input {
        width: 100%;
        min-width: 200px;

        @media screen and (max-width: 700px) {
          min-width: 100%;
          max-width: 100%;
          margin-bottom: 10px;
        }
      }
    }
  }
`;

export const ItemSetup = styled(Olympe.Item)`
  justify-content: space-around;

  @media screen and (max-width: 700px) {
    max-width: 100%;
  }

  &.large {
    width: 100%;
    max-width: 100%;
    overflow: hidden;

    @media screen and (max-width: 700px) {
      width: 100%;
      max-width: 100%;
    }
  }

  img {
    object-fit: contain;
    margin: auto;
    border-radius: 30px;

    @media screen and (max-width: 700px) {
      border-radius: 10px;
      max-height: 100px;
      max-width: 100px;
    }
  }

  .usrDetails {
    &.productName {
      margin-bottom: 10px;
      margin-right: 10px;

      input {
        padding: 10px;
        font-size: 0.9rem;
      }

      svg {
        width: 24px;
        height: auto;
      }
    }

    svg {
      width: 20px;
      height: auto;
    }

    span {
      white-space: break-spaces;
    }
  }

  .pdfWaring {
    margin: 20px auto;
    width: 70%;
    text-align: center;

    span {
      text-align: center;
      font-size: 1.1rem;
    }

    svg {
      color: ${({ clr = Olympe.Color }) => clr.tomato};
      margin-right: 10px;
      height: 24px;
      width: 24px;
    }
  }

  input {
    width: auto;

    &[type="text"] {
      width: 100%;
    }

    &[type="number"] {
      min-width: 60px;
    }
  }

  .timeSep {
    padding: 0 10px;
  }

  .fullDate {
    font-size: 0.8rem;
    color: ${({ clr = Olympe.Color }) => clr.grey};
    padding: 0 10px;
  }
`;

export const ItemSeeFile = styled(Olympe.ItemFullWidth)`
  width: 100%;

  @media screen and (max-width: 700px) {
    max-width: 100%;
  }

  p {
    color: ${({ clr = Olympe.Color }) => clr.grey};
    text-align: center;
    margin: auto;
    margin-bottom: 30px;
    width: 80%;

    @media screen and (max-width: 700px) {
      font-size: 12px;
    }
  }

  h3 {
    color: ${({ clr = Olympe.Color }) => clr.light};
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin-right: 10px;
    }

    @media screen and (max-width: 700px) {
      font-size: 12px;

      svg {
        width: 14px;
      }
    }
  }

  .links {
    display: flex;
    flex-direction: column;

    .link {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 20px 0;

      p {
        text-align: left;
        margin: 0;
        padding: 0 5px 0 0;
        display: flex;
        flex-direction: row;
        align-items: center;

        svg {
          color: ${({ clr = Olympe.Color }) => clr.blueLight};
          margin-right: 10px;
          width: 20px;
          height: 20px;
        }

        .desc {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;

          svg {
            color: ${({ clr = Olympe.Color }) => clr.tomato};
          }
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid ${({ clr = Olympe.Color }) => clr.grey};
      }
    }
  }

  .addFile {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: hidden;
    padding: 20px 0;

    .progBar {
      display: block;
      width: 70%;
      margin-top: 15px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid ${({ clr = Olympe.Color }) => clr.grey};
    }

    .ButtonBase {
      color: ${({ clr = Olympe.Color }) => clr.grey};
      border-radius: 10px;

      svg {
        height: 18px;
        width: 18px;
        margin-left: 10px;
      }
    }

    input[type="file"] {
      display: none;
    }

    .loadFile {
      padding: 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      p {
        color: ${({ clr = Olympe.Color }) => clr.grey};
      }
    }
  }

  .addDocu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px dashed ${({ clr = Olympe.Color }) => clr.light};

    @media screen and (max-width: 700px) {
      flex-direction: column;
    }

    .fields {
      width: 70%;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 700px) {
        width: 100%;
      }

      input {
        margin: 5px;
      }
    }
  }
`;

export const ItemAccountHistory = styled(Olympe.Item)`
  width: calc(60% - 10px);
  height: 500px;

  @media screen and (max-width: 1460px) {
    width: 100%;
    height: auto;
    min-height: initial;
  }

  @media screen and (max-width: 700px) {
    width: calc(100% - 25px);
  }

  .card {
    border-radius: 15px;
    text-decoration: none;
    margin: 2px 0;
    padding: 15px 0;
    color: ${({ clr = Olympe.Color }) => clr.light};
    border: 1px solid transparent;
    transition: background-color 0.4s ease-in-out, border-color 0.4s;

    display: flex;
    flex-direction: row;
    align-items: center;

    .date {
      padding: 0 10px;
      max-width: 70px;
      min-width: 70px;
      color: ${({ clr = Olympe.Color }) => clr.grey};
      font-size: 0.85rem;
      text-align: center;
    }

    .name {
      font-size: 0.9rem;
      padding: 0 0 5px 0;
      margin: 0;
    }

    .desc {
      margin: 0;
      padding: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
      font-size: 0.85rem;
    }

    &:not(:disabled):hover {
      background: rgba(255, 255, 255, 0.2);
      border-color: rgba(255, 255, 255, 0.1);
    }

    &.disabled {
      cursor: not-allowed !important;
    }
  }
`;

export const ItemDeleteFile = styled(Olympe.Item)`
  p {
    text-align: center;
    color: ${({ clr = Olympe.Color }) => clr.grey};
  }

  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    button {
      color: ${({ clr = Olympe.Color }) => clr.light};
      width: 60%;
      margin: 10px;

      svg {
        height: 18px;
        width: 18px;
        margin-left: 10px;
      }
    }
  }
`;
