import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { axiosGet } from "../datas/fetch";

import { AlertTriangle, Info, PlusCircle, Search } from "react-feather";

import { MainContext } from "../datas/context";
import { Olympe } from "dv-olympe";
import { HorizontalCard, HorizontalWrap } from "../components/StyledElements";

export default function ProductsView () {
  const context = React.useContext(MainContext);
  const params = useParams();
  const [loading, setLoad] = useState(!!params.usrId);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const refreshInfos = async () => {
      axiosGet({
        apiKey: context.api.key,
        uri: "products/"
      }).then((data) => {
        setUsers(data);

        if (loading) setLoad(false);
      });
    };

    refreshInfos();
    const datasInterval = setInterval(() => refreshInfos(), 2000);

    return () => {
      clearInterval(datasInterval);
    };
  }, [navigate]);

  const filteredProducts = () => {
    const ret =
      search.length > 0
        ? users.filter(
          (obj) =>
            obj.id?.toLowerCase().includes(search) ||
              obj.list?.find((elt) => elt.name?.toLowerCase().includes(search))
        )
        : users.sort((a, b) => b.related - a.related);

    for (let i = 0; i < ret.length; i++) {
      ret[i].list = ret[i].list?.filter(
        (obj) =>
          obj.name?.toLowerCase().includes(search) ||
          obj.corp?.toLowerCase().includes(search)
      );
    }
    return ret;
  };

  const renderImgCard = (elt) => {
    // console.log(elt);

    return (
      <HorizontalCard
        key={elt.name}
        onClick={() => navigate(`/products/details/${elt.id}`)}
      >
        {elt.count === 0 && (
          <AlertTriangle color={Olympe.Color.tomato} size={16} />
        )}
        <img
          src={elt.logo}
          alt={elt.name}
          height={100}
          width={100}
          className="contain"
        />
        <h3>{elt.name}</h3>
        <p>{elt.corp.toUpperCase()}</p>
        <span>Utilisé {elt.count} fois.</span>
      </HorizontalCard>
    );
  };

  if (!users[0]) return <Olympe.Loader />;

  return (
    <Olympe.Wrapper horizontal multiple>
      <Olympe.SubItem>
        <Olympe.Item>
          <Olympe.ItemTitle>
            <span>
              <Info />
              Produits
            </span>
          </Olympe.ItemTitle>

          <p className="usrDetails">
            Voici la totalité des produits enregistrés, triés par fabricants.
            Vous pouvez les consulter, les modifier ou les supprimer.
          </p>

          <p className="usrDetails">
            <Search />
            <Olympe.Input.Text
              type="text"
              value={search}
              autoComplete="off"
              placeholder="Fabricant, produit ..."
              onChange={(e) => setSearch(e.target.value)}
              onKeyPress={(e) => {
                e.key === "Enter" && Olympe.Utils.enterInput(e);
              }}
            />
          </p>

          <div className="footerInfos">
            <Olympe.Button.Basic
              toExecute={() => navigate("/products/add/")}
              label="Ajouter un produit"
              icon={<PlusCircle />}
            />
          </div>
        </Olympe.Item>
      </Olympe.SubItem>

      {filteredProducts()?.map((elt) => (
        <Olympe.SubItem key={elt.id}>
          <Olympe.ItemFullWidth>
            <Olympe.ItemTitle>
              <span>{elt.name}</span>
              <span style={{ fontSize: 14 }}>
                {elt.related || "0"} produit{elt.related > 1 ? "s" : ""},{" "}
                {elt.count || "0"} installation{elt.count > 1 ? "s" : ""}.
              </span>
            </Olympe.ItemTitle>
            <HorizontalWrap>
              {elt.list.map((elt) => renderImgCard(elt))}
            </HorizontalWrap>
          </Olympe.ItemFullWidth>
        </Olympe.SubItem>
      ))}

      {filteredProducts().length === 0 && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Olympe.ItemTitle>Aucune correspondance</Olympe.ItemTitle>
        </div>
      )}
    </Olympe.Wrapper>
  );
}
