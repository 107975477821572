import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import $ from "jquery";
import axios from "axios";
import { LinearProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import {
  AlertTriangle,
  ArrowLeftCircle,
  Book,
  BookOpen,
  Calendar,
  DollarSign,
  Edit,
  ExternalLink,
  File,
  FileMinus,
  Image,
  Link,
  MapPin,
  Package,
  Trash,
  Truck,
  User,
  Users,
  Video,
  Watch,
  X,
  XCircle
} from "react-feather";

import { MainContext } from "../datas/context";
import { getInstTime } from "../datas/UtilsFunctions";
import { axiosDelete, axiosGet, axiosPost } from "../datas/fetch";
import { ItemSeeFile, ItemSetup } from "../components/StyledItem";
import { Olympe } from "dv-olympe";

export default function SetupsDetails () {
  const context = React.useContext(MainContext);
  const params = useParams();
  const navigate = useNavigate();

  const [infos, setInfos] = useState({});
  const [progress, setProgress] = useState({
    devis: 0,
    contrat: 0,
    facture: 0
  });
  const [buffer, setBuffer] = useState({
    devis: 0,
    contrat: 0,
    facture: 0
  });
  const [fileLoad, setFileLoad] = useState({
    devis: false,
    contrat: false,
    facture: false
  });

  const [selectedDevis, setSelectedDevis] = useState(null);
  const [selectedContrat, setSelectedContrat] = useState(null);
  const [selectedFacture, setSelectedFacture] = useState(null);

  const [deleteSetup, setDeleteSetup] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => setOpenModal(false);

  const { register, setValue, getValues, watch } = useForm();

  const selectContrat = (event) => {
    setSelectedContrat(event.target.files);

    if (event.target.files[0]) {
      setValue("fileContrat", magicFileName("contrat"));
    } else {
      setSelectedContrat(null);
    }
  };
  const selectDevis = (event) => {
    setSelectedDevis(event.target.files);

    if (event.target.files[0]) {
      setValue("fileDevis", magicFileName("devis"));
    } else {
      setSelectedDevis(null);
    }
  };
  const selectFacture = (event) => {
    setSelectedFacture(event.target.files);

    if (event.target.files[0]) {
      setValue("fileFacture", magicFileName("facture"));
    } else {
      setSelectedFacture(null);
    }
  };

  const refreshInfos = async () => {
    axiosGet({
      apiKey: context.api.key,
      uri: `setups/?id=${params.fileId}`
    }).then((data) => {
      if (!data.instId) {
        toast.error("Installation non trouvée.", { icon: "🔎" });
        navigate("/setups/");
        return;
      }
      setInfos(data);
    });
  };

  useEffect(() => {
    if (!params.fileId) {
      toast.error("Installation non précisée.", { icon: <Package /> });
      navigate("/setups/");
    }

    watch(["fileDevis", "fileContrat", "fileFacture"]);
    refreshInfos();
  }, [navigate]);

  const magicFileName = (type = "") => {
    if (type === "contrat") {
      return `Contrat REF-${infos.instRef.replace("ref", "")} ${
        infos.product.usr
      }`;
    } else if (type === "devis") {
      return `Devis REF-${infos.instRef.replace("ref", "")} ${
        infos.product.usr
      }`;
    } else if (type === "facture") {
      return `Facture REF-${infos.instRef.replace("ref", "")} ${
        infos.product.usr
      }`;
    } else {
      return `Document REF-${infos.instRef.replace("ref", "")} ${
        infos.product.usr
      }`;
    }
  };

  const UploadFile = (file = "") => {
    const formData = new FormData();
    let currentFile = null;
    let fileName = "Nouveau Document";

    if (file === "") {
      return console.error("File type not specified");
    } else if (file === "contrat") {
      if (!selectedContrat) {
        return console.error("Empty file.");
      }

      currentFile = selectedContrat[0];
      fileName = getValues("fileContrat") || "Nouveau contrat";

      setFileLoad({ contrat: true });
      setProgress({ contrat: 0 });
    } else if (file === "devis") {
      if (!selectedDevis) {
        return console.error("Empty file.");
      }

      currentFile = selectedDevis[0];
      fileName = getValues("fileDevis") || "Nouveau devis";

      setFileLoad({ devis: true });
      setProgress({ devis: 0 });
    } else if (file === "facture") {
      if (!selectedFacture) {
        return console.error("Empty file.");
      }

      currentFile = selectedFacture[0];
      fileName = getValues("fileFacture") || "Nouvelle facture";

      setFileLoad({ facture: true });
      setProgress({ facture: 0 });
    } else {
      return console.error("Wrong file type.");
    }

    if (!currentFile) {
      return console.error("Empty file.");
    }

    let count = 0;

    formData.append("file", currentFile);

    const toastId = toast.info("Préparation au téléchargement", {
      isLoading: true,
      autoClose: false
    });

    return axios
      .post(
        `https://api.mayansenergies.com/v1/file/?item=getId&fileName=${fileName}&clid=${infos.clid}`,
        formData,
        {
          auth: { username: context.api.key },
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            count++;
            const prog = progressEvent.loaded / progressEvent.total;

            if (file === "contrat") {
              if (buffer.contrat === 0) setBuffer({ contrat: prog * 100 * 2 });
              else {
                setBuffer({
                  contrat:
                    Math.round(100 * prog) + Math.round(100 * prog) / count
                });
              }
              setProgress({ contrat: Math.round(100 * prog) });
            } else if (file === "devis") {
              if (buffer.devis === 0) setBuffer({ devis: prog * 100 * 2 });
              else {
                setBuffer({
                  devis: Math.round(100 * prog) + Math.round(100 * prog) / count
                });
              }
              setProgress({ devis: Math.round(100 * prog) });
            } else if (file === "facture") {
              if (buffer.facture === 0) setBuffer({ facture: prog * 100 * 2 });
              else {
                setBuffer({
                  facture:
                    Math.round(100 * prog) + Math.round(100 * prog) / count
                });
              }
              setProgress({ facture: Math.round(100 * prog) });
            }

            if (prog < 1) {
              toast.update(toastId, {
                render: `Téléchargé à ${Math.round(prog * 100)} %`,
                progress,
                isLoading: true
              });
            }
          }
        }
      )
      .then(({ data }) => {
        if (typeof data !== "number") {
          throw new Error(data || "réponse serveur erronée.");
        }

        toast.update(toastId, {
          render: `Le fichier "${fileName}" a bien été téléchargé, ajout en tant que ${file}`,
          type: "info",
          isLoading: true,
          autoClose: false
        });

        axiosPost({
          apiKey: context.api.key,
          uri: "setupFile/",
          opt: {
            fileId: data,
            instId: infos.instId,
            fileType: file
          }
        })
          .then((data) => {
            if (data !== "true") {
              throw new Error(data || "réponse serveur erronée.");
            }
            toast.update(toastId, {
              render: `Le fichier "${fileName}" a bien été téléchargé et relié en tant que ${file}`,
              type: "success",
              isLoading: false,
              autoClose: true,
              icon: <File />
            });
          })
          .catch((e) => {
            toast.update(toastId, {
              render: `Le fichier "${fileName}" a bien été téléchargé, mais n'est pas relié en tant que ${file} à l'installation : ${e.message}`,
              type: "error",
              isLoading: false,
              autoClose: false
            });
          })
          .finally(() => refreshInfos());
      })
      .catch((e) => {
        toast.update(toastId, {
          render: `Le fichier "${fileName}" n'a pas été téléchargé : ${e.message}`,
          type: "error",
          isLoading: false,
          autoClose: true
        });
      })
      .finally(() => {
        if (file === "contrat") {
          setSelectedContrat(null);
          setProgress({ contrat: 0 });
          setBuffer({ contrat: 0 });
          setFileLoad({ contrat: false });
        } else if (file === "devis") {
          setSelectedDevis(null);
          setProgress({ devis: 0 });
          setBuffer({ devis: 0 });
          setFileLoad({ devis: false });
        } else if (file === "facture") {
          setSelectedFacture(null);
          setProgress({ facture: 0 });
          setBuffer({ facture: 0 });
          setFileLoad({ facture: false });
        }
      });
  };

  const delSetup = () => {
    setDeleteSetup(true);
    axiosDelete({
      apiKey: context.api.key,
      uri: "setup",
      opt: { instId: infos.instId }
    })
      .then((data) => {
        if (data !== "true") {
          throw new Error(data || "réponse serveur erronée.");
        }
        toast.success("L'installation a bien été supprimée.", {
          icon: <Trash />
        });
        navigate("/setups/");
      })
      .catch((e) =>
        toast.error(
          "Une erreur est survenue, l'installation n'a pas été supprimée : " +
            e.message
        )
      )
      .finally(() => {
        setDeleteSetup(false);
        setOpenModal(false);
      });
  };

  if (!infos.instId) return <Olympe.Loader />;

  return (
    <Olympe.Wrapper>
      <Olympe.Modal
        open={openModal}
        verifTxt={infos.product.name}
        loading={deleteSetup}
        onClose={closeModal}
        title="Supprimer l'installation"
        content={
          <>
            Supprimer une installation est immédiat et irréversible. La
            documentation de celle-ci est perdue mais les fichiers reliés
            restent connectés au compte de l'utilisateur.
          </>
        }
        buttons={[
          {
            isCancel: true,
            label: "Annuler",
            icon: <XCircle />,
            exec: closeModal
          },
          {
            isDanger: true,
            label: "Supprimer",
            icon: <Trash />,
            exec: delSetup
          }
        ]}
      />

      <Olympe.SubItem>
        <ItemSetup>
          <Olympe.ItemTitle>
            <span>
              <Olympe.Tooltips
                title="Revenir à la page précédente."
                classes="icon"
                placement="left"
              >
                <Olympe.Button.Basic
                  toExecute={() => navigate(-1)}
                  icon={<ArrowLeftCircle />}
                />
              </Olympe.Tooltips>

              <span style={{ marginLeft: 20 }}>Installation</span>
            </span>
          </Olympe.ItemTitle>

          {!infos.product.name && (
            <p className="usrDetails">
              <AlertTriangle />
              <span>Cette installation est erronée.</span>
            </p>
          )}

          <p className="usrDetails">
            <Users />
            <Olympe.Tooltips title="Les techniciens">
              {infos.instTech}
            </Olympe.Tooltips>
          </p>

          <p className="usrDetails">
            <MapPin />
            <Olympe.Tooltips title="Adresse postale">
              {infos.instAddr}
            </Olympe.Tooltips>
          </p>

          <p className="usrDetails">
            <Watch />
            <span>Durée des travaux : {getInstTime(infos.instDura)}</span>
          </p>

          <p className="usrDetails">
            <Calendar />
            <Olympe.Tooltips title="Date de la mise en route">
              {moment(infos.instDate).format("LL")}
            </Olympe.Tooltips>
          </p>

          <p className="usrDetails">
            <Link />
            <Olympe.Tooltips title="Référence">{infos.instRef}</Olympe.Tooltips>
          </p>

          <p className="usrDetails">
            <DollarSign />
            <Olympe.Tooltips title="Prix de l'installation">
              {infos.instPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || "0"} €
            </Olympe.Tooltips>
          </p>
        </ItemSetup>

        <ItemSetup>
          {infos.product.name
            ? (
              <>
                <img
                  src={infos.product.logo}
                  alt={infos.product.name}
                  height={150}
                  width={150}
                />
                <p className="usrDetails">
                  <Package />
                  <Olympe.Tooltips title="Nom du produit">
                    {infos.product.name}
                  </Olympe.Tooltips>
                </p>

                <p className="usrDetails">
                  <Truck />
                  <Olympe.Tooltips title="Fabricant">
                    {infos.product.corp}
                  </Olympe.Tooltips>
                </p>

                <p className="usrDetails">
                  <User />
                  <Olympe.Tooltips title="Utilisateur relié">
                    {infos.product.usr}
                  </Olympe.Tooltips>
                </p>

                <p className="usrDetails">
                  <Package />
                  <Olympe.Tooltips title="Identifiant du produit">
                    {infos.product.id.replace("product_", "")}
                  </Olympe.Tooltips>
                </p>
              </>
            )
            : (
              <p className="pdfWaring">
                <AlertTriangle />
                <br />
                <br />
                <span>
                  Le produit dont est relié cette installation est introuvable.
                  Possibles causes : produit supprimé, base de données corrompue,
                  scripts non fonctionnels...
                </span>
              </p>
            )}
        </ItemSetup>
      </Olympe.SubItem>

      <Olympe.SubItem>
        <ItemSeeFile>
          <Olympe.ItemTitle>
            <span>
              <File /> Documents
            </span>
          </Olympe.ItemTitle>

          <p>
            Les documents ci dessous sont ceux reliés à l'installation. Ils
            peuvent être remplacés ici à tout moment ou bien supprimés dans la
            section <i>Modifier l'installation</i>.
          </p>

          <div className="addFile">
            <input
              type="file"
              onChange={selectContrat}
              accept=".pdf"
              id="pickContrat"
            />

            <div className="loadFile">
              {!selectedContrat && (
                <Olympe.Button.FilePick
                  className="btnGetFiles"
                  onClick={() => $("#pickContrat").trigger("click")}
                >
                  Choisir un contrat
                </Olympe.Button.FilePick>
              )}

              {selectedContrat && (
                <Olympe.Input.FormFile>
                  <span>
                    <File />
                    <Olympe.Input.File
                      type="input"
                      placeholder="Description du fichier"
                      {...register("fileContrat")}
                    />

                    <Olympe.Button.FileCancel
                      onClick={() => setSelectedContrat(null)}
                    >
                      <X />
                    </Olympe.Button.FileCancel>
                  </span>

                  <Olympe.Button.FileUpload
                    className="btnUpload"
                    onClick={() => UploadFile("contrat")}
                  >
                    Télécharger
                  </Olympe.Button.FileUpload>
                </Olympe.Input.FormFile>
              )}
            </div>

            {!selectedContrat &&
              (infos.contratId
                ? (
                  <Olympe.Button.Basic
                    label="Voir le contrat"
                    icon={<ExternalLink />}
                    toExecute={() => navigate(`/files/pdf/${infos.contratId}`)}
                  />
                )
                : (
                  <Olympe.Button.Basic
                    label="Aucun contrat"
                    icon={<FileMinus />}
                    toExecute={false}
                  />
                ))}

            {fileLoad.contrat && (
              <LinearProgress
                value={progress.contrat || 0}
                valueBuffer={buffer.contrat || 0}
                variant="buffer"
                className="progBar"
              />
            )}
          </div>

          <div className="addFile">
            <input
              type="file"
              onChange={selectDevis}
              accept=".pdf"
              id="pickDevis"
            />

            <div className="loadFile">
              {!selectedDevis && (
                <Olympe.Button.FilePick
                  className="btnGetFiles"
                  onClick={() => $("#pickDevis").trigger("click")}
                >
                  Choisir un devis
                </Olympe.Button.FilePick>
              )}

              {selectedDevis && (
                <Olympe.Input.FormFile>
                  <span>
                    <File />
                    <Olympe.Input.File
                      type="text"
                      placeholder="Description du fichier"
                      {...register("fileDevis")}
                    />

                    <Olympe.Button.FileCancel
                      onClick={() => setSelectedDevis(null)}
                    >
                      <X />
                    </Olympe.Button.FileCancel>
                  </span>

                  <Olympe.Button.FileUpload
                    className="btnUpload"
                    onClick={() => UploadFile("devis")}
                  >
                    Télécharger
                  </Olympe.Button.FileUpload>
                </Olympe.Input.FormFile>
              )}
            </div>

            {!selectedDevis &&
              (infos.devisId
                ? (
                  <Olympe.Button.Basic
                    label="Voir le devis"
                    icon={<ExternalLink />}
                    toExecute={() => navigate(`/files/pdf/${infos.devisId}`)}
                  />
                )
                : (
                  <Olympe.Button.Basic
                    label="Aucun devis"
                    icon={<FileMinus />}
                    toExecute={false}
                  />
                ))}

            {fileLoad.devis && (
              <LinearProgress
                value={progress.devis || 0}
                valueBuffer={buffer.devis || 0}
                variant="buffer"
                className="progBar"
              />
            )}
          </div>

          <div className="addFile">
            <input
              type="file"
              onChange={selectFacture}
              accept=".pdf"
              id="pickFacture"
            />

            <div className="loadFile">
              {!selectedFacture && (
                <Olympe.Button.FilePick
                  className="btnGetFiles"
                  onClick={() => $("#pickFacture").trigger("click")}
                >
                  Choisir une facture
                </Olympe.Button.FilePick>
              )}

              {selectedFacture && (
                <Olympe.Input.FormFile>
                  <span>
                    <File />
                    <Olympe.Input.File
                      type="text"
                      placeholder="Description du fichier"
                      {...register("fileFacture")}
                    />

                    <Olympe.Button.FileCancel
                      onClick={() => setSelectedFacture(null)}
                    >
                      <X />
                    </Olympe.Button.FileCancel>
                  </span>

                  <Olympe.Button.FileUpload
                    className="btnUpload"
                    onClick={() => UploadFile("facture")}
                  >
                    Télécharger
                  </Olympe.Button.FileUpload>
                </Olympe.Input.FormFile>
              )}
            </div>

            {!selectedFacture &&
              (infos.factureId
                ? (
                  <Olympe.Button.Basic
                    label={
                      <>
                        Voir la facture <ExternalLink />
                      </>
                    }
                    toExecute={() => navigate(`/files/pdf/${infos.factureId}`)}
                  />
                )
                : (
                  <Olympe.Button.Basic
                    label="Aucune facture"
                    icon={<FileMinus />}
                    toExecute={false}
                  />
                ))}

            {fileLoad.facture && (
              <LinearProgress
                value={progress.facture || 0}
                valueBuffer={buffer.facture || 0}
                variant="buffer"
                className="progBar"
              />
            )}
          </div>
        </ItemSeeFile>
      </Olympe.SubItem>

      <Olympe.SubItem>
        <ItemSeeFile>
          <Olympe.ItemTitle>
            <span>
              <BookOpen /> Documentation
            </span>
          </Olympe.ItemTitle>

          <p>
            Voici la liste des images et vidéos reliées à cette installation.
            Elle peut être modifiée à tout moment, dans la section{" "}
            <i>Modifier l'installation</i>. Elle est uniquement visible sur les
            comptes particuliers.
          </p>

          {infos.documentation && infos.documentation.length > 0
            ? (
              <div className="links">
                {infos.documentation.map((elt, idx) => (
                  <div className="link" key={`${idx}-${elt.link}`}>
                    <p className="title">
                      {elt.type === "image"
                        ? (
                          <Image />
                        )
                        : elt.type === "video"
                          ? (
                            <Video />
                          )
                          : (
                            <File />
                          )}{" "}
                      <span className="desc">
                        {elt.name.startsWith("ERROR")
                          ? (
                            <>
                              <AlertTriangle /> {elt.name.replace("ERROR", "")}
                            </>
                          )
                          : (
                            elt.name
                          )}
                      </span>
                    </p>
                    <Olympe.Button.Basic
                      label="Ouvrir"
                      icon={<ExternalLink />}
                      toExecute={() => window.open(elt.link, "_blank").focus()}
                    />
                  </div>
                ))}
              </div>
            )
            : (
              <h3>
                <Book /> Aucune documentation
              </h3>
            )}
        </ItemSeeFile>
      </Olympe.SubItem>

      <Olympe.SubItem>
        <Olympe.ItemFullWidth>
          <Olympe.ItemTitle>Zone dangereuse</Olympe.ItemTitle>

          <p className="usrDetails">
            <Edit />
            <span className="codeDesc">
              Modifier une installation change les statistiques de l'entreprise
              ; les changements seront directement visibles par l'utilisateur.
              Aucun retour en arrière n'est possible, mais l'installation peut
              être modifiée autant de fois que nécessaire.
            </span>
          </p>
          <p className="usrDetails">
            <Trash />
            <span className="codeDesc">
              Supprimer une installation est irréversible ; ses données sont
              perdues à l'exception des fichiers reliés qui pourront être
              supprimés manuellement depuis la section correspondante.
            </span>
          </p>
          <div className="footerInfos">
            <Olympe.Button.Basic
              label="Modifier l'installation"
              icon={<Edit />}
              toExecute={() => navigate(`/setups/edit/${infos.instId}`)}
            />

            <Olympe.Button.Basic
              label="Supprimer l'installation"
              icon={<Trash />}
              anim="danger"
              toExecute={() => setOpenModal(true)}
            />
          </div>
        </Olympe.ItemFullWidth>
      </Olympe.SubItem>
    </Olympe.Wrapper>
  );
}
