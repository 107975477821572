import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { axiosGet } from "../datas/fetch";

import { AlertTriangle, Package, PlusCircle } from "react-feather";
import { getInstTime } from "../datas/UtilsFunctions";
import moment from "moment";
import { toast } from "react-toastify";

import { MainContext } from "../datas/context";
import { Olympe } from "dv-olympe";

const columns = [
  {
    name: "Id",
    selector: (row, idx) => idx,
    format: (row, idx) =>
      row.product.id === false || row.docuError > 0
        ? (
          <span className="errorRow">
            <AlertTriangle /> {idx + 1}
          </span>
        )
        : (
          idx + 1
        ),
    width: "80px",
    hide: "sm"
  },
  {
    name: "Client",
    selector: (row) => Olympe.Utils.truncStr({ str: row.product.usr || "nc", len: 20 }),
    grow: 3,
    sortable: true
  },
  {
    name: "Installation",
    selector: (row) => Olympe.Utils.truncStr({ str: row.product.name || "nc", len: 20 }),
    grow: 5,
    sortable: true,
    hide: "sm"
  },
  {
    name: "Date",
    selector: (row) =>
      row.product.id === false || row.docuError > 0
        ? moment().format("YYYY-MM-DD")
        : row.instDate,
    format: (row) =>
      row.product.id === false || row.docuError > 0
        ? moment().format("ll")
        : moment(row.instDate).format("ll"),
    sortable: true,
    grow: 2,
    hide: "sm"
  }
];

export default function SetupsView () {
  const params = useParams();
  const context = React.useContext(MainContext);
  const navigate = useNavigate();

  const [loading, setLoad] = useState(!!params.usrId);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [searchFile, setSearchFile] = useState("");

  useEffect(() => {
    const refreshInfos = async () => {
      axiosGet({
        apiKey: context.api.key,
        uri: "setups/"
      }).then((data) => {
        setUsers(data);

        if (params.usrId && !data[0].id) {
          toast.error("Utilisateur non trouvé.", { icon: "🔎" });
          navigate("/accounts/");
        }

        if (loading) setLoad(false);
      });
    };

    if (params.usrId === "pdf") navigate("/files/");
    refreshInfos();
    const datasInterval = setInterval(() => refreshInfos(), 2000);

    return () => {
      clearInterval(datasInterval);
    };
  }, [navigate]);

  const handleSearch = (event) => setSearch(event.target.value);
  const handleSearchFile = (event) => setSearchFile(event.target.value);

  const filteredDatas = () => {
    const ret = [];
    const tmp = users
      .filter(
        (obj) =>
          obj.id.toLowerCase().includes(search.toLowerCase()) ||
          obj.name.toLowerCase().includes(search.toLowerCase()) ||
          obj.count.toString().includes(search.toLowerCase()) ||
          getInstTime(obj.size).includes(search.toLowerCase())
      )
      .sort((a, b) => b.size - a.size)
      .sort((a, b) => b.related - a.related)
      .sort((a, b) => b.error - a.error);

    for (let i = 0; i < tmp.length; i++) {
      ret.push({
        id: tmp[i].id,
        name: tmp[i].name,
        desc:
          tmp[i].count !== tmp[i].related
            ? (
              <span style={{ color: Olympe.Color.tomato }}>
                {Math.abs(tmp[i].related - tmp[i].count)} Erronée{" "}
                {Math.abs(tmp[i].related - tmp[i].count) > 1 && "s"}
              </span>
            )
            : tmp[i].count === 0
              ? (
                "Aucune installation."
              )
              : (
                <>
                  <span className="text">
                    {tmp[i].count} <Package /> ● {getInstTime(tmp[i].size)}
                  </span>
                </>
              ),
        badge: Math.abs(tmp[i].related - tmp[i].count)
      });
    }
    return ret;
  };

  const filteredTableDatas = () => {
    const tmp = [];

    if (params.usrId) {
      for (let i = 0; i < users.length; i++) {
        if (users[i] && users[i].id === params.usrId) {
          for (let k = 0; k < users[i].setups.length; k++) {
            tmp.push(users[i].setups[k]);
          }
          break;
        }
      }
    } else {
      for (let i = 0; i < users.length; i++) {
        if (users[i].count > 0) {
          for (let k = 0; k < users[i].setups.length; k++) {
            tmp.push(users[i].setups[k]);
          }
        }
      }
    }
    return tmp.filter(
      (obj) =>
        obj.instRef.toLowerCase().includes(searchFile.toLowerCase()) ||
        obj.instTech.toLowerCase().includes(searchFile.toLowerCase()) ||
        obj.product.usr.toLowerCase().includes(searchFile.toLowerCase()) ||
        obj.product.name.toLowerCase().includes(searchFile.toLowerCase()) ||
        obj.product.corp.toLowerCase().includes(searchFile.toLowerCase())
    );
  };

  const filteredFilesUser = () => {
    const tmp = {
      id: null,
      name: null
    };

    if (params.usrId) {
      for (let i = 0; i < users.length; i++) {
        if (users[i] && users[i].id === params.usrId) {
          tmp.id = users[i].id;
          tmp.name = users[i].name;
          break;
        }
      }
    }

    return tmp;
  };

  if (!users[0]) return <Olympe.Loader />;

  return (
    <Olympe.Wrapper horizontal>
      <Olympe.SubItem>
        <Olympe.List.Menu
          uri="setups"
          label="Installations"
          placeholder="Utilisateur, identifiant"
          handleSearch={handleSearch}
          search={search}
          content={filteredDatas()}
        />

        <Olympe.List.Table
          id={filteredFilesUser().id}
          sortId={4}
          sortAsc={false}
          name={filteredFilesUser().name}
          loading={loading}
          columns={columns}
          search={searchFile}
          buttonIcon={<PlusCircle />}
          tableTitle="Toutes les installations"
          buttonLabel="Ajouter une installation"
          placeholder="Produit, date, ref ..."
          datas={filteredTableDatas()}
          handleSearch={handleSearchFile}
          onClickBtn={() => navigate(`/setups/add/${filteredFilesUser().id}`)}
          onClickRow={(data) => navigate(`/setups/details/${data.instId}`)}
        />
      </Olympe.SubItem>
    </Olympe.Wrapper>
  );
}
