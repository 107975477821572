import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  AlertTriangle,
  ArrowLeftCircle,
  Calendar,
  CheckCircle,
  DollarSign,
  Edit,
  FileText,
  MapPin,
  Package,
  Trash,
  Users,
  Watch,
  XCircle
} from "react-feather";

import { MainContext } from "../datas/context";
import { getInstTime } from "../datas/UtilsFunctions";
import { axiosDelete, axiosGet, axiosPut } from "../datas/fetch";
import { Olympe } from "dv-olympe";

export default function MeetingsDetails () {
  const context = React.useContext(MainContext);
  const params = useParams();
  const navigate = useNavigate();

  const [infos, setInfos] = useState({});

  const [deleteSetup, setDeleteSetup] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => setOpenModal(false);

  const [rejectModif, setRejectModif] = useState(false);
  const [openModalReject, setOpenModalReject] = useState(false);
  const closeModalReject = () => setOpenModalReject(false);

  const [toInter, setToInter] = useState(false);
  const [openModalInter, setOpenModalInter] = useState(false);
  const closeModalInter = () => setOpenModalInter(false);

  const [openModalAccept, setOpenModalAccept] = useState(false);
  const closeModalAccept = () => setOpenModalAccept(false);

  const refreshInfos = async () => {
    axiosGet({
      apiKey: context.api.key,
      uri: `meets/?id=${params.meetId}`
    }).then((data) => {
      if (!data.rdvId) {
        toast.error("Rendez-vous non trouvé.", { icon: "🔎" });
        navigate("/meets/");
        return;
      }
      setInfos(data);
    });
  };

  useEffect(() => {
    if (!params.meetId) {
      toast.error("Rendez-vous non précisé.", { icon: <Calendar /> });
      navigate("/meets/");
    }

    refreshInfos();
    const datasInterval = setInterval(() => refreshInfos(), 2000);

    return () => {
      clearInterval(datasInterval);
    };
  }, [navigate]);

  const delMeeting = () => {
    setDeleteSetup(true);
    axiosDelete({
      apiKey: context.api.key,
      uri: `meet/?item=${infos.rdvId}`,
      opt: {}
    })
      .then((data) => {
        if (data !== "true") {
          throw new Error(data || "réponse serveur erronée.");
        }
        toast.success("Le rendez-vous a bien été supprimé.", {
          icon: <Trash />
        });
        navigate("/meets/");
      })
      .catch((e) =>
        toast.error(
          "Une erreur est survenue, le rendez-vous n'a pas été supprimé : " +
            e.message
        )
      )
      .finally(() => {
        setDeleteSetup(false);
        setOpenModal(false);
      });
  };

  const modifReject = () => {
    setRejectModif(true);
    axiosPut({
      apiKey: context.api.key,
      uri: "meetModif/?item=reject",
      opt: { rdvId: infos.rdvId }
    })
      .then((data) => {
        if (data !== "true") {
          throw new Error(data || "réponse serveur erronée.");
        }
        toast.success("La demande de modification a bien été refusée.", {
          icon: <XCircle />
        });
      })
      .catch((e) =>
        toast.error(
          "Une erreur est survenue, la demande de modification n'a pas été rejetée : " +
            e.message
        )
      )
      .finally(() => {
        setRejectModif(false);
        setOpenModalReject(false);
      });
  };

  const modifAccept = () => {
    setRejectModif(true);
    axiosPut({
      apiKey: context.api.key,
      uri: "meetModif/?item=accept",
      opt: { rdvId: infos.rdvId }
    })
      .then((data) => {
        if (data !== "true") {
          throw new Error(data || "réponse serveur erronée.");
        }
        toast.success("La demande de modification a bien été acceptée.", {
          icon: <CheckCircle />
        });
      })
      .catch((e) =>
        toast.error(
          "Une erreur est survenue, la demande de modification n'a pas été acceptée : " +
            e.message
        )
      )
      .finally(() => {
        setRejectModif(false);
        setOpenModalAccept(false);
      });
  };

  const meetToInter = () => {
    setToInter(true);
    axiosPut({
      apiKey: context.api.key,
      uri: "meetToInter/",
      opt: { rdvId: infos.rdvId }
    })
      .then((data) => {
        if (data !== "true") {
          throw new Error(data || "réponse serveur erronée.");
        }
        toast.success("Le rendez-vous a bien été transformé en installation.", {
          icon: <Package />
        });
      })
      .catch((e) =>
        toast.error(
          "Une erreur est survenue, l'installation n'a pas été créée : " +
            e.message
        )
      )
      .finally(() => {
        setToInter(false);
        setOpenModalInter(false);
      });
  };

  if (!infos.rdvId) return <Olympe.Loader />;

  return (
    <Olympe.Wrapper>
      <Olympe.Modal
        open={openModal}
        verifTxt={infos.rdvName}
        loading={deleteSetup}
        onClose={closeModal}
        title="Supprimer le rendez-vous"
        content={
          <>
            Supprimer un rendez-vous est immédiat et irréversible. Toutes les
            données de celui-ci sont perdues et il ne peut être restauré.
          </>
        }
        buttons={[
          {
            isCancel: true,
            label: "Annuler",
            icon: <XCircle />,
            exec: closeModal
          },
          {
            isDanger: true,
            label: "Supprimer",
            icon: <Trash />,
            exec: delMeeting
          }
        ]}
      />

      <Olympe.Modal
        open={openModalReject}
        loading={rejectModif}
        onClose={closeModalReject}
        title="Refuser la demande"
        content={
          <>
            Refuser une demande de modification notifie l'utilisateur par une
            notification push et un mail. La modification est perdue.
          </>
        }
        buttons={[
          {
            isCancel: true,
            label: "Annuler",
            icon: <XCircle />,
            exec: closeModalReject
          },
          {
            isDanger: true,
            label: "Refuser la modification",
            icon: <Trash />,
            exec: modifReject
          }
        ]}
      />

      <Olympe.Modal
        open={openModalAccept}
        loading={rejectModif}
        onClose={closeModalAccept}
        title="Accepter la demande"
        content={
          <>
            Accepter une demande de modification modifie le contenu d'un
            rendez-vous et notifie l'utilisateur par une notification push et un
            mail.
          </>
        }
        buttons={[
          {
            isCancel: true,
            label: "Annuler",
            icon: <XCircle />,
            exec: closeModalAccept
          },
          {
            label: "Accepter la modification",
            icon: <CheckCircle />,
            exec: modifAccept
          }
        ]}
      />

      <Olympe.Modal
        open={openModalInter}
        loading={toInter}
        onClose={closeModalInter}
        title="Transformer en installation"
        content={
          <>
            Transformer un rendez-vous en installation permet de créer une nouvelle installation en se basant sur les paramètres du rendez-vous.
          </>
        }
        buttons={[
          {
            isCancel: true,
            label: "Annuler",
            icon: <XCircle />,
            exec: closeModalInter
          },
          {
            label: "Transformer",
            icon: <Package />,
            exec: meetToInter
          }
        ]}
      />

      <Olympe.SubItem>
        <Olympe.ItemFullWidth>
          <Olympe.ItemTitle>
            <span>
              <Olympe.Tooltips
                title="Revenir à la page précédente."
                classes="icon"
                placement="left"
              >
                <Olympe.Button.Basic
                  toExecute={() => navigate(-1)}
                  icon={<ArrowLeftCircle />}
                />
              </Olympe.Tooltips>

              <span style={{ marginLeft: 20 }}>Rendez-vous</span>
            </span>
          </Olympe.ItemTitle>

          <Olympe.ItemWithAvatar>
            <div className="infosSep">
              {infos.rdvAction && (
                <p className="usrDetails">
                  <AlertTriangle color={Olympe.Color.tomato} />
                  <span>Modification demandée.</span>
                </p>
              )}

              <p className="usrDetails">
                <Users />
                <Olympe.Tooltips title={`Les techniciens : ${infos.rdvTech}`}>
                  {infos.rdvTech}
                </Olympe.Tooltips>
              </p>

              <p className="usrDetails">
                <MapPin />
                <Olympe.Tooltips
                  title={`Adresse postale : ${infos.rdvLocation}`}
                >
                  {infos.rdvLocation}
                </Olympe.Tooltips>
              </p>

              <p className="usrDetails">
                <Watch />
                <span>Durée : {getInstTime(infos.rdvDuration)}</span>
              </p>

              <p className="usrDetails">
                <Calendar />
                <Olympe.Tooltips title="Date du début du rendez-vous">
                  {moment(infos.rdvDate)
                    .format("DD MMM YYYY à HH:mm")
                    .replace(":", "h")}
                </Olympe.Tooltips>
              </p>

              <p className="usrDetails">
                <DollarSign />
                <Olympe.Tooltips title="Prix du rendez-vous">
                  {infos.rdvPrice
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, " ") || "0"}{" "}
                  €
                </Olympe.Tooltips>
              </p>

              {infos.rdvToInter && (
                <div className="footerInfos">
                  <Olympe.Button.Basic
                    label="Transformer en installation"
                    icon={<Package />}
                    type="submit"
                    toExecute={() => setOpenModalInter(true)}
                  />
                </div>
              )}
            </div>

            <div className="infosSep avatar">
              <span
                className="icon"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <img
                  src={infos.productImage}
                  alt={infos.productName || "Logo"}
                  height={150}
                  width={150}
                  style={
                    infos.productName === ""
                      ? {
                        objectFit: "contain",
                        borderRadius: 0
                      }
                      : { objectFit: "cover", borderRadius: 10 }
                  }
                />

                {infos.productName
                  ? (
                    <Olympe.Tooltips
                      title="Voir le produit"
                      placement="left"
                      notSpan
                    >
                      <Link
                        to={`/products/details/${infos.productId}`}
                        style={{
                          marginTop: 20,
                          textAlign: "center",
                          color: Olympe.Color.blueLight,
                          textDecoration: "none"
                        }}
                      >
                        {infos.productName}
                      </Link>
                    </Olympe.Tooltips>
                  )
                  : (
                    <p style={{ textAlign: "center", color: Olympe.Color.grey }}>
                      Aucun produit associé.
                    </p>
                  )}
              </span>
            </div>
          </Olympe.ItemWithAvatar>
        </Olympe.ItemFullWidth>
      </Olympe.SubItem>

      {infos.rdvAction && (
        <Olympe.SubItem>
          <Olympe.ItemFullWidth>
            <Olympe.ItemTitle>Modification demandée</Olympe.ItemTitle>

            <i>
              {infos.rdvModif.split("\n").map(
                (str, id) =>
                  (
                    <span key={id}>
                      <br />
                      {str}
                    </span>
                  ) || "Modif NC"
              )}
            </i>

            <div className="footerInfos">
              <Olympe.Button.Basic
                label="Refuser la modification"
                icon={<XCircle />}
                anim="danger"
                toExecute={() => setOpenModalReject(true)}
              />

              <Olympe.Button.Basic
                label="Accepter et mettre à jour"
                icon={<CheckCircle />}
                toExecute={() => setOpenModalAccept(true)}
              />
            </div>
          </Olympe.ItemFullWidth>
        </Olympe.SubItem>
      )}

      <Olympe.SubItem>
        <Olympe.ItemFullWidth>
          <Olympe.ItemTitle style={{ color: Olympe.Color.grey }}>
            <span>
              <FileText />
              {infos.rdvName || "Motif NC"}
            </span>
          </Olympe.ItemTitle>

          <p style={{ marginTop: 0 }}>
            {infos.rdvContent.split("\n").map((str, id) =>
              str.trim() === "Modification acceptée :"
                ? (
                  <span key={id} style={{ color: Olympe.Color.blueLight }}>
                    <br />
                    {str}
                  </span>
                )
                : (
                  (
                    <span key={id}>
                      <br />
                      {str.trim()}
                    </span>
                  ) || "Contenu NC"
                )
            )}
          </p>
        </Olympe.ItemFullWidth>
      </Olympe.SubItem>

      <Olympe.SubItem>
        <Olympe.ItemFullWidth>
          <Olympe.ItemTitle>
            <span>
              <AlertTriangle />
              Zone dangereuse
            </span>
          </Olympe.ItemTitle>

          <p className="usrDetails">
            <Edit />
            <span className="codeDesc">
              Modifier un rendez-vous est immédiat et peut informer
              l'utilisateur par une notification push ou un mail le cas échéant.
            </span>
          </p>
          <p className="usrDetails">
            <Trash />
            <span className="codeDesc">
              Supprimer un rendez-vous est irréversible ; ses données sont
              perdues et le rendez-vous disparaît du compte utilisateur.
            </span>
          </p>
          <div className="footerInfos">
            <Olympe.Button.Basic
              label="Modifier le rendez-vous"
              icon={<Edit />}
              toExecute={() => navigate(`/meets/edit/${infos.rdvId}`)}
            />

            <Olympe.Button.Basic
              label="Supprimer le rendez-vous"
              icon={<Trash />}
              anim="danger"
              toExecute={() => setOpenModal(true)}
            />
          </div>
        </Olympe.ItemFullWidth>
      </Olympe.SubItem>
    </Olympe.Wrapper>
  );
}
