import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import moment from "moment";
import $ from "jquery";
import {
  AlertTriangle,
  ArrowLeftCircle,
  Calendar,
  Check,
  Database,
  DollarSign,
  Edit,
  FileText,
  Info,
  MapPin,
  Send,
  Users,
  Watch
} from "react-feather";

import { MainContext } from "../datas/context";
import { axiosGet, axiosPut } from "../datas/fetch";
import { Olympe } from "dv-olympe";
import { ItemSetup } from "../components/StyledItem";

export default function MeetingsEdit () {
  const context = React.useContext(MainContext);
  const params = useParams();
  const navigate = useNavigate();

  const [sent, setSend] = useState(false);
  const [addrChanged, setAddrChanged] = useState(false);
  const [techChanged, setTechChanged] = useState(false);
  const [prodtChanged, setProdtChanged] = useState(false);
  const [infos, setInfos] = useState({});
  const [techs, setTechs] = useState([]);
  const [addrs, setAddrs] = useState([]);
  const [prodt, setProdt] = useState([]);

  const {
    register,
    getValues,
    setValue,
    setFocus,
    watch,
    formState: { dirtyFields }
  } = useForm();

  const refreshInfos = async () => {
    axiosGet({
      apiKey: context.api.key,
      uri: `meets/?id=${params.meetId}&edit`
    }).then((data) => {
      if (!data.rdvId) {
        toast.error("Rendez-vous non trouvé.", { icon: "🔎" });
        navigate("/meets/");
        return;
      }
      setInfos(data);

      setTechs(
        data.rdvTech !== "-" &&
          data.rdvTech.split(", ").map((elt) => ({
            label: elt.trim(),
            value: elt
              .trim()
              .toLowerCase()
              .replace(/[^a-zA-Z0-9]+/g, "")
          }))
      );

      setAddrs({
        label: data.rdvLocation.trim(),
        value: data.rdvLocation
          .trim()
          .toLowerCase()
          .replace(/[^a-zA-Z0-9]+/g, "")
      });

      if (data.productName !== "") {
        setProdt({
          label: data.productName?.trim(),
          value: data.productName
            ?.trim()
            .toLowerCase()
            .replace(/[^a-zA-Z0-9]+/g, "")
        });
      }
    });
  };

  useEffect(() => {
    if (!params.meetId) {
      toast.error("Rendez-vous non précisé.", { icon: <Calendar /> });
      navigate("/meets/");
    }

    watch([
      "rdvDate",
      "rdvDuraH",
      "rdvDuraM",
      "rdvMotif",
      "rdvPrice",
      "rdvContent",
      "letKnow"
    ]);

    refreshInfos();
  }, [navigate]);

  const sendModif = () => {
    const date = getValues("rdvDate");
    const addr = addrs.label;
    const letKnow = getValues("letKnow").toString();
    const motif = getValues("rdvMotif");
    const content = getValues("rdvContent");
    const duration = (
      parseInt(getValues("rdvDuraH")) * 60 +
      parseInt(getValues("rdvDuraM"))
    ).toString();
    const tech = techs
      .map((elt, idx) => `${idx > 0 ? " " : ""}${elt.label.trim()}`)
      .toString();
    const prod = prodt?.value || "";
    const price = getValues("rdvPrice")?.replace(",", ".").toString();

    if (tech.length === 0) {
      toast.error("Veuillez sélectionner au moins un technicien.");
      return;
    }

    if (addr.length === 0) {
      toast.error("Veuillez sélectionner ou ajouter une adresse.");
      return;
    }

    if (!date) {
      toast.error("Veuillez sélectionner une date.");
      setFocus("rdvDate");
      return;
    }

    if (duration === "0") {
      toast.error("Un rendez-vous doit durer au moins 5 minutes.");
      setValue("rdvDuraM", 5);
      setFocus("rdvDuraM");
      return;
    }

    if (price === "") {
      toast.error("Veuillez indiquer un prix pour ce rendez-vous ; il peut être nul.");
      setValue("rdvPrice", 0);
      setFocus("rdvPrice");
      return;
    }

    if (motif.length === 0) {
      toast.error("Veuillez ajouter un motif pour ce rendez-vous.");
      setFocus("rdvMotif");
      return;
    }

    if (content.length === 0) {
      toast.error("Veuillez ajouter un contenu pour ce rendez-vous.");
      setFocus("rdvContent");
      return;
    }

    setSend(true);

    axiosPut({
      apiKey: context.api.key,
      uri: "meets/",
      opt: {
        rdvId: infos.rdvId,
        duration,
        prod,
        price,
        date,
        tech,
        addr,
        letKnow,
        motif,
        content
      }
    })
      .then((data) => {
        if (data !== "true") {
          throw new Error(data || "réponse serveur erronée.");
        }
        toast.success("Le rendez-vous a bien été modifié.", {
          icon: <Edit />
        });
        navigate(`/meets/details/${infos.rdvId}`);
      })
      .catch((e) => {
        toast.error(
          "Une erreur est survenue, le rendez-vous n'a pas été modifié : " +
            e.message
        );
      })
      .finally(() => {
        setSend(false);
      });
  };

  if (!infos.rdvId) return <Olympe.Loader />;

  return (
    <Olympe.Wrapper>
      <Olympe.SubItem>
        <ItemSetup>
          <Olympe.ItemTitle>
            <span>
              <Olympe.Tooltips
                title="Revenir à la page précédente."
                classes="icon"
                placement="left"
              >
                <Olympe.Button.Basic
                  toExecute={() => navigate(-1)}
                  icon={<ArrowLeftCircle />}
                />
              </Olympe.Tooltips>

              <span style={{ marginLeft: 20 }}>Rendez-vous</span>
            </span>
          </Olympe.ItemTitle>

          {infos.rdvAction && (
            <p className="usrDetails">
              <AlertTriangle />
              <span>Une modification est en attente pour ce rendez-vous.</span>
            </p>
          )}

          <div className="usrDetails">
            <Users />
            <CreatableSelect
              isMulti
              className="select"
              options={infos.list.emps || []}
              placeholder="Techniciens"
              defaultValue={techs}
              onChange={(t) => {
                setTechs(t);
                setTechChanged(true);
              }}
              formatCreateLabel={(newVal) => `Ajouter : ${newVal}`}
              noOptionsMessage={() => "Aucun technicien trouvé 😬"}
            />
          </div>

          <div className="usrDetails">
            <MapPin />
            <CreatableSelect
              className="select"
              options={infos.list.addr}
              placeholder="Adresse de résidence"
              defaultValue={addrs}
              onChange={(t) => {
                setAddrs(t);
                setAddrChanged(true);
              }}
              noOptionsMessage={() => "Aucune adresse trouvée 😬"}
              formatCreateLabel={(newVal) => `Ajouter : ${newVal}`}
            />
          </div>

          <div className="usrDetails">
            <Database />
            <Select
              isClearable
              className="select"
              options={infos.list.pros}
              placeholder="Produit relié au rendez-vous (optionnel)"
              defaultValue={prodt}
              onChange={(t) => {
                setProdt(t);
                setProdtChanged(true);
              }}
              noOptionsMessage={() => "Aucun produit trouvé 😬"}
            />
          </div>

          <p className="usrDetails">
            <Calendar />
            <Olympe.Tooltips title="Jour de la fin des travaux">
              <Olympe.Input.Text
                type="datetime-local"
                {...register("rdvDate")}
                defaultValue={moment(infos.rdvDate).format(
                  "YYYY-MM-DD HH:mm:ss"
                )}
                autoComplete="off"
                onKeyPress={(e) => {
                  e.key === "Enter" && Olympe.Utils.enterInput(e);
                }}
              />
            </Olympe.Tooltips>
            <span className="fullDate">
              {getValues("rdvDate") &&
                getValues("rdvDate").length > 0 &&
                moment(getValues("rdvDate"))
                  .format("ddd D MMMM Y à HH:mm")
                  .replace(":", "h")}
            </span>
          </p>

          <p className="usrDetails">
            <Watch />

            <Olympe.Tooltips title="Durée du rendez-vous en heures">
              <Olympe.Input.Text
                type="number"
                min={0}
                step={1}
                max={20}
                {...register("rdvDuraH")}
                defaultValue={Math.floor(Number(infos.rdvDuration) / 60)}
                placeholder="h"
                autoComplete="off"
                onKeyPress={(e) => {
                  e.key === "Enter" && Olympe.Utils.enterInput(e);
                }}
              />
            </Olympe.Tooltips>

            <span className="timeSep">h</span>

            <Olympe.Tooltips title="Durée du rendez-vous en minutes">
              <Olympe.Input.Text
                type="number"
                min={0}
                step={5}
                max={60}
                {...register("rdvDuraM")}
                defaultValue={Math.floor(Number(infos.rdvDuration) % 60)}
                placeholder="min"
                autoComplete="off"
                onKeyPress={(e) => {
                  e.key === "Enter" && Olympe.Utils.enterInput(e);
                }}
              />
            </Olympe.Tooltips>
            <span className="timeSep">min.</span>
          </p>

          <p className="usrDetails">
            <DollarSign />

            <Olympe.Tooltips title="Prix total du rendez-vous en euros">
              <Olympe.Input.Text
                type="number"
                min={0}
                step={0.01}
                max={1000000}
                {...register("rdvPrice")}
                defaultValue={parseFloat(infos.rdvPrice?.replace(",", ".") || 0)}
                placeholder="Prix total"
                autoComplete="off"
                onKeyPress={(e) => {
                  e.key === "Enter" && Olympe.Utils.enterInput(e);
                }}
              />
            </Olympe.Tooltips>

            <span className="timeSep">€</span>
          </p>
        </ItemSetup>
      </Olympe.SubItem>

      <Olympe.SubItem>
        <Olympe.ItemFullWidth>
          <Olympe.ItemTitle>
            <span>
              <FileText /> Motif et description
            </span>
          </Olympe.ItemTitle>

          <div className="usrDetails">
            <div className="basicInput">
              <Olympe.Input.Text
                type="text"
                {...register("rdvMotif")}
                defaultValue={infos.rdvName}
                autoComplete="off"
                placeholder="Motif du rendez-vous"
                onKeyPress={(e) => {
                  e.key === "Enter" && Olympe.Utils.enterInput(e);
                }}
              />
            </div>
          </div>

          <div className="usrDetails">
            <textarea
              {...register("rdvContent")}
              defaultValue={infos.rdvContent}
              placeholder="Description complète du rendez-vous."
            />
          </div>
        </Olympe.ItemFullWidth>
      </Olympe.SubItem>

      <Olympe.SubItem>
        <Olympe.ItemFullWidth>
          <Olympe.ItemTitle>
            <span>
              <Check />
              Confirmer les modifications
            </span>
          </Olympe.ItemTitle>

          <p className="usrDetails">
            <Info />
            <span className="codeDesc">
              Modifier les informations d'un rendez-vous est une action
              immédiate et irréversible. Pour des soucis de communication et
              d'expérience utilisateur, un mail automatique est envoyé à
              l'utilisateur lui prévenant des changements appliqués.
            </span>
          </p>

          <p className="usrDetails">
            <Send />
            <span className="codeDesc">
              Pour ne pas notifier l'utilisateur de ces changements, veuillez
              désactiver l'option ci-dessous :<br />
              <br />
              Notifier l'utilisateur
              <Olympe.Input.Text
                type="checkbox"
                defaultChecked
                {...register("letKnow")}
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                  $(e.target)[0].blur();
                }}
              />
            </span>
          </p>

          <div className="footerInfos">
            {sent
              ? (
                <Olympe.MiniLoader />
              )
              : (
                <Olympe.Button.Basic
                  label="Valider les modifications"
                  icon={<Check />}
                  type="submit"
                  toExecute={
                    dirtyFields.rdvDate ||
                  dirtyFields.rdvDuraM ||
                  dirtyFields.rdvDuraH ||
                  dirtyFields.rdvMotif ||
                  dirtyFields.rdvContent ||
                  dirtyFields.rdvPrice ||
                  addrChanged ||
                  techChanged ||
                  prodtChanged
                      ? sendModif
                      : false
                  }
                />
              )}
          </div>
        </Olympe.ItemFullWidth>
      </Olympe.SubItem>
    </Olympe.Wrapper>
  );
}
