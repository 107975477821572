import React, { useEffect, useState } from "react";
import { Bell, Download, PlusCircle, Users } from "react-feather";
import { ActiveTime, shortTime } from "../datas/UtilsFunctions";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Filler
} from "chart.js";
import { Doughnut, Line } from "react-chartjs-2";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { axiosGet } from "../datas/fetch";
import { MainContext } from "../datas/context";
import { ItemAccountHistory, ItemHistory } from "../components/StyledItem";
import moment from "moment";
import { Olympe } from "dv-olympe";
import $ from "jquery";
import { HeaderDatatable } from "../components/StyledElements";

const columns = [
  {
    name: "Id",
    selector: (row, idx) => idx + 1,
    width: "80px",
    hide: "sm"
  },
  {
    name: "Nom",
    selector: (row) =>
      Olympe.Utils.truncStr({ str: row.name || "nc", len: 20 }),
    sortable: true,
    reorder: true,
    grow: 2
  },
  {
    name: "Mail",
    selector: (row) =>
      Olympe.Utils.truncStr({ str: row.mail || "nc", len: 30 }),
    hide: "md",
    sortable: true,
    reorder: true,
    grow: 3
  },
  {
    name: "Téléphone",
    selector: (row) =>
      Olympe.Utils.truncStr({ str: row.phon || "nc", len: 14 }),
    hide: "md",
    sortable: true,
    reorder: true,
    grow: 2
  },
  {
    name: <Bell />,
    selector: (row) => row.date,
    format: (row) => (row.noti === "true" ? "oui" : "non"),
    sortable: true,
    reorder: true,
    grow: 1,
    hide: "sm"
  }
];

export default function Account () {
  const context = React.useContext(MainContext);
  const [datas, setDatas] = useState([]);
  const [stats, setStats] = useState([]);
  const [search, setSearch] = useState("");
  const [searchUsr, setSearchUsr] = useState("");
  const navigate = useNavigate();

  const { register, watch, getValues } = useForm();

  useEffect(() => {
    const refreshInfos = async () => {
      axiosGet({
        apiKey: context.api.key,
        uri: "users/?item=infos"
      }).then((data) => {
        // if (data === false) {
        //   localStorage.removeItem("key");
        //   navigate("/login/");
        // }
        setDatas(data);
      });

      axiosGet({
        apiKey: context.api.key,
        uri: "stats/?item=accounts"
      }).then((data) => setStats(data));
    };

    refreshInfos();
    const datasInterval = setInterval(() => refreshInfos(), 3000);
    watch("usrsSelection");

    return () => {
      clearInterval(datasInterval);
    };
  }, []);

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleSearchUsr = (event) => {
    setSearchUsr(event.target.value);
  };
  console.log(datas);
  const filteredDatas = () => {
    const ret = [];
    const tmp = datas
      .filter(
        (obj) =>
          obj.id.toLowerCase().includes(search.toLowerCase()) ||
          obj.name.toLowerCase().includes(search.toLowerCase()) ||
          obj.mail.toLowerCase().includes(search.toLowerCase()) ||
          obj.phon.toLowerCase().includes(search.toLowerCase()) ||
          obj.logs.expoToken.toLowerCase().includes(search.toLowerCase())
      )
      .sort((a, b) => b.logs?.last - a.logs?.last);

    for (let i = 0; i < tmp.length; i++) {
      ret.push({
        id: tmp[i].id,
        name: tmp[i].name,
        desc:
          tmp[i].logs.last === tmp[i].logs.first
            ? "Pas encore d'activité"
            : ActiveTime(tmp[i].logs.last),
        badge: Math.abs(tmp[i].related - tmp[i].count)
      });
    }
    return ret;
  };

  const filteredUsrs = () =>
    getValues("usrsSelection") === false
      ? datas.filter(
        (obj) =>
          obj.id.toLowerCase().includes(searchUsr.toLowerCase()) ||
            obj.name.toLowerCase().includes(searchUsr.toLowerCase()) ||
            obj.mail.toLowerCase().includes(searchUsr.toLowerCase()) ||
            obj.phon.toLowerCase().includes(searchUsr.toLowerCase()) ||
            obj.logs.expoToken.toLowerCase().includes(searchUsr.toLowerCase())
      )
      : datas
        .filter(
          (obj) =>
            obj.id.toLowerCase().includes(searchUsr.toLowerCase()) ||
              obj.name.toLowerCase().includes(searchUsr.toLowerCase()) ||
              obj.mail.toLowerCase().includes(searchUsr.toLowerCase()) ||
              obj.phon.toLowerCase().includes(searchUsr.toLowerCase()) ||
              obj.logs.expoToken.toLowerCase().includes(searchUsr.toLowerCase())
        )
        .filter((obj) => obj.noti === "true");

  const filteredActivity = () => {
    const tmp = stats.activity.slice(0, 6);

    tmp.sort((a, b) => (a.curtime < b.curtime ? 1 : -1));
    return tmp;
  };

  const renderActivity = (elt, idx) => {
    const { curtime, name, content, disabled } = elt;

    const time = Olympe.Utils.SentLogTime(curtime, true);

    return (
      <Link
        key={`${idx}-${curtime}`}
        className={`card ${disabled ? "disabled" : ""}`}
        to={disabled ? "#" : `/accounts/${elt.clid}`}
      >
        <span className="date">~ {time}</span>
        <div>
          <p className="name">
            {Olympe.Utils.truncStr({ str: name, len: 40 })}
          </p>
          <p className="desc">
            {Olympe.Utils.truncStr({ str: content, len: 45 })}
          </p>
        </div>
      </Link>
    );
  };

  const convertCSV = () => {
    const tmp = filteredUsrs();
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";

    const keys = [
      "Nom",
      "Adresse postale",
      "Adresse mail",
      "Téléphone",
      "Professionnel",
      "Notifications activées",
      "Enregistré le"
    ];

    const keysObj = ["name", "addr", "mail", "phon", "type", "noti", "logs"];

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    tmp.forEach((item) => {
      let ctr = 0;
      keys.forEach((k) => {
        const key = keysObj[keys.indexOf(k)];
        if (ctr > 0) result += columnDelimiter;
        let tmp2 = item[key];

        if (key === "addr" && item[key].length > 1) {
          tmp2 = item[key].join(" ; \u2028");
        } else if (key === "type") {
          tmp2 = item[key] === "cl_pro" ? "oui" : "non";
        } else if (key === "noti") {
          tmp2 = item[key] === "true" ? "oui" : "non";
        } else if (key === "logs") {
          tmp2 = moment(item[key].first).format("YYYY-MM-DD");
        }

        result += tmp2;
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  };

  const getSubHeaderComponent = () => (
    <HeaderDatatable>
      <div>
        <input type="checkbox" {...register("usrsSelection")} />
        <p>Notifications activées.</p>
      </div>

      <Olympe.Input.Text
        className="search"
        type="text"
        onChange={handleSearchUsr}
        value={searchUsr}
        placeholder="Rechercher..."
        autoComplete="off"
      />
    </HeaderDatatable>
  );

  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    Filler,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement
  );

  if (!datas[0] || !stats.time_average) return <Olympe.Loader />;

  const doughnutData = {
    labels: [" Particuliers ", " Professionels "],
    datasets: [
      {
        label: "",
        data: [stats.usrs_par, stats.usrs_pro],
        backgroundColor: [context.clr.blue, context.clr.light],
        borderWidth: 0
      }
    ]
  };

  let gradient = null;

  if ($(".lineChart")[0]) {
    const ctx = $(".lineChart")[0].getContext("2d");

    gradient = ctx.createLinearGradient(0, 25, 0, 300);
    gradient.addColorStop(0, `${context.clr.blue}70`);
    gradient.addColorStop(0.35, `${context.clr.blue}25`);
    gradient.addColorStop(1, `${context.clr.blue}00`);
  }

  const barData = {
    labels: [
      moment().subtract(7, "day").format("ddd DD"),
      moment().subtract(6, "day").format("ddd DD"),
      moment().subtract(5, "day").format("ddd DD"),
      moment().subtract(4, "day").format("ddd DD"),
      moment().subtract(3, "day").format("ddd DD"),
      moment().subtract(2, "day").format("ddd DD"),
      "Hier",
      "Aujourd'hui"
    ],
    datasets: [
      {
        label: " Connexions ",
        data: [
          stats.j_7,
          stats.j_6,
          stats.j_5,
          stats.j_4,
          stats.j_3,
          stats.j_2,
          stats.j_1,
          stats.j_0
        ],
        backgroundColor: gradient || context.clr.blue,
        borderColor: `${context.clr.blue}`,
        pointBackgroundColor: `${context.clr.blue}`,
        lineTension: 0.4,
        borderWidth: 2,
        pointRadius: 3,
        fill: !!gradient
      }
    ]
  };

  return (
    <Olympe.Wrapper>
      <Olympe.SubItem>
        <Olympe.Chart
          title="Comptes"
          subtitle="Part des utilisateurs selon leur type."
          chartTitle={Olympe.Utils.numberShorter(
            Math.floor(Number(stats.usrs_par) + Number(stats.usrs_pro))
          )}
          chartLegend={
            <>
              <div className="legend">
                <span
                  className="square"
                  style={{ backgroundColor: context.clr.blue }}
                />
                Comptes particuliers
              </div>

              <div className="legend">
                <span
                  className="square"
                  style={{ backgroundColor: context.clr.light }}
                />
                Comptes professionels
              </div>
            </>
          }
          chart={
            <Doughnut
              data={doughnutData}
              options={{
                responsive: true,
                plugins: { legend: { display: false } },
                cutout: 62
              }}
            />
          }
        />

        <Olympe.Chart
          title="Durée moyenne"
          subtitle="Durée moyenne de la totalité des sessions utilisateurs."
          chartLegend={
            <div className="legend lonely">
              Données provenant des comptes utilisateurs uniquement.
            </div>
          }
          chart={
            <p>{shortTime(stats.time_average ? stats.time_average : 0)}</p>
          }
        />
      </Olympe.SubItem>

      <Olympe.SubItem>
        <Olympe.Chart
          large
          title="Connexions"
          subtitle="Taux de connexions par utilisateur sur différentes périodes."
          chart={
            <Line
              className="lineChart"
              data={barData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false
                  }
                },
                scales: {
                  x: {
                    ticks: {
                      color: context.clr.grey,
                      padding: 10,
                      autoSkip: false
                    },
                    grid: {
                      display: false
                    }
                  },
                  y: {
                    ticks: {
                      color: context.clr.grey,
                      stepSize: 5
                    },
                    grid: {
                      color: "rgba(255, 255, 255, 0.2)"
                    }
                  }
                }
              }}
            />
          }
        />
      </Olympe.SubItem>

      <Olympe.SubItem>
        <Olympe.List.Menu
          uri="accounts"
          label="Utilisateurs"
          btnIcon={<PlusCircle />}
          btnFct={() => navigate("/accounts/add")}
          btnTitle="Ajouter un utilisateur"
          placeholder="Nom, mail, téléphone..."
          search={search}
          content={filteredDatas()}
          handleSearch={handleSearch}
        />

        <ItemAccountHistory>
          <Olympe.ItemTitle>Historique d'actions</Olympe.ItemTitle>
          <div className="activities">
            {filteredActivity().length > 0
              ? (
                filteredActivity().map((elt, idx) => renderActivity(elt, idx))
              )
              : (
                <p style={{ textAlign: "center" }}>Aucune activité récente.</p>
              )}
          </div>
        </ItemAccountHistory>
      </Olympe.SubItem>

      <Olympe.SubItem>
        <ItemHistory>
          <Olympe.ItemTitle>
            <span>
              <Users /> Liste des utilisateurs{" "}
            </span>
            <Olympe.Tooltips
              title="Télécharger le tableau au format CSV."
              classes="icon"
              placement="left"
            >
              <Olympe.Button.Basic
                toExecute={() =>
                  Olympe.Utils.downloadCSV(
                    convertCSV(),
                    "liste_des_utilisateurs.csv"
                  )
                }
                icon={<Download />}
              />
            </Olympe.Tooltips>
          </Olympe.ItemTitle>

          <div className="logsTable">
            <Olympe.Table.Wrap
              responsive
              clr={context.clr}
              columns={columns}
              data={filteredUsrs()}
              noDataComponent={Olympe.Table.Empty()}
              pagination
              paginationComponentOptions={Olympe.Table.Pagination}
              onRowClicked={(row) => navigate(`/accounts/${row.id}`)}
              subHeader
              subHeaderComponent={getSubHeaderComponent()}
              theme="customTheme"
              customStyles={Olympe.Table.Style}
            />
          </div>
        </ItemHistory>
      </Olympe.SubItem>
    </Olympe.Wrapper>
  );
}
